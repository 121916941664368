/* eslint-disable @typescript-eslint/no-explicit-any */
import { getStore } from './store';
import appendQuery from 'append-query'; // TODO: Remove dependency if possible
import Cookies from 'js-cookie';
import superagent from 'superagent'; // TODO: Remove dependency if possible
import { Next } from './next';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let suspendErrors = false;
window.addEventListener('beforeunload', () => {
  suspendErrors = true;
});

const defaultResponse = {
  status: 0,
  success: false,
  error: 'Failed to fetch',
  data: null,
};

const errorFunctions: any = [];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const showError = (message: string) => {
  // TODO: Replace with Whale component notification
  // !suspendErrors &&
  //   toast.error(<Toast icon='icon icon-error'>{message}</Toast>, {
  //     position: 'bottom-center',
  //     autoClose: 10000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //   });
};

const dispatchActions = (actions: any) => {
  const store = getStore();
  const actionsList = Array.isArray(actions) ? actions : [actions];
  actionsList.forEach(action => {
    if (action) {
      store.dispatch(typeof action === 'function' ? action() : action);
    }
  });
};

export const onErrorMiddleware = (func: any) => {
  if (typeof func === 'function') {
    errorFunctions.push(func);
  } else {
    throw new Error('On Error middleware must be a function!');
  }
};

const getSuperAgent = (method = '') => {
  switch (method.toUpperCase()) {
    case 'GET':
      return superagent.get;
    case 'POST':
      return superagent.post;
    case 'PUT':
      return superagent.put;
    case 'PATCH':
      return superagent.patch;
    case 'DELETE':
      return superagent.delete;
    default:
      return superagent.get;
  }
};

const _createHttpAction = (next: Partial<Next>, jsonData: any = {}, options: any = {}) => {
  if (!(next instanceof Next) || !options) {
    throw new Error('Invalid Next Parameter!');
  }
  const store = getStore();
  const combinedOptions: any = {
    ...next.options,
    ...options,
  };
  const params = jsonData.params || {};
  if (params) {
    delete jsonData.params;
    Object.keys(params).forEach(key => {
      combinedOptions.uri = combinedOptions.uri.replace(new RegExp(`{${key}}`, 'g'), params[key]);
    });
    const query = jsonData.query;
    if (query) {
      combinedOptions.uri = appendQuery(combinedOptions.uri, query, { encodeComponents: false });
    }
  }

  const request = getSuperAgent(combinedOptions.method);
  const { uri } = combinedOptions;
  const current = request(uri).withCredentials();

  current.set('Authorization', Cookies.get('auth-token') || '');
  current.set('Cache-Control', 'no-cache, no-store, must-revalidate');
  current.set('Pragma', 'no-cache');
  current.set('Expires', '0 ');
  current.set('Accept', 'application/json');
  if (jsonData.files && jsonData.files.length) {
    jsonData.files.forEach(({ name, file }: any) => {
      current.attach(name, file);
    });
    if (jsonData.body) {
      // eslint-disable-next-line no-console
      console.warn('Super agent forbids sending files with JSON data');
    }
  } else {
    current.send(jsonData.body);
  }

  current
    .then((response: { body: any }) => {
      const data = (response && response.body) || defaultResponse;
      store.dispatch({
        type: next.SUCCESS,
        payload: data,
      });
      dispatchActions(options.onSuccess && options.onSuccess(data));
      dispatchActions(options.onFinish && options.onFinish(null, data));
    })
    .catch(
      (error: {
        response?: any | undefined;
        message?: 'Something went wrong, Please try again or contact support.' | undefined;
        status: any;
      }) => {
        const {
          response = {},
          message = 'Something went wrong, Please try again or contact support.',
          status,
        } = error;
        const data = (response && response.body) || defaultResponse;
        store.dispatch({
          type: next.ERROR,
          payload: data,
        });
        if (!status || status === 500) {
          showError(message);
        }
        dispatchActions(options.onError && options.onError(data));
        dispatchActions(options.onFinish && options.onFinish(data, null));
        errorFunctions.forEach((errorFunction: any) =>
          errorFunction(next, dispatchActions, data, error),
        );
      },
    );

  return {
    action: {
      type: next.INIT,
    },
    request: current,
  };
};

export interface IOptions<ResultDataType, ResultErrorType = ResultDataType> {
  uri?: string;
  method?: string;
  onSuccess?: (data: ResultDataType) => void;
  onError?: (data: ResultErrorType) => void;
  onFinish?: (err: unknown, data: ResultDataType) => void;
}

export interface IData<IBody, IParams, IQuery> {
  body?: IBody;
  params?: IParams;
  query?: IQuery;
  files?: [];
}

export interface IHttpAction {
  type: string;
}

export function createHttpAction<
  IBody,
  IParams,
  IQuery,
  ResultDataType,
  ResultErrorType = ResultDataType,
>(
  next: Partial<Next>,
  data: IData<IBody, IParams, IQuery>,
  options?: IOptions<ResultDataType, ResultErrorType>,
): IHttpAction {
  return _createHttpAction(next, data, options).action;
}
