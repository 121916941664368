import { Next } from '../next';

export const createPreEntryNext = new Next('/investments/pre-entry', 'CREATE_PRE_ENTRY', {
  method: 'POST',
});

export const updatePreEntryNext = new Next('/investments/pre-entry/{id}', 'UPDATE_PRE_ENTRY', {
  method: 'PATCH',
});

export const createEntryNext = new Next('/investments/entry', 'CREATE_ENTRY', {
  method: 'POST',
});

export const createInvestmentNext = new Next('/investments', 'CREATE_INVESTMENT', {
  method: 'POST',
});

export const updateInvestmentNext = new Next('/investments/{id}', 'UPDATE_INVESTMENT', {
  method: 'PATCH',
});

export const getInvestableAmountNext = new Next(
  '/investments/investable-amount',
  'GET_INVESTABLE_AMOUNT',
  {
    method: 'GET',
  },
);

export const INIT_ERROR = 'INIT_ERROR';
