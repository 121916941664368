import {
  AuthInfoResponseDto,
  CreateSessionTokenResponseDto,
  DisableTfaDto,
  EnableTfaDto,
  GenerateTfaSeedDto,
  GenerateTfaSeedResponseDto,
  RequestResetPasswordDto,
  ResetPasswordDto,
  ResetPasswordResponseDto,
  VerifyEmailDto,
  VerifyPasswordDto,
  VerifyResetPasswordCodeDto,
} from '@web-api-client';
import {
  refreshSessionNext,
  loginNext,
  logoutNext,
  verifyEmailNext,
  verifyPasswordNext,
  enableTFANext,
  disableTFANext,
  generateTFASeedNext,
  SAVE_TFA_TEMP_DATA,
  requestResetPasswordNext,
  verifyResetPasswordEmailNext,
  resetPasswordNext,
  loginSonyNext,
  RESET_USER_SESSION,
  INIT_ERROR,
} from './actionTypes';
import { createAction } from '@reduxjs/toolkit';
import { createHttpAction, IOptions } from '../http-action';
import { IPostLoginRequest, IPostSsoLoginRequest, ITwoFactorAuth } from './models';

export const refreshSession = (option?: IOptions<CreateSessionTokenResponseDto>) =>
  createHttpAction(refreshSessionNext, {}, option);
export const loginUser = (
  body: IPostLoginRequest,
  option?: IOptions<CreateSessionTokenResponseDto>,
) => createHttpAction(loginNext, { body }, option);
export const loginSony = (
  body: IPostSsoLoginRequest,
  option?: IOptions<CreateSessionTokenResponseDto>,
) => createHttpAction(loginSonyNext, { body }, option);
export const logoutUser = () => createHttpAction(logoutNext, {});
export const verifyEmail = (
  body: VerifyEmailDto,
  option?: IOptions<CreateSessionTokenResponseDto>,
) => createHttpAction(verifyEmailNext, { body }, option);
export const verifyPassword = (body: VerifyPasswordDto, option?: IOptions<void>) =>
  createHttpAction(verifyPasswordNext, { body }, option);
export const generateTFASeed = (
  body: GenerateTfaSeedDto,
  option?: IOptions<GenerateTfaSeedResponseDto>,
) => createHttpAction(generateTFASeedNext, { body }, option);
export const enableTFA = (body: EnableTfaDto, option?: IOptions<AuthInfoResponseDto>) =>
  createHttpAction(enableTFANext, { body }, option);
export const disableTFA = (body: DisableTfaDto, option?: IOptions<AuthInfoResponseDto>) =>
  createHttpAction(disableTFANext, { body }, option);
export const saveTFATempData = (data: ITwoFactorAuth) => ({
  type: SAVE_TFA_TEMP_DATA,
  payload: data,
});
export const requestResetPassword = (body: RequestResetPasswordDto, option?: IOptions<void>) =>
  createHttpAction(requestResetPasswordNext, { body }, option);
export const verifyResetPasswordEmail = (
  body: VerifyResetPasswordCodeDto,
  option?: IOptions<void>,
) => createHttpAction(verifyResetPasswordEmailNext, { body }, option);
export const resetPassword = (
  body: ResetPasswordDto,
  option?: IOptions<ResetPasswordResponseDto>,
) => createHttpAction(resetPasswordNext, { body }, option);
export const resetUserSession = createAction(RESET_USER_SESSION);
export const initError = createAction(INIT_ERROR);
