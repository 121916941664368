import { CreateEntryDto, CreateInvestmentDto, CreatePreEntryDto } from '@web-api-client';
import { createHttpAction, IOptions } from '../http-action';
import {
  createPreEntryNext,
  updatePreEntryNext,
  createInvestmentNext,
  updateInvestmentNext,
  createEntryNext,
  getInvestableAmountNext,
  INIT_ERROR,
} from './actionTypes';
import { createAction } from '@reduxjs/toolkit';

export const createPreEntry = (body: CreatePreEntryDto, option?: IOptions<void>) =>
  createHttpAction(createPreEntryNext, { body }, option);

export const updatePreEntry = (id: number, body: CreatePreEntryDto, option?: IOptions<void>) =>
  createHttpAction(updatePreEntryNext, { body, params: { id } }, option);

export const createEntry = (body: CreateEntryDto, option?: IOptions<void>) =>
  createHttpAction(createEntryNext, { body }, option);

export const createInvestment = (body: CreateInvestmentDto, option?: IOptions<void>) =>
  createHttpAction(createInvestmentNext, { body }, option);

export const updateInvestment = (id: number, body: CreateInvestmentDto, option?: IOptions<void>) =>
  createHttpAction(updateInvestmentNext, { body, params: { id } }, option);

export const getInvestableAmount = (options?: IOptions<number>) =>
  createHttpAction(getInvestableAmountNext, {}, options);

export const initError = createAction(INIT_ERROR);
