import classNames from 'classnames';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { useAuthSelector } from '@state/auth/hooks';
import { useInvestorSelector } from '@state/investor/hooks';
import { useSettingSelector } from '@state/setting/hooks';
import {
  RegistrationStatusesBankRegistrationStatusEnum,
  RegistrationStatusesCompatibilityRegistrationStatusEnum,
  RegistrationStatusesGeneralRegistrationStatusEnum,
  UpdateRequestStatusEnum,
} from '@web-api-client';
import { PATH_AUTH } from '@routes';
import { Translate } from '../Translate';
import { Button } from '../Button';
import { Logo } from '../Logo';
import './UserMenu.scss';

export interface IUserMenuItem {
  icon: string;
  onClick?: (value?: unknown) => void;
  path?: string;
  i18nKey: string;
  isExternalUrl?: boolean;
}

export interface IUserMenu {
  legacy?: boolean;
  menuItems?: IUserMenuItem[];
}

const UserMenu = ({ legacy = false, menuItems = [] }: IUserMenu) => {
  const history = useHistory();
  const { isAuthenticated } = useAuthSelector();
  const { setting } = useSettingSelector();
  const { investor } = useInvestorSelector();
  const { firstName, lastName, email, investorType } = investor?.generalProfile || {};
  const fullName = [lastName, firstName].join(' ');
  const dashboardDisplayFlags = setting?.variables.dashboardDisplayFlags;

  const statuses = [
    investor?.registrationStatuses?.bankRegistrationStatus,
    investor?.registrationStatuses?.compatibilityRegistrationStatus,
    investor?.registrationStatuses?.generalRegistrationStatus,
  ];
  const isVerified = statuses.every(
    status =>
      status &&
      [
        RegistrationStatusesGeneralRegistrationStatusEnum.Verified,
        RegistrationStatusesBankRegistrationStatusEnum.Verified,
        RegistrationStatusesCompatibilityRegistrationStatusEnum.Verified,
      ].includes(status),
  );
  const isReverted = !!statuses.find(
    status =>
      status &&
      [
        RegistrationStatusesGeneralRegistrationStatusEnum.Reverted,
        RegistrationStatusesBankRegistrationStatusEnum.Reverted,
        RegistrationStatusesCompatibilityRegistrationStatusEnum.Reverted,
      ].includes(status),
  );
  const isRejected = !!statuses.find(
    status =>
      status &&
      [
        RegistrationStatusesGeneralRegistrationStatusEnum.Rejected,
        RegistrationStatusesBankRegistrationStatusEnum.Rejected,
        RegistrationStatusesCompatibilityRegistrationStatusEnum.Rejected,
      ].includes(status),
  );

  const isUpdateRequested = [
    investor?.updateRequests?.profile?.status,
    investor?.updateRequests?.bank?.status,
    investor?.updateRequests?.compatibility?.status,
  ].includes(UpdateRequestStatusEnum.Requested);

  const isUpdateRequestRejected = [
    investor?.updateRequests?.profile?.status,
    investor?.updateRequests?.bank?.status,
    investor?.updateRequests?.compatibility?.status,
  ].includes(UpdateRequestStatusEnum.Rejected);

  const [isMenu, setMenuState] = useState(false);
  const hasFullName = !!fullName?.replace(/-/g, '').trim();

  let statusIcon = 'clock';
  let statusKey = 'processing';
  if (isRejected) {
    statusIcon = 'error';
    statusKey = 'rejected';
  } else if (isVerified) {
    statusIcon = 'check';
    statusKey = 'verified';
  } else if (isReverted) {
    statusIcon = 'error';
    statusKey = 'reverted';
  }
  if (isUpdateRequested) {
    statusIcon = 'clock';
    statusKey = 'update-requested';
  }
  if (isUpdateRequestRejected) {
    statusIcon = 'error';
    statusKey = 'update-request-rejected';
  }

  const isEnableMenuNavigation = dashboardDisplayFlags?.enableMenuNavigation;

  return (
    <>
      <div
        className={classNames('font-16', {
          UserMenuComponent: isEnableMenuNavigation,
          UserMenuComponentWithoutUserMenu: !isEnableMenuNavigation,
        })}
      >
        <div>
          <Logo
            className={classNames({
              UserMenuComponent_logo: isEnableMenuNavigation,
              UserMenuComponentWithoutUserMenu_logo: !isEnableMenuNavigation,
            })}
            src={setting?.general.images.logo}
            alt={setting?.general.issuerName}
            style={{ objectFit: 'contain' }}
            disabled={!dashboardDisplayFlags?.enableLogoNavigation}
          />
        </div>
        {isAuthenticated ? (
          <CDropdown className='UserMenuComponent_container d-flex flex-column align-items-end'>
            <div className='d-flex align-items-center justify-content-between'>
              {dashboardDisplayFlags?.enableUserMenuProfile && (
                <div className='UserMenuComponent_data w-auto'>
                  <div className='font-16 text-truncate text-right'>{email}</div>
                </div>
              )}
              <CDropdownToggle
                onClick={() => setMenuState(true)}
                className={classNames('UserMenuComponent_avatar font-24', {
                  fill1: statusIcon === 'clock',
                  fill2: statusIcon === 'error',
                  fill3: statusIcon === 'check',
                })}
                tag='button'
              >
                <i className={investorType === 'entity' ? 'icon-entity' : 'icon-profile'} />
              </CDropdownToggle>
            </div>
            <CDropdownMenu className='UserMenuComponent_menu font-16 border-0'>
              {dashboardDisplayFlags?.enableUserMenuProfile && (
                <>
                  <div>
                    <>
                      {hasFullName && <div className='font-16 text-truncate mt-2'>{fullName}</div>}
                      <div className='font-16 subtext text-truncate mt-2'>{email}</div>
                    </>
                    <div className='font-16 text-truncate mt-2'>
                      <Translate translationKey='Texts.menu.status' />
                    </div>
                    <div className='font-16 subtext text-truncate mt-2'>
                      <span className={classNames(`UserMenuComponent_menu__status--${statusIcon}`)}>
                        <i className={`icon-${statusIcon} mr-1`} />
                        <Translate translationKey={`Texts.registration-status.${statusKey}`} />
                      </span>
                    </div>
                  </div>
                  <hr />
                </>
              )}
              {menuItems.map(({ path, icon, onClick, i18nKey, isExternalUrl }, idx) => (
                <CDropdownItem
                  key={idx}
                  onClick={() => {
                    if (onClick) onClick();
                    setMenuState(!isMenu);
                    if (path) {
                      if (isExternalUrl || legacy) {
                        window.location.href = path;
                      } else {
                        history.push(path);
                      }
                    }
                  }}
                  className='UserMenuComponent_menu__item h-100'
                >
                  <i className={`${icon} font-24 mr-3`} />
                  <span>
                    <Translate translationKey={i18nKey} />
                  </span>
                </CDropdownItem>
              ))}
            </CDropdownMenu>
          </CDropdown>
        ) : (
          <Button variant='ghost' size='lg' onClick={() => history.push(PATH_AUTH.login)}>
            <i className='icon-password mr-2 text-primary' />
            Login
          </Button>
        )}
      </div>
    </>
  );
};

export default UserMenu;
