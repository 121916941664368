import { Next } from '../next';

export const getOpportunityNext = new Next('/opportunities/detail', 'GET_OPPORTUNITY', {
  method: 'GET',
});

export const getOpportunityDocumentNext = new Next(
  '/opportunities/documents/detail',
  'GET_OPPORTUNITY_DOCUMENT',
  {
    method: 'GET',
  },
);

export const CLEAR_OPPORTUNITY_DETAIL = 'CLEAR_OPPORTUNITY_DETAIL';
