import React, { useEffect, useRef, useState } from 'react';
import { CTooltip } from '@coreui/react';
import './Tooltip.scss';

const Tooltip = ({ children, ...props }: CTooltip) => {
  return <CTooltip {...props}>{children}</CTooltip>;
};

const OptionalTooltip = ({
  children,
  ...props
}: Omit<CTooltip, 'children'> & {
  children: (ref: React.MutableRefObject<HTMLElement | null>) => JSX.Element;
}) => {
  const ref = useRef<HTMLElement>(null);
  const [isTooltip, setTooltip] = useState(false);

  const handleWindowResize = () => {
    if (!ref.current) return;
    const text = ref.current;
    setTooltip(text.offsetWidth !== text.scrollWidth);
  };

  useEffect(() => {
    if (ref.current) {
      window.addEventListener('resize', handleWindowResize);
      handleWindowResize();
    } else {
      window.removeEventListener('resize', handleWindowResize);
    }
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [ref.current]);

  if (isTooltip) {
    return <CTooltip {...props}>{children(ref)}</CTooltip>;
  }

  return <>{children(ref)}</>;
};

export default Tooltip;

export { OptionalTooltip };
