import React from 'react';
import classNames from 'classnames';
import './Chip.scss';

export interface IChipProps {
  text: string;
  className?: string;
  color?: 'primary' | 'secondary' | 'danger' | 'disabled';
}

const Chip = ({ className, text, color, ...props }: IChipProps) => {
  return (
    <div className={classNames('chip', color, className)} {...props}>
      {text}
    </div>
  );
};

Chip.defaultProps = {
  color: 'primary',
} as IChipProps;

export default Chip;
