import ReactHtmlParser, { Options } from 'react-html-parser';

/**
 * Wrapper for ReactHtmlParser.
 * Intentionally removed sanitizeHtml because of this issue: https://securitize.slack.com/archives/C01BZ92BLUA/p1689318087655139
 *
 * @param html
 * @param options
 * @returns
 */
const SafeReactHtmlParser = (html: string, options?: Options) => {
  return ReactHtmlParser(html, options);
};

export default SafeReactHtmlParser;
