import { useSelector } from 'react-redux';
import { InvestmentDetailDto } from '@web-api-client';
import { RootState } from '../store';

export function usePortfolioSelector(): RootState['portfolio'] {
  return useSelector<RootState, RootState['portfolio']>(state => state.portfolio);
}

export const usePortfolio = (tokenId: string) => {
  return useSelector<RootState, InvestmentDetailDto | undefined>(state => {
    const portfolio = state.portfolio.investments?.find(x => x.tokenId === tokenId);
    if (!portfolio) return undefined;
    return portfolio;
  });
};
