import classNames from 'classnames';
import React from 'react';
import { CCol, CRow } from '@coreui/react';
import { useCurrency } from '@hooks/currency.hook';
import { useDashboardMode } from '@hooks/dashboard-mode.hook';
import { ComputePreciseOperation, computePrecise } from '@utils/compute-precise';
import {
  Currency,
  OpportunityDetailDtoRoundStatusEnum,
  OpportunityDtoRoundStatusEnum,
} from '@web-api-client';
import { ProgressBar } from '../../ProgressBar';
import { Translate } from '../../Translate';
import styles from './Progress.module.scss';

interface IOpportunityProgress {
  isListCard?: boolean;

  roundStatus: OpportunityDtoRoundStatusEnum | OpportunityDetailDtoRoundStatusEnum;
  amountPledged: number;
  minAmountToBeRaised: number;
  maxAmountToBeRaised: number;
  currency?: Currency;
}

const Progress = ({
  isListCard,
  roundStatus,
  amountPledged,
  minAmountToBeRaised,
  maxAmountToBeRaised,
  currency,
}: IOpportunityProgress) => {
  const { getTranslateByMode } = useDashboardMode();
  const { formatCurrency } = useCurrency({ currency });

  const progressValue = Math.floor(
    computePrecise(amountPledged, maxAmountToBeRaised, ComputePreciseOperation.Divide) * 100,
  );

  const amountUntilComplete = computePrecise(
    minAmountToBeRaised,
    amountPledged,
    ComputePreciseOperation.Subtract,
  );

  const isMinimumAmountRaised = amountUntilComplete <= 0;
  const ProgressStatusComponent = isListCard ? 'h5' : 'p';

  return (
    <div>
      <h5 className='mb-1'>
        <Translate translationKey='Texts.opportunity.progress.title' />
      </h5>
      <CRow className='mb-0'>
        <CCol
          sm={isListCard ? undefined : '12'}
          lg={isListCard ? '8' : undefined}
          className={classNames('d-flex justify-content-between', {
            ['my-1']: isListCard,
            ['my-2']: !isListCard,
          })}
        >
          <div
            className={classNames({
              ['d-flex align-items-end']: isListCard,
            })}
          >
            <h5 className='m-0'>{formatCurrency(amountPledged)}</h5>
            {isListCard ? (
              <div className='d-flex'>
                <div>{/* div to prevent align break */}/</div>
                <div>{formatCurrency(maxAmountToBeRaised)}</div>
              </div>
            ) : (
              <div>/{formatCurrency(maxAmountToBeRaised)}</div>
            )}
          </div>
          {minAmountToBeRaised !== maxAmountToBeRaised && (
            <div>
              <div className='text-right'>
                <Translate translationKey='Texts.opportunity.min-amount-to-be-raised' />
              </div>
              <div>{formatCurrency(minAmountToBeRaised)}</div>
            </div>
          )}
        </CCol>
        <CCol sm='12' md={isListCard ? '8' : '12'} lg={isListCard ? '8' : '6'} className='mb-2'>
          <ProgressBar className={classNames(styles.progressBar)} value={progressValue} />
        </CCol>
        <CCol
          sm='12'
          md={isListCard ? '4' : '12'}
          lg={isListCard ? '4' : '6'}
          className='d-flex align-items-center justify-content-center mb-2'
        >
          <ProgressStatusComponent className='m-0'>
            {[
              OpportunityDtoRoundStatusEnum.NotOpened,
              OpportunityDtoRoundStatusEnum.Completed,
            ].some(s => s === roundStatus) ? (
              '-'
            ) : [
                OpportunityDtoRoundStatusEnum.Failed,
                OpportunityDtoRoundStatusEnum.Redeemed,
                OpportunityDtoRoundStatusEnum.Operating,
                OpportunityDtoRoundStatusEnum.OperationEnded,
              ].some(s => s === roundStatus) ? (
              <span style={{ whiteSpace: 'pre' }}>
                {getTranslateByMode(`Texts.opportunity.progress.${roundStatus}`)}
              </span>
            ) : isMinimumAmountRaised ? (
              <Translate translationKey='Texts.opportunity.progress.completed' />
            ) : (
              <span style={{ whiteSpace: 'pre' }}>
                <Translate
                  translationKey='Texts.opportunity.progress.opened'
                  parameters={{ amount: formatCurrency(amountUntilComplete) }}
                />
              </span>
            )}
          </ProgressStatusComponent>
        </CCol>
      </CRow>
    </div>
  );
};

export default Progress;
