import React from 'react';
import { CLabel, CInputRadio } from '@coreui/react';
import classNames from 'classnames';
import './RadioInput.scss';

const RadioInput = ({ children, className, ...props }: CInputRadio) => {
  return (
    <CLabel className={classNames('RadioInputComponent m-0', className)}>
      <CInputRadio className='position-static ml-0' {...props} />
      <span>{children}</span>
    </CLabel>
  );
};

export default RadioInput;
