import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { PATH_ROOT } from '@routes';

export interface ILogoProps {
  src?: string;
  alt?: string;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
}

const Logo = ({ src, alt, className, style, disabled }: ILogoProps) => {
  const history = useHistory();
  return (
    <img
      className={classNames(className)}
      src={src}
      style={style || { height: '64px', objectFit: 'cover', cursor: 'pointer' }}
      alt={alt}
      onClick={disabled ? undefined : () => history.push(PATH_ROOT)}
    />
  );
};

export default Logo;
