import React, { PropsWithChildren, useState } from 'react';
import {
  CLabel,
  CInput,
  CFormGroup,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
} from '@coreui/react';
import classNames from 'classnames';
import './PasswordInput.scss';
import { Translate } from '../../Translate';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
export interface IPasswordInputProps extends PropsWithChildren<CInput> {
  label?: string;
  errors?: DeepMap<FieldValues, FieldError> | null;
}

const PasswordInput = ({
  children,
  className,
  label,
  name = '',
  errors,
  ...props
}: IPasswordInputProps) => {
  const [isShownPass, setIsShownPass] = useState(false);
  return (
    <CFormGroup className={classNames('PasswordInputComponent', className)}>
      {label && (
        <CLabel htmlFor={name && 'password'}>
          <Translate translationKey={label} />
        </CLabel>
      )}
      <CInputGroup>
        <CInput type={isShownPass ? 'text' : 'password'} {...props} />
        <CInputGroupAppend>
          <CInputGroupText onClick={() => setIsShownPass(!isShownPass)}>
            <i className={`text-muted icon-icon-eye-${isShownPass ? 'hide' : 'show'}`} />
          </CInputGroupText>
        </CInputGroupAppend>
      </CInputGroup>
      {errors && (
        <ErrorMessage
          className='error text-danger'
          errors={errors}
          name={name || 'password'}
          as='small'
        />
      )}
    </CFormGroup>
  );
};

export default PasswordInput;
