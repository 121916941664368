import { Next } from '../next';

export const getInvestorNext = new Next(`/investors`, 'GET_INVESTOR', {
  method: 'GET',
});

export const updateInvestorNext = new Next(`/investors`, 'UPDATE_INVESTOR', {
  method: 'PATCH',
});

export const updateInvestorOtherItemsNext = new Next(
  `/investors/other-items`,
  'UPDATE_INVESTOR_OTHER_ITEMS',
  {
    method: 'PATCH',
  },
);

export const getInvestorDocumentsNext = new Next(
  `/documents?type=investor`,
  'GET_INVESTOR_DOCUMENTS',
  {
    method: 'GET',
  },
);

export const refreshDisplayDocumentsNext = new Next(
  `/documents/display`,
  'REFRESH_DISPLAY_DOCUMENTS',
  {
    method: 'GET',
  },
);

export const uploadTermsOfUseNext = new Next('/documents/terms-of-use', 'UPLOAD_TERMS_OF_USE', {
  method: 'POST',
});

export const uploadElectronicDeliveryNext = new Next(
  '/documents/electronic-delivery',
  'UPLOAD_ELECTRONIC_DELIVERY',
  {
    method: 'POST',
  },
);

export const startKycNext = new Next('/investors/kyc', 'START_KYC', {
  method: 'POST',
});

export const updateKycStatusNext = new Next('/investors/kyc-status', 'UPDATE_KYC_STATUS', {
  method: 'PATCH',
});

export const getOpportunityListOfTokenDocumentsNext = new Next(
  '/documents/opportunity-list',
  'GET_DOCUMENTS_OPPORTUNITY_LIST',
  {
    method: 'GET',
  },
);

export const getAddressCompleteNext = new Next(
  `/investors/address-complete`,
  'GET_ADDRESS_COMPLETE',
  {
    method: 'GET',
  },
);

export const createEposnetAccessTokenNext = new Next(
  '/oauth/eposnet/access-token',
  'CREATE_EPOSNET_ACCESS_TOKEN',
  { method: 'POST' },
);

export const getEposnetUserInfoNext = new Next(
  '/oauth/eposnet/user-info',
  'GET_EPOSNET_USER_INFO',
  {
    method: 'GET',
  },
);

export const getOpportunityDocumentsNext = new Next(
  '/documents?type=opportunity',
  'GET_OPPORTUNITY_DOCUMENTS',
  {
    method: 'GET',
  },
);

export const getDomainDocumentsNext = new Next('/documents?type=domain', 'GET_DOMAIN_DOCUMENTS', {
  method: 'GET',
});

export const getAnnualTransactionReportsNext = new Next(
  '/documents?type=annual-transaction-reports',
  'GET_ANNUAL_TRANSACTION_REPORTS',
  {
    method: 'GET',
  },
);

export const getDocumentNext = new Next('/documents/{documentId}', 'GET_DOCUMENT', {
  method: 'GET',
});

export const getReadOpportunityDocumentsNext = new Next('/read-documents', 'GET_READ_DOCUMENTS', {
  method: 'GET',
});

export const SET_HAS_ISSUED_OAUTH_ACCESS_TOKEN = 'SET_HAS_ISSUED_OAUTH_ACCESS_TOKEN';

export const getInvestorVerifiedTokensNext = new Next(
  '/investors/verified-tokens',
  'GET_INVESTOR_VERIFIED_TOKENS',
  { method: 'GET' },
);

export const verifyInvestorTokenNext = new Next(
  '/investors/verified-tokens',
  'VERIFY_INVESTOR_TOKEN',
  {
    method: 'POST',
  },
);
