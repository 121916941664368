import React, { PropsWithChildren } from 'react';
import { CTabs } from '@coreui/react';

const TabMenu = ({ children, ...props }: PropsWithChildren<CTabs>) => {
  return (
    <div>
      <CTabs {...props}>{children}</CTabs>
    </div>
  );
};

export default TabMenu;
