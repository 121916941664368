import classNames from 'classnames';
import React from 'react';
import {
  InvestmentDetailDtoStatusEnum,
  OpportunityDetailDto,
  OpportunityDto,
} from '@web-api-client';
import { Card, CardMedia } from '../Card';
import {
  Content,
  Header,
  Description,
  RoundInfo,
  Progress,
  ActionButton,
  RedirectToDetailButton,
} from './components';
import styles from './OpportunityCard.module.scss';

export interface IOpportunityCard {
  opportunity: OpportunityDto | OpportunityDetailDto;
  investmentStatus?: InvestmentDetailDtoStatusEnum;
  className?: string;
  isDetail?: boolean;
  showProgress?: boolean;
  actionButton?: React.ReactElement;
  isListCard?: boolean;
}

const OpportunityCard = ({
  opportunity,
  investmentStatus,
  className,
  isDetail = false,
  showProgress = true,
  actionButton,
  isListCard,
}: IOpportunityCard) => {
  return (
    <Card
      className={classNames(
        'overflow-hidden mb-0',
        {
          [styles.ListCard]: isListCard,
        },
        className,
      )}
    >
      <CardMedia
        image={opportunity.image}
        backupImage='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8+R8AAvcB+vBGZskAAAAASUVORK5CYII='
      />
      <Content isListCard={isListCard}>
        <Header
          title={opportunity.title}
          status={opportunity.roundStatus}
          investmentStatus={investmentStatus}
        />
        <Description
          description={opportunity.content}
          isDetail={isDetail}
          isListCard={isListCard}
        />
        <RoundInfo
          roundAdditionalInfo={opportunity.roundAdditionalInfo}
          roundStatus={opportunity.roundStatus}
          isListCard={isListCard}
        />
        {showProgress && (
          <Progress
            roundStatus={opportunity.roundStatus}
            amountPledged={opportunity.amountPledged}
            minAmountToBeRaised={opportunity.roundAdditionalInfo.minAmountToBeRaised}
            maxAmountToBeRaised={opportunity.roundAdditionalInfo.maxAmountToBeRaised}
            isListCard={isListCard}
            currency={opportunity.roundAdditionalInfo.currency}
          />
        )}
        {isDetail ? (
          <>{actionButton && <ActionButton actionButton={actionButton} />}</>
        ) : (
          <RedirectToDetailButton
            isListCard={isListCard}
            opportunityId={opportunity.opportunityId}
          />
        )}
      </Content>
    </Card>
  );
};

export default OpportunityCard;
