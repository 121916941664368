export function extractNumberFromPx(value: string) {
  // Use regular expression to extract the numeric part (removing "px" if it exists)
  const match = value.match(/^(\d+)px$/);
  if (match && match[1]) {
    // Convert the extracted value to a number and return it
    return parseInt(match[1], 10);
  }
  // Return 0 or NaN or handle the error in a way that makes sense for your use case
  return 0;
}
