import { configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { rootReducer } from './reducer';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: {
      name: 'domain-investor-dashboard',
    },
  });
};

let store = setupStore();

export const getStore = () => store;

export const connectTestStore = (appStore: AppStore) => {
  store = appStore;
  return appStore;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export type CommonError = {
  message: string;
  name: string;
  statusCode: number;
};
