import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

export function useWindowResize() {
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    // Debounce to avoid the function fire multiple times
    const handleResizeThrottled = throttle(function handleResize() {
      setWindowSize(getSize());
    }, 250);

    window.addEventListener('resize', handleResizeThrottled);
    return () => window.removeEventListener('resize', handleResizeThrottled);
  }, []);

  return windowSize;
}
