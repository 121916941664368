import { useSettingSelector } from '@state/setting/hooks';
import { useI18nSelector } from '@state/i18n/hooks';
import { createIntl, createIntlCache } from '@formatjs/intl';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-displaynames/locale-data/ja';
import { GroupedOptions } from '@components/Form/SelectInput/SelectInput';
import { translate } from '@utils/translate';
import { countryCodes as _countryCodes, selectOptionSeparator } from '@utils/constants';

export const useNationality = () => {
  const { isLoading, setting } = useSettingSelector();
  const { isLoading: isLoadingI18n, activeLanguage } = useI18nSelector();

  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: activeLanguage.toLowerCase(),
      defaultLocale: 'en',
    },
    cache,
  );

  const getNationalityCodes = (_list: string[], correctList: string[]) => {
    const list = _list.map(code => code.toUpperCase());
    return list.filter(code => correctList.includes(code));
  };

  const getNationalities = () => {
    if (isLoading || isLoadingI18n) return [];
    const nationalitySetting = setting?.variables.nationalitySetting;
    const blackList = getNationalityCodes(nationalitySetting?.blacklist || [], _countryCodes);
    const countryCodes = _countryCodes.filter(code => !blackList.includes(code));
    const whiteList = getNationalityCodes(nationalitySetting?.whitelist || [], countryCodes);
    const orderList = getNationalityCodes(nationalitySetting?.orderList || [], countryCodes);

    const getOptions = (list: string[]) =>
      list.map(nationality => ({
        label: intl.formatDisplayName(nationality, { type: 'region' }) || '',
        value: nationality,
      })) || [];
    const priority = getOptions(
      whiteList?.length
        ? orderList.filter(code => whiteList.includes(code))
        : orderList.filter(code => countryCodes.includes(code)),
    );
    const normal = getOptions(
      whiteList?.length
        ? whiteList.filter(code => !orderList.includes(code))
        : countryCodes.filter(code => !orderList.includes(code)),
    );
    const nationalities = [
      priority.length ? priority : undefined,
      normal.length ? normal : undefined,
      !whiteList?.length ? [{ label: translate('Texts.other'), value: 'other' }] : undefined,
    ];

    return nationalities
      .filter(nationality => !!nationality)
      .map((nationality, i) => ({
        label: i === 0 ? '' : selectOptionSeparator,
        value: nationality,
      })) as GroupedOptions;
  };

  const getNationality = (regionCode: string) => {
    const nationality = intl.formatDisplayName(regionCode, { type: 'region' }) || '';
    return isLoading || isLoadingI18n ? '' : nationality;
  };

  return { getNationalities, getNationality };
};
