import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';
import { Translate } from '../Translate';
import './ReturnLink.scss';

export interface IReturnLink {
  className?: string;
  path?: string;
  title?: string;
}
const ReturnLink = ({ className, path, title }: PropsWithChildren<IReturnLink>) => {
  const history = useHistory();
  const goBack = () => {
    if (path) {
      history.push(path);
    } else {
      history.goBack();
    }
  };
  return (
    <div className={classNames('ReturnLinkComponent', className)}>
      <Button className='p-0 d-flex align-items-center' color='link' onClick={() => goBack()}>
        <div className='rotate-left-90'>
          <i className='icon icon-point-up font-20 text-dark' />
        </div>
        <span className='font-20 text-dark'>
          {!title ? <Translate translationKey='Buttons.back' /> : title}
        </span>
      </Button>
    </div>
  );
};

export default ReturnLink;
