import type { PathOptions, Paths } from './types';

export const path = (root: string, sublink: string) => {
  return `${root}/${sublink}`;
};

export const paths = (pathsObj: Paths, options?: PathOptions) => {
  const paths = Object.values(pathsObj);

  const omit = options?.omit;

  return omit ? paths.filter(path => !omit.includes(path)) : paths;
};
