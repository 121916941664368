import classNames from 'classnames';
import React, { useState, PropsWithChildren } from 'react';
import { CCollapse } from '@coreui/react';
import Card from './Card';
import CardHeader from './CardHeader';

import './ExpansionCard.scss';

export interface IExpansionCardProps {
  title: string | JSX.Element;
  className?: string;
  headerClassName?: string;
  image?: string;
  icon?: string;
  iconLeft?: boolean;
  hideDefault?: boolean;
  showIcon?: boolean;
  size?: 'sm' | 'md' | 'lg';
  headerContent?: JSX.Element;
}

interface IOpenIcon {
  collapse: boolean;
  size: 'sm' | 'md' | 'lg';
}
const OpenIcon = ({ collapse, size }: IOpenIcon) => {
  return (
    <div
      className={classNames('rotate-icon', {
        ['rotate-icon-close']: collapse,
        ['rotate-icon-open']: !collapse,
      })}
    >
      <i
        className={classNames('icon-point-down', {
          ['font-32']: size === 'lg',
          ['font-24']: size === 'md',
          ['font-18']: size === 'sm',
        })}
      />
    </div>
  );
};

const ExpansionCard = ({
  title,
  className,
  headerClassName,
  icon,
  iconLeft = false,
  hideDefault = false,
  showIcon = true,
  children,
  size = 'lg',
  headerContent,
}: PropsWithChildren<IExpansionCardProps>) => {
  const [collapse, setCollapse] = useState(!hideDefault);

  const openIcon = showIcon ? <OpenIcon collapse={collapse} size={size} /> : undefined;

  return (
    <Card className={classNames('ExpansionCardComponent', className)}>
      <CardHeader
        className={classNames(headerClassName)}
        title={title}
        icon={icon}
        onClick={() => setCollapse(!collapse)}
        size={size}
        startAdornment={iconLeft ? openIcon : undefined}
        endAdornment={iconLeft ? undefined : openIcon}
      >
        {headerContent}
      </CardHeader>
      <CCollapse show={collapse}>{children}</CCollapse>
    </Card>
  );
};

export default ExpansionCard;
