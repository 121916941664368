import {
  Gender,
  MappedDomainInvestorGeneralProfileInvestorTypeEnum,
  GenerateTfaSeedResponseDto,
} from '@web-api-client';

export type IOtherItems = { [key: string]: string };

export const investmentExperienceTypes = [
  'stock',
  'publicBond',
  'investmentTrust',
  'futures',
  'foreignCurrencyDeposits',
  'forexMarginTrading',
  'marginTrading',
] as const;

export interface IGeneralProfile {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  birthdate: string | null;
  gender: Gender | null;
  investorType: MappedDomainInvestorGeneralProfileInvestorTypeEnum;
  countryCode: string;
  city: string | null;
  zipCode: string | null;
  address: string | null;
  additionalInfo: string | null;
  language: string;

  birthYear?: number;
  birthMonth?: number;
  birthDay?: number;
}

export interface IPostLoginRequest {
  email: string;
  password: string;
  recaptchaToken: string;
}

export interface IPostSsoLoginRequest {
  data: string;
}

export interface ITwoFactorAuth {
  password: string;
  isPasswordVerified: boolean;
  seed: GenerateTfaSeedResponseDto | null;
}

export enum AuthErrorTypes {
  WRONG_EMAIL_OR_PASSWORD = 'WRONG_EMAIL_OR_PASSWORD',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  REGISTRATION_REQUEST_EXPIRED = 'REGISTRATION_REQUEST_EXPIRED', // verification email expiration
  TWO_FA_CODE_REQUIRED = 'TWO_FA_CODE_REQUIRED',
  WRONG_TWO_FA_CODE = 'WRONG_TWO_FA_CODE',
  RESET_PASSWORD_REQUEST_EXPIRED = 'RESET_PASSWORD_REQUEST_EXPIRED',
  INVESTOR_RESIGNED = 'INVESTOR_RESIGNED',
  ACCOUNT_WAS_LOCKED = 'ACCOUNT_WAS_LOCKED',
}

export enum TokenState {
  EXPIRED,
  VALID,
  NOT_SET,
}
