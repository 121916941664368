export const logo = [
  '102 102',
  `
<title>securitize logo</title>
<svg viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.24684 51.51C6.24881 44.3469 7.98849 37.2913 11.3166 30.9484C14.6448 24.6055 19.462 19.1647 25.3551 15.0927C31.2482 11.0208 38.0411 8.43925 45.1512 7.56965C52.2612 6.70004 59.4761 7.56831 66.1768 10.1C66.9905 8.09554 68.2329 6.29341 69.8168 4.81996C59.8693 0.708617 48.8686 -0.118117 38.4183 2.46027C27.9679 5.03865 18.6143 10.8874 11.7207 19.1539C4.82705 27.4204 0.753866 37.6726 0.0948188 48.4161C-0.564228 59.1596 2.22531 69.8328 8.05684 78.88C9.19593 77.0299 10.7206 75.4473 12.5268 74.24C8.40856 67.374 6.23757 59.5163 6.24684 51.51Z" fill="#F49E39"/>
  <path d="M92.5968 23.5599C91.4507 25.3914 89.9226 26.9537 88.1168 28.1399C93.022 36.0417 95.3146 45.2874 94.6695 54.5655C94.0243 63.8435 90.4741 72.6829 84.5225 79.8298C78.5708 86.9766 70.52 92.068 61.5119 94.3817C52.5038 96.6954 42.9959 96.114 34.3368 92.7199C33.4484 94.7049 32.1339 96.47 30.4868 97.8899C40.4851 102.207 51.6105 103.177 62.205 100.656C72.7995 98.1354 82.2959 92.2587 89.2788 83.9019C96.2617 75.5451 100.357 65.1556 100.955 54.2818C101.553 43.4079 98.6214 32.632 92.5968 23.5599Z" fill="#F49E39"/>
  <path d="M80.2268 24.28C85.1145 24.28 89.0768 20.3177 89.0768 15.43C89.0768 10.5422 85.1145 6.57996 80.2268 6.57996C75.3391 6.57996 71.3768 10.5422 71.3768 15.43C71.3768 20.3177 75.3391 24.28 80.2268 24.28Z" fill="#F49E39"/>
  <path d="M20.5668 95.8799C25.4545 95.8799 29.4168 91.9177 29.4168 87.0299C29.4168 82.1422 25.4545 78.1799 20.5668 78.1799C15.6791 78.1799 11.7168 82.1422 11.7168 87.0299C11.7168 91.9177 15.6791 95.8799 20.5668 95.8799Z" fill="#F49E39"/>
  <path d="M50.5168 19C44.089 18.998 37.8048 20.9022 32.4591 24.4717C27.1134 28.0412 22.9463 33.1157 20.4846 39.0536C18.0229 44.9914 17.3773 51.5259 18.6294 57.8306C19.8815 64.1354 22.975 69.9273 27.5188 74.4739C32.0626 79.0205 37.8526 82.1176 44.1566 83.3736C50.4606 84.6295 56.9955 83.9879 62.9348 81.5299C68.8742 79.0719 73.9513 74.9078 77.5241 69.5643C81.0968 64.2208 83.0049 57.9379 83.0069 51.51C83.0082 47.242 82.1688 43.0156 80.5368 39.072C78.9047 35.1284 76.5119 31.5449 73.4949 28.5261C70.4779 25.5073 66.8959 23.1122 62.9533 21.4777C59.0107 19.8433 54.7848 19.0013 50.5168 19ZM58.0069 65.71C55.8869 67.4033 52.9435 68.25 49.1768 68.25C45.9909 68.2983 42.8364 67.6141 39.9569 66.25V59.87C41.7586 60.7078 43.6246 61.4 45.5368 61.94C46.8952 62.3202 48.2965 62.5252 49.7069 62.55C50.9321 62.6276 52.1514 62.3237 53.1968 61.68C53.5981 61.383 53.9189 60.9907 54.1302 60.5384C54.3415 60.0861 54.4366 59.5883 54.4069 59.09C54.4142 58.4816 54.2287 57.8865 53.8769 57.39C53.4243 56.8336 52.8756 56.3628 52.2569 56C50.8853 55.1677 49.4662 54.4164 48.0068 53.75C46.4024 53.0488 44.8963 52.1411 43.5268 51.05C42.5477 50.2127 41.7395 49.194 41.1468 48.05C40.5296 46.8088 40.2241 45.4358 40.2568 44.05C40.1943 42.7691 40.4248 41.4908 40.9308 40.3125C41.4368 39.1341 42.205 38.0867 43.1768 37.25C45.1768 35.61 47.8369 34.78 51.2769 34.78C52.9092 34.7724 54.5358 34.974 56.1169 35.38C57.7886 35.8145 59.4236 36.3796 61.0069 37.07L58.7769 42.43C57.3768 41.8332 55.9366 41.3353 54.4669 40.94C53.3712 40.6654 52.2464 40.5243 51.1169 40.52C50.0395 40.4485 48.9729 40.772 48.1168 41.43C47.7835 41.7273 47.5208 42.0952 47.3478 42.5069C47.1747 42.9187 47.0959 43.3639 47.1168 43.81C47.1033 44.3736 47.2526 44.9291 47.5469 45.41C47.9002 45.9329 48.3561 46.3786 48.8869 46.72C50.2711 47.5631 51.7035 48.3244 53.1768 49C56.2169 50.46 58.3035 51.92 59.4369 53.38C60.5931 54.9302 61.1889 56.827 61.1269 58.76C61.1889 60.082 60.9387 61.4001 60.3967 62.6075C59.8547 63.815 59.036 64.8778 58.0069 65.71Z" fill="#20547B"/>
</svg>
`,
];
