import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Route } from '@routes/types';
import { SET_CURRENT_STEP_INDEX, SET_WIZARD_STEPS } from './actionTypes';

export interface IWizardState {
  steps: Partial<Route>[];
  currentStepIndex: number;
  nextStepPath?: string | null;
  prevStepPath?: string | null;
}

export const initialWizardState: IWizardState = {
  steps: [],
  currentStepIndex: 0,
  nextStepPath: '',
  prevStepPath: '',
};

export const wizard = createReducer(initialWizardState, builder =>
  builder
    .addCase<string, PayloadAction<Partial<Route>[]>>(
      SET_WIZARD_STEPS,
      (state, { payload }): IWizardState => ({
        ...state,
        steps: payload,
      }),
    )
    .addCase<string, PayloadAction<number>>(
      SET_CURRENT_STEP_INDEX,
      (state, { payload: index }): IWizardState => ({
        ...state,
        currentStepIndex: index,
        nextStepPath: index + 1 >= state.steps.length ? null : state.steps[index + 1].path,
        prevStepPath: index - 1 < 0 ? null : state.steps[index - 1].path,
      }),
    ),
);
