/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { connect } from 'react-redux';
import { getTranslation } from '@utils/translate';

// dont make one liner, \n doesnt work as new line with white-space:pre, this does
const newLine = `
`;
const replaceWithNewLine = (string: string) => string.replace(/\\n|<br>|<br\/>>|<br \/>/g, newLine);

const markupBold = (translation: string) => {
  const parts = translation.split(/<b>|<\/b>|<\/ b>/);
  if (parts.length > 1) {
    return parts.reduce((res: any, part, index) => {
      if (index % 2) {
        res.push(<strong key={`part-${res.length}`}>{replaceWithNewLine(part)}</strong>);
      } else {
        res.push(replaceWithNewLine(part));
      }
      return res;
    }, []);
  }
  return replaceWithNewLine(translation);
};

const TranslateComp: React.FC<{
  translationKey: string;
  translations?: any;
  parameters?: any;
}> = ({ translationKey, translations, parameters }) =>
  translationKey ? (
    <>
      {/* {
        process.env.NODE_ENV === 'development' && <ReactComment text={translationKey} />
      } */}
      {markupBold(getTranslation(translations, translationKey, parameters))}
    </>
  ) : null;

const mapStateToProps = ({ i18n }: any) => ({ translations: i18n?.translations || {} });

export const Translate = connect(mapStateToProps, {})(TranslateComp);
