import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { translate } from '@utils/translate';
import { Chip } from '../Chip';
import {
  OpportunityDetailDtoRoundStatusEnum,
  OpportunityDtoRoundStatusEnum,
} from '@web-api-client';
import styles from './StatusChip.module.scss';

export interface IStatusChip {
  className?: string;
  status: OpportunityDtoRoundStatusEnum | OpportunityDetailDtoRoundStatusEnum;
}

const StatusChip = ({ className, status }: PropsWithChildren<IStatusChip>) => {
  return (
    <Chip
      className={classNames(className, styles[status])}
      text={translate(`Texts.opportunity.status.${status}`)}
    />
  );
};

export default StatusChip;
