import { PayloadAction, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { ProviderInfoResponse } from '@web-api-client';
import { getProviderInfoNext } from './actionTypes';

type IBaseState = {
  isLoading: boolean;
  isError: boolean;
  error: string | Record<string, string> | null;
};

const providersAdapter = createEntityAdapter<ProviderInfoResponse>({
  selectId: providerInfo => providerInfo.network.tokenId,
});

const initialState = providersAdapter.getInitialState<IBaseState>({
  isLoading: false,
  isError: false,
  error: null,
});

export const providers = createReducer(initialState, builder =>
  builder
    .addCase(getProviderInfoNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<ProviderInfoResponse>>(
      getProviderInfoNext.SUCCESS,
      (state, { payload: provider }) => {
        state.isLoading = false;
        state.isError = false;
        state.error = null;
        providersAdapter.upsertOne(state, provider);
      },
    )
    .addCase<string, PayloadAction<void>>(getProviderInfoNext.ERROR, state => {
      state.isLoading = false;
      state.isError = true;
    }),
);

export type IProvidersState = ReturnType<typeof providers.getInitialState>;
