import dayjs from 'dayjs';
import React from 'react';
import {
  RoundAdditionalInfo,
  OpportunityDtoRoundStatusEnum,
  OpportunityDetailDtoRoundStatusEnum,
} from '@web-api-client';
import { Currency } from '../../Currency';
import ModeComponentSwitch from '../../ModeComponentSwitch/ModeComponentSwitch';
import { Translate } from '../../Translate';
import styles from './RoundInfo.module.scss';

interface IRoundInfo {
  roundAdditionalInfo: RoundAdditionalInfo;
  roundStatus: OpportunityDtoRoundStatusEnum | OpportunityDetailDtoRoundStatusEnum;
  isListCard?: boolean;
}

const RoundInfo = ({
  roundAdditionalInfo: {
    annualInterestRate,
    operationTerm,
    operationEndsAt,
    operationStartsAt,
    applicationStartsAt,
    applicationEndsAt,
    maxAmountToBeRaised,
    currency,
  },
  roundStatus,
  isListCard,
}: IRoundInfo) => {
  const operationPeriodInMonths = dayjs(operationEndsAt).diff(dayjs(operationStartsAt), 'month');
  const operationYears = Math.floor(operationPeriodInMonths / 12);
  const operationMonths = Math.floor(operationPeriodInMonths % 12) || null;

  const isAfterOperation = [
    OpportunityDtoRoundStatusEnum.Operating,
    OpportunityDtoRoundStatusEnum.OperationEnded,
    OpportunityDtoRoundStatusEnum.Redeemed,
  ].some(s => s === roundStatus);

  const DisplayElement = isListCard ? 'h5' : 'h6';

  const Display = ({
    title,
    value,
  }: {
    title: string | JSX.Element;
    value: string | JSX.Element;
  }) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <DisplayElement className='m-0'>{title}</DisplayElement>
      <DisplayElement className='m-0'>{value}</DisplayElement>
    </div>
  );

  return (
    <div className={styles.RoundInfo}>
      <Display
        title={
          <ModeComponentSwitch
            ftkComponent={<Translate translationKey='Texts.opportunity.expected-yield' />}
            cbSelfOfferingComponent={<Translate translationKey='Texts.opportunity.interest-rate' />}
          />
        }
        value={`${annualInterestRate}%`}
      />
      <Display
        title={
          <ModeComponentSwitch
            ftkComponent={
              <Translate translationKey='Texts.opportunity.expected-operation-period' />
            }
            cbSelfOfferingComponent={<Translate translationKey='Texts.opportunity.term' />}
          />
        }
        value={
          operationTerm ? (
            operationTerm
          ) : (
            <>
              <Translate translationKey='Texts.unit.years' parameters={{ years: operationYears }} />
              {operationMonths && (
                <Translate
                  translationKey='Texts.unit.months'
                  parameters={{ months: operationMonths }}
                />
              )}
            </>
          )
        }
      />
      <Display
        title={<Translate translationKey='Texts.opportunity.max-amount-to-be-raised' />}
        value={
          maxAmountToBeRaised ? (
            <Currency amount={maxAmountToBeRaised} currency={currency} />
          ) : (
            <Translate translationKey={`Texts.opportunity.progress.pending`} />
          )
        }
      />
      <Display
        title={
          <Translate
            translationKey={`Texts.opportunity.${
              isAfterOperation ? 'operation-period' : 'application-period'
            }`}
          />
        }
        value={
          <>
            <div style={{ whiteSpace: 'pre' }}>
              {dayjs(isAfterOperation ? operationStartsAt : applicationStartsAt).format(
                'YYYY/MM/DD HH:mm',
              )}
              &nbsp;
            </div>
            <div style={{ whiteSpace: 'pre' }}>
              ~&nbsp;
              {dayjs(isAfterOperation ? operationEndsAt : applicationEndsAt).format(
                'YYYY/MM/DD HH:mm',
              )}
            </div>
          </>
        }
      />
    </div>
  );
};

export default RoundInfo;
