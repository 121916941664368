import React, { PropsWithChildren, useEffect, useState } from 'react';
import { CLabel, CInputFile } from '@coreui/react';
import classNames from 'classnames';
import fileInputImg from '@assets/img/other/file-input.svg';
import { translate } from '@utils/translate';
import { ContentType } from '@web-api-client';
import { Loading } from '../../Loading';
import { Translate } from '../../Translate';
import './FileInput.scss';

export interface IFileInputProps extends Omit<PropsWithChildren<CInputFile>, 'label'> {
  initThumbnail?: string;
  maxBytes?: number;
  description?: string;
  onLoadFile?: (fileDataUri: string) => void;
  onDeleteFile?: () => void;
  label?: string | React.ReactElement;
}

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const FileInput = ({
  children,
  label,
  className,
  description,
  initThumbnail,
  maxBytes,
  onLoadFile,
  onDeleteFile,
  ...props
}: IFileInputProps) => {
  const [dataUri, setDataUri] = useState('');
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [fileType, setFileType] = useState('');
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorLoadingThumb, setIsErrorLoadingThumb] = useState(false);

  useEffect(() => {
    if (initThumbnail) {
      setIsSelectedFile(true);
      setDataUri(initThumbnail);
    }
  }, [initThumbnail]);

  const fileSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length <= 0) return;
    if (maxBytes && e.target.files?.[0].size > maxBytes) {
      setErrorMessage(translate('Errors.file-size', { maxBytes: formatBytes(maxBytes) }));
      return;
    }
    setErrorMessage('');

    setIsSelectedFile(true);
    const file = e.target.files?.[0];
    setFileType(file.type);
    setFileName(file.name);

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        if (!reader.result) return;
        setIsErrorLoadingThumb(false);
        setDataUri(reader.result as string);
        onLoadFile?.(reader.result as string);
      },
      false,
    );
    reader.readAsDataURL(file);
  };

  const fileDeleteHandler = () => {
    setIsSelectedFile(false);
    setDataUri('');
    onDeleteFile?.();
  };

  const isShowThumb = fileType === ContentType.ImageJpeg || fileType === ContentType.ImagePng;

  const onErrorThumbLoading = () => {
    setIsErrorLoadingThumb(true);
  };

  return (
    <div className={classNames('FileInputComponent', className)}>
      {label &&
        (typeof label === 'string' ? (
          <CLabel className='mr-2'>
            <Translate translationKey={label} />
          </CLabel>
        ) : (
          label
        ))}
      {dataUri && !props.disabled && (
        <CLabel className='delete' onClick={() => fileDeleteHandler()}>
          <Translate translationKey='Texts.delete' />
        </CLabel>
      )}
      {errorMessage && <CLabel className='delete'>{errorMessage}</CLabel>}

      <CLabel className={classNames('container m-0')}>
        {dataUri && !isErrorLoadingThumb && (initThumbnail || isShowThumb) ? (
          <img className='thumbnail' src={dataUri} onError={onErrorThumbLoading} />
        ) : isSelectedFile && !dataUri ? (
          <Loading />
        ) : (
          <>
            <CInputFile onChange={fileSelectHandler} className='position-static ml-0' {...props} />
            <div className='d-flex justify-content-center w-100'>
              <img className='file-icon mb-3' src={fileInputImg} />
            </div>
            {dataUri ? (
              fileName ? (
                <p className='description mb-1'>{fileName}</p>
              ) : (
                <p className='description mb-1'>
                  <Translate translationKey='Texts.file-input.uploaded' />
                </p>
              )
            ) : (
              <>
                <p className='description mb-1'>{description}</p>
                <div className='browse'>
                  <Translate translationKey='Texts.file-input.browse' />
                </div>
              </>
            )}
          </>
        )}
      </CLabel>
    </div>
  );
};

export default FileInput;
