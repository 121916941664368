import React from 'react';
import classNames from 'classnames';

import './Loading.scss';

export const Loading: React.FC<{
  className?: string;
}> = ({ className = '' }) => (
  <div className={classNames('loader fade show pt-3 text-center', className)}>
    <div
      className='spinner-grow text-primary'
      aria-hidden='false'
      aria-label='Loading'
      role='status'
    />
  </div>
);
