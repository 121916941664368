import { Next } from '../next';

export const getTokenWalletsNext = new Next('/token-wallets', 'GET_TOKEN_WALLETS', {
  method: 'GET',
});

export const authorizeNext = new Next('/token-wallets/authorize', 'AUTHORIZE', {
  method: 'POST',
});

export const authorizeInvestorPaysNext = new Next(
  '/token-wallets/authorize-investor-pays',
  'AUTHORIZE_INVESTOR_PAYS',
  {
    method: 'POST',
  },
);

export const authorizeInvestorPaysCompleteNext = new Next(
  '/token-wallets/authorize-investor-pays-complete',
  'AUTHORIZE_INVESTOR_PAYS_COMPLETE',
  {
    method: 'POST',
  },
);

export const withdrawTokensNext = new Next('/token-wallets/withdraw-tokens', 'WITHDRAW_TOKENS', {
  method: 'POST',
});

export const withdrawTokensInvestorPaysNext = new Next(
  '/token-wallets/withdraw-tokens-investor-pays',
  'WITHDRAW_TOKENS_INVESTOR_PAYS',
  { method: 'POST' },
);

export const withdrawTokensInvestorPaysCompleteNext = new Next(
  '/token-wallets/withdraw-tokens-investor-pays-complete',
  'WITHDRAW_TOKENS_INVESTOR_PAYS_COMPLETE',
  { method: 'POST' },
);
