import classNames from 'classnames';
import React, { FC } from 'react';
import { CLabel } from '@coreui/react';
import { Badge } from '../Badge';
import { Translate } from '../Translate';
import './BadgedLabel.scss';

export interface IBadgedLabelProps {
  label: string;
  badgeType: 'required' | 'optional';
  htmlFor?: string;
  className?: string;
  badgeClassName?: string;
}

const BadgedLabel: FC<IBadgedLabelProps> = ({
  label,
  badgeType,
  htmlFor,
  className,
  badgeClassName,
}) => (
  <CLabel
    className={classNames(
      'BadgedLabelComponent',
      'd-flex',
      'justify-content-start',
      'align-items-center',
      className,
    )}
    htmlFor={htmlFor}
  >
    <Translate translationKey={label} />
    <Badge
      className={classNames(
        {
          'required-badge': badgeType === 'required',
          'optional-badge': badgeType === 'optional',
        },
        badgeClassName,
      )}
    >
      {badgeType === 'required' && <Translate translationKey='Texts.badges.required' />}
      {badgeType === 'optional' && <Translate translationKey='Texts.badges.optional' />}
    </Badge>
  </CLabel>
);

BadgedLabel.defaultProps = {
  htmlFor: '',
};

export default BadgedLabel;
