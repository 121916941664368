import { getLocalStorageItem, LocalStorageKeys, useLocalStorage } from '@hooks/local-storage.hook';
import { PATH_AUTH, PATH_RESET_PASSWORD, PATH_ROOT } from '@routes';
import { refreshSession, logoutUser, resetUserSession } from '@state/auth/actions';
import { useAuthSelector, useSessionExpiration } from '@state/auth/hooks';
import { TokenState } from '@state/auth/models';
import { useSettingSelector } from '@state/setting/hooks';
import React, { useContext, createContext } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import { paths } from '@routes/util';

type SessionContextProps = {
  isInitialized: boolean;
  validateSession: () => void;
  logout: () => void;
  isExpired: boolean;
};

const SessionContext = createContext<SessionContextProps | null>(null);

/**
 * Session provider
 *
 * @description handles session initialization and validation on page load and route change
 * @link https://securitize.atlassian.net/wiki/spaces/JP/pages/2986672129/Session+validation+flow+patterns
 */
export const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { isAuthenticated } = useAuthSelector();
  const { accessTokenState, refreshTokenState } = useSessionExpiration();
  const { setting, isSSO } = useSettingSelector();
  const isInitialized = !isNil(isAuthenticated);

  /**
   * Initialize session after setting has been loaded
   *
   * In SSO mode the user is redirected to SSO Login page and
   * must not be initialized here
   */
  useEffect(() => {
    if (setting && !isSSO) {
      if (!isInitialized) {
        initializeSession();
      }
    }
  }, [setting]);

  /**
   * Validate session on route change (except logout route)
   *
   * Must be done after session initialization, otherwise
   * this will be triggered twice on the first load
   *
   * Must not be triggered on logout route, because
   * logout and login will trigger at the same time and logout will not work
   * as intended
   */
  useEffect(() => {
    if (isInitialized) {
      if (![...paths(PATH_AUTH), ...paths(PATH_RESET_PASSWORD)].includes(location.pathname)) {
        validateSession();
      }
    }
  }, [location]);

  /**
   * Initialize user session
   *
   * If a valid token (cookies) exist, the user will be logged in
   * on the first load of the application
   *
   * `isAuthenticated` will be set to a boolean from it's initial state (undefined)
   */
  const initializeSession = () => {
    dispatch(refreshSession());
  };

  /**
   * Validate user session
   *
   * If the refresh token is expired, the user session will be reset
   *
   *       -> `isAuthenticated` will be set to `false`
   *
   *
   * If the access token is expired, the user session will be refreshed
   *
   *       refresh token is valid: `isAuthenticated` will stay `true` and tokens will be refreshed
   *       refresh token is expired: `isAuthenticated` will be set to `false`
   */
  const validateSession = () => {
    if (refreshTokenState === TokenState.EXPIRED) {
      dispatch(logoutUser());
    } else if (accessTokenState === TokenState.EXPIRED) {
      dispatch(refreshSession());
    }
  };

  const logout = () => {
    const loggedInExternalId = getLocalStorageItem(LocalStorageKeys.LoggedInExternalId);

    // loggedInExternalId is not defined when the user has logged out from
    // another tab and the session is not yet invalidated in this tab
    if (!loggedInExternalId) {
      dispatch(resetUserSession());
      return;
    }

    if (isAuthenticated) {
      dispatch(logoutUser());
    }
  };

  const handleStorageUpdate = ({ key, newValue }: StorageEvent) => {
    // Needed to ensure that isSSO is set correctly
    if (!setting) return;

    if (key === LocalStorageKeys.LoggedInExternalId) {
      // New login event in another tab
      if (newValue) {
        if (isSSO) {
          dispatch(resetUserSession());
          history.push(PATH_AUTH.login);
          return;
        }
        dispatch(refreshSession());
        history.push(PATH_ROOT);
        return;
      }

      // Logout event happened in another tab
      history.push(PATH_AUTH.logout);
    }
  };

  useLocalStorage({ onEvent: handleStorageUpdate });

  return (
    <SessionContext.Provider
      value={{
        isInitialized,
        validateSession,
        logout,
        isExpired:
          accessTokenState === TokenState.EXPIRED || refreshTokenState === TokenState.EXPIRED,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const ctx = useContext(SessionContext);
  if (!ctx) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return ctx;
};
