import React, { PropsWithChildren, forwardRef, useEffect, useRef } from 'react';
import { CLabel, CInput, innerRef } from '@coreui/react';
import classNames from 'classnames';
import './Input.scss';
import { Translate } from '../../Translate';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { fullToHalf } from '@utils/form';
import { extractNumberFromPx } from '@utils/extract-number-from-px';
import { useWindowResize } from '@hooks/use-window-resize.hook';

export interface IInputProps extends Omit<PropsWithChildren<CInput>, 'label'> {
  label?: string | React.ReactElement;
  forceFullToHalf?: boolean;
  errors?: DeepMap<FieldValues, FieldError> | null;
  error?: boolean;
  inputClassName?: string;
}

const Input = forwardRef(function Input(
  {
    children,
    className,
    label,
    name = '',
    forceFullToHalf = false,
    errors,
    onChange,
    onBlur,
    error,
    inputClassName,
    style: propStyle,
    ...props
  }: IInputProps,
  ref,
) {
  const errorMessageRef = useRef<HTMLDivElement>(null);
  const formGroupRef = useRef<HTMLDivElement>(null);

  const windowSize = useWindowResize();

  const handleTransform: React.ReactEventHandler<HTMLInputElement> = event => {
    const target = event.target as HTMLInputElement;
    if (forceFullToHalf) {
      target.value = fullToHalf(target.value);
    }
  };

  useEffect(() => {
    const formGroup = formGroupRef.current;
    if (!formGroup) return;

    const errorMessage = errorMessageRef.current;

    let originalExtraMargin;
    if (formGroup.dataset.originalExtraMargin === undefined) {
      originalExtraMargin = window.getComputedStyle(formGroup).getPropertyValue('margin-bottom');
      formGroup.dataset.originalExtraMargin = originalExtraMargin;
    } else {
      originalExtraMargin = formGroup.dataset.originalExtraMargin;
    }

    formGroup.style.removeProperty('margin-bottom');
    const formGroupMargin = window.getComputedStyle(formGroup).getPropertyValue('margin-bottom');
    const margin = extractNumberFromPx(formGroupMargin);

    // default margin is 16px from form-group class. we need to keep whatever is extra (if any)
    const originalExtraMarginValue = extractNumberFromPx(originalExtraMargin);
    const calculatedExtraMargin =
      originalExtraMarginValue === 0 ? 0 : originalExtraMarginValue - 16;

    let height: number;
    if (errorMessage) {
      height = errorMessage.clientHeight;
    } else {
      height = 0;
    }

    if (height >= margin) {
      formGroup.style.setProperty(
        'margin-bottom',
        `${height + calculatedExtraMargin}px`,
        'important',
      );
    } else {
      formGroup.style.removeProperty('margin-bottom');
    }
  }, [errorMessageRef.current, formGroupRef.current, errors?.[name], windowSize.width]);

  return (
    <div
      className={classNames(
        'form-group',
        'InputComponent',
        {
          error: error,
        },
        className,
      )}
      ref={formGroupRef}
    >
      {label &&
        (typeof label === 'string' ? (
          <CLabel htmlFor={props.htmlFor}>
            <Translate translationKey={label} />
          </CLabel>
        ) : (
          label
        ))}
      <CInput
        name={name}
        innerRef={ref as innerRef}
        // Input composition １２３ then click enter -> 123
        onCompositionEnd={e => {
          handleTransform(e);
          onChange?.(e);
        }}
        // In case not transformed, transform when removing focus
        onBlur={e => {
          handleTransform(e);
          onBlur ? onBlur(e) : onChange?.(e);
        }}
        onChange={event => {
          onChange?.(event);
        }}
        className={inputClassName}
        style={propStyle}
        {...props}
      />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <small className='errorMessage text-danger' ref={errorMessageRef}>
              {message}
            </small>
          )}
        />
      )}
    </div>
  );
});

export default Input;
