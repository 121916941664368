import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getOpportunitiesNext } from './actionTypes';
import { GetDomainOpportunitiesResponseDto, OpportunityDto } from '@web-api-client';

export interface IOpportunitiesState {
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly totalItems: number;
  readonly opportunities: OpportunityDto[];
  readonly page: number;
  readonly hasMore: boolean;
}

export const initialState: IOpportunitiesState = {
  isLoading: true,
  isError: false,
  totalItems: 0,
  opportunities: [],
  page: 0,
  hasMore: true,
};

export const opportunities = createReducer(initialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getOpportunitiesNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<GetDomainOpportunitiesResponseDto>>(
      getOpportunitiesNext.SUCCESS,
      (state, { payload: opportunities }) => {
        state.totalItems = opportunities.totalItems;
        state.opportunities.push(...opportunities.data);
        state.page += 1;
        state.isLoading = false;
        if (
          opportunities.data.length === opportunities.totalItems ||
          opportunities.data.length <= 0
        ) {
          state.hasMore = false;
        }
      },
    )
    .addCase<string, PayloadAction<void>>(getOpportunitiesNext.ERROR, state => {
      state.isLoading = false;
      state.isError = true;
    }),
);
