import React from 'react';
import { useCurrency } from '@hooks/currency.hook';
import { Currency as CurrencyEnum } from '@web-api-client';
export interface ICurrencyProps {
  amount: number;
  currency?: CurrencyEnum;
}

const Currency = ({ amount, currency }: ICurrencyProps) => {
  const { formatCurrency } = useCurrency({ currency });
  return <> {formatCurrency(amount)} </>;
};

export default Currency;
