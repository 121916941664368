/* tslint:disable */
/* eslint-disable */
/**
 * Domain Investor Dashboard
 * WEB API for Domain Investor Dashboard
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ActivateInvestorDto
 */
export interface ActivateInvestorDto {
  /**
   *
   * @type {string}
   * @memberof ActivateInvestorDto
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof ActivateInvestorDto
   */
  countryCode: string;
  /**
   *
   * @type {string}
   * @memberof ActivateInvestorDto
   */
  registrationToken: string;
  /**
   *
   * @type {string}
   * @memberof ActivateInvestorDto
   */
  nationality: string;
}
/**
 *
 * @export
 * @interface ActivateResponseDto
 */
export interface ActivateResponseDto {
  /**
   *
   * @type {SessionTokenResponseDto}
   * @memberof ActivateResponseDto
   */
  sessionToken: SessionTokenResponseDto;
}
/**
 *
 * @export
 * @interface AdditionalPrivateInfo
 */
export interface AdditionalPrivateInfo {
  /**
   *
   * @type {AdditionalProfile}
   * @memberof AdditionalPrivateInfo
   */
  profile?: AdditionalProfile;
  /**
   *
   * @type {Bank}
   * @memberof AdditionalPrivateInfo
   */
  bank?: Bank;
  /**
   *
   * @type {Compatibility}
   * @memberof AdditionalPrivateInfo
   */
  compatibility?: Compatibility;
}
/**
 *
 * @export
 * @interface AdditionalProfile
 */
export interface AdditionalProfile {
  /**
   *
   * @type {string}
   * @memberof AdditionalProfile
   */
  lastNameKana: string;
  /**
   *
   * @type {string}
   * @memberof AdditionalProfile
   */
  firstNameKana: string;
  /**
   *
   * @type {string}
   * @memberof AdditionalProfile
   */
  nationality: string;
  /**
   *
   * @type {string}
   * @memberof AdditionalProfile
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AdditionalStatuses
 */
export interface AdditionalStatuses {
  /**
   *
   * @type {string}
   * @memberof AdditionalStatuses
   */
  kycStatus?: AdditionalStatusesKycStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AdditionalStatuses
   */
  identificationStatus?: AdditionalStatusesIdentificationStatusEnum;
  /**
   *
   * @type {AdditionalStatusesOperatorConfirmationStatus}
   * @memberof AdditionalStatuses
   */
  operatorConfirmationStatus: AdditionalStatusesOperatorConfirmationStatus;
  /**
   *
   * @type {RegisteredMailStatus}
   * @memberof AdditionalStatuses
   */
  registeredMailStatus?: RegisteredMailStatus;
  /**
   *
   * @type {string}
   * @memberof AdditionalStatuses
   */
  registrationStatus?: AdditionalStatusesRegistrationStatusEnum;
  /**
   *
   * @type {Array<SubscriptionAgreementAdditionalStatus>}
   * @memberof AdditionalStatuses
   */
  subscriptionAgreementAdditionalStatuses?: Array<SubscriptionAgreementAdditionalStatus>;
  /**
   *
   * @type {boolean}
   * @memberof AdditionalStatuses
   */
  ignoreAgeRestriction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AdditionalStatuses
   */
  isResigned?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum AdditionalStatusesKycStatusEnum {
  None = 'none',
  Processing = 'processing',
  Verified = 'verified',
  Rejected = 'rejected',
  Reverted = 'reverted',
}
/**
 * @export
 * @enum {string}
 */
export enum AdditionalStatusesIdentificationStatusEnum {
  None = 'none',
  Processing = 'processing',
  Verified = 'verified',
  Rejected = 'rejected',
  Reverted = 'reverted',
}
/**
 * @export
 * @enum {string}
 */
export enum AdditionalStatusesRegistrationStatusEnum {
  None = 'none',
  Processing = 'processing',
  Verified = 'verified',
  Rejected = 'rejected',
  Reverted = 'reverted',
}

/**
 *
 * @export
 * @interface AdditionalStatusesOperatorConfirmationStatus
 */
export interface AdditionalStatusesOperatorConfirmationStatus {
  /**
   *
   * @type {OperatorConfirmationStatus}
   * @memberof AdditionalStatusesOperatorConfirmationStatus
   */
  kyc?: OperatorConfirmationStatus;
  /**
   *
   * @type {OperatorConfirmationStatus}
   * @memberof AdditionalStatusesOperatorConfirmationStatus
   */
  bank?: OperatorConfirmationStatus;
  /**
   *
   * @type {OperatorConfirmationStatus}
   * @memberof AdditionalStatusesOperatorConfirmationStatus
   */
  compatibility?: OperatorConfirmationStatus;
}
/**
 *
 * @export
 * @interface AddressCompleteResponseDto
 */
export interface AddressCompleteResponseDto {
  /**
   *
   * @type {string}
   * @memberof AddressCompleteResponseDto
   */
  zipcode: string;
  /**
   *
   * @type {Array<AddressSuggestionDto>}
   * @memberof AddressCompleteResponseDto
   */
  suggestions: Array<AddressSuggestionDto>;
}
/**
 *
 * @export
 * @interface AddressSuggestionDto
 */
export interface AddressSuggestionDto {
  /**
   *
   * @type {string}
   * @memberof AddressSuggestionDto
   */
  prefecture: string;
  /**
   *
   * @type {string}
   * @memberof AddressSuggestionDto
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof AddressSuggestionDto
   */
  town: string;
}
/**
 *
 * @export
 * @interface AgeRestriction
 */
export interface AgeRestriction {
  /**
   *
   * @type {number}
   * @memberof AgeRestriction
   */
  min: number;
  /**
   *
   * @type {number}
   * @memberof AgeRestriction
   */
  max: number;
}
/**
 *
 * @export
 * @interface Attribute
 */
export interface Attribute {
  /**
   *
   * @type {string}
   * @memberof Attribute
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Attribute
   */
  content: string;
  /**
   *
   * @type {number}
   * @memberof Attribute
   */
  order: number;
}
/**
 *
 * @export
 * @interface AuthInfoResponseDto
 */
export interface AuthInfoResponseDto {
  /**
   *
   * @type {string}
   * @memberof AuthInfoResponseDto
   */
  domainId: string;
  /**
   *
   * @type {string}
   * @memberof AuthInfoResponseDto
   */
  externalId: string;
  /**
   *
   * @type {boolean}
   * @memberof AuthInfoResponseDto
   */
  isTfaEnabled: boolean;
}
/**
 *
 * @export
 * @interface AuthorizeBodyDto
 */
export interface AuthorizeBodyDto {
  /**
   *
   * @type {string}
   * @memberof AuthorizeBodyDto
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof AuthorizeBodyDto
   */
  walletId: number;
}
/**
 *
 * @export
 * @interface AuthorizeInvestorPaysBodyDto
 */
export interface AuthorizeInvestorPaysBodyDto {
  /**
   *
   * @type {string}
   * @memberof AuthorizeInvestorPaysBodyDto
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof AuthorizeInvestorPaysBodyDto
   */
  walletId: number;
  /**
   *
   * @type {string}
   * @memberof AuthorizeInvestorPaysBodyDto
   */
  payingWalletAddress: string;
}
/**
 *
 * @export
 * @interface AuthorizeInvestorPaysCompleteBodyDto
 */
export interface AuthorizeInvestorPaysCompleteBodyDto {
  /**
   *
   * @type {string}
   * @memberof AuthorizeInvestorPaysCompleteBodyDto
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof AuthorizeInvestorPaysCompleteBodyDto
   */
  signatureTransactionId: number;
  /**
   *
   * @type {string}
   * @memberof AuthorizeInvestorPaysCompleteBodyDto
   */
  signature: string;
}
/**
 *
 * @export
 * @interface AuthorizeInvestorPaysResponseDto
 */
export interface AuthorizeInvestorPaysResponseDto {
  /**
   *
   * @type {string}
   * @memberof AuthorizeInvestorPaysResponseDto
   */
  blockchain: string;
  /**
   *
   * @type {object}
   * @memberof AuthorizeInvestorPaysResponseDto
   */
  additionalData: object;
  /**
   *
   * @type {object}
   * @memberof AuthorizeInvestorPaysResponseDto
   */
  options: object;
  /**
   *
   * @type {number}
   * @memberof AuthorizeInvestorPaysResponseDto
   */
  signatureTransactionId: number;
  /**
   *
   * @type {string}
   * @memberof AuthorizeInvestorPaysResponseDto
   */
  unsignedTransaction: string;
}
/**
 *
 * @export
 * @interface AuthorizeResponseDto
 */
export interface AuthorizeResponseDto {
  /**
   *
   * @type {boolean}
   * @memberof AuthorizeResponseDto
   */
  isInvestorPays: boolean;
}
/**
 *
 * @export
 * @interface Bank
 */
export interface Bank {
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  bankName: string;
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  bankCode: string;
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  branchName: string;
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  branchCode: string;
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  accountNumber: string;
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  accountType: BankAccountTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  eposnetCustomerId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum BankAccountTypeEnum {
  SavingsAccount = 'savings-account',
  CurrentAccount = 'current-account',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum BankAccountProviderMode {
  Eposnet = 'eposnet',
  AozoraBank = 'aozoraBank',
}

/**
 *
 * @export
 * @interface Calculator
 */
export interface Calculator {
  /**
   *
   * @type {number}
   * @memberof Calculator
   */
  tokenPriceUSD: number;
  /**
   *
   * @type {number}
   * @memberof Calculator
   */
  minimumInvestmentFiatMainCurrency: number;
  /**
   *
   * @type {number}
   * @memberof Calculator
   */
  minimumInvestmentCryptoMainCurrency: number;
  /**
   *
   * @type {string}
   * @memberof Calculator
   */
  mainCurrency: string;
}
/**
 *
 * @export
 * @interface CalculatorDataTiers
 */
export interface CalculatorDataTiers {
  /**
   *
   * @type {number}
   * @memberof CalculatorDataTiers
   */
  minInvestmentAmount: number;
  /**
   *
   * @type {number}
   * @memberof CalculatorDataTiers
   */
  bonusPercentage: number;
}
/**
 *
 * @export
 * @interface ColorScheme
 */
export interface ColorScheme {
  /**
   *
   * @type {string}
   * @memberof ColorScheme
   */
  primaryColor: string;
  /**
   *
   * @type {string}
   * @memberof ColorScheme
   */
  secondaryColor: string;
  /**
   *
   * @type {string}
   * @memberof ColorScheme
   */
  actionColor: string;
}
/**
 *
 * @export
 * @interface Compatibility
 */
export interface Compatibility {
  /**
   *
   * @type {string}
   * @memberof Compatibility
   */
  incomeSource: string;
  /**
   *
   * @type {string}
   * @memberof Compatibility
   */
  job: string;
  /**
   *
   * @type {string}
   * @memberof Compatibility
   */
  annualIncome: string;
  /**
   *
   * @type {string}
   * @memberof Compatibility
   */
  assetAmount: string;
  /**
   *
   * @type {string}
   * @memberof Compatibility
   */
  assetType: string;
  /**
   *
   * @type {string}
   * @memberof Compatibility
   */
  investmentPurpose: string;
  /**
   *
   * @type {string}
   * @memberof Compatibility
   */
  investmentPolicy: string;
  /**
   *
   * @type {InvestmentExperiences}
   * @memberof Compatibility
   */
  investmentExperiences: InvestmentExperiences;
  /**
   *
   * @type {string}
   * @memberof Compatibility
   */
  financialWorkExperience: string;
  /**
   *
   * @type {string}
   * @memberof Compatibility
   */
  foreignPoliticallyExposedPerson: string;
  /**
   *
   * @type {number}
   * @memberof Compatibility
   */
  riskLevel?: number;
}
/**
 *
 * @export
 * @interface Content
 */
export interface Content {
  /**
   *
   * @type {number}
   * @memberof Content
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Content
   */
  opportunityId: number;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  attributeType: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  resource?: string;
  /**
   *
   * @type {number}
   * @memberof Content
   */
  order: number;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  alignment: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ContentType {
  ImagePng = 'image/png',
  ImageJpeg = 'image/jpeg',
  ApplicationPdf = 'application/pdf',
}

/**
 *
 * @export
 * @interface CreateEntryDto
 */
export interface CreateEntryDto {
  /**
   *
   * @type {number}
   * @memberof CreateEntryDto
   */
  roundId: number;
  /**
   *
   * @type {string}
   * @memberof CreateEntryDto
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof CreateEntryDto
   */
  opportunityId: number;
  /**
   *
   * @type {number}
   * @memberof CreateEntryDto
   */
  investmentId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateEntryDto
   */
  documentIds: Array<number>;
}
/**
 *
 * @export
 * @interface CreateEposnetAccessTokenBodyDto
 */
export interface CreateEposnetAccessTokenBodyDto {
  /**
   *
   * @type {string}
   * @memberof CreateEposnetAccessTokenBodyDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CreateEposnetAccessTokenBodyDto
   */
  redirectUri: string;
}
/**
 *
 * @export
 * @interface CreateInvestmentDto
 */
export interface CreateInvestmentDto {
  /**
   *
   * @type {number}
   * @memberof CreateInvestmentDto
   */
  roundId: number;
  /**
   *
   * @type {string}
   * @memberof CreateInvestmentDto
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof CreateInvestmentDto
   */
  opportunityId: number;
  /**
   *
   * @type {number}
   * @memberof CreateInvestmentDto
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CreateInvestmentDto
   */
  currencyIdentifier: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateInvestmentDto
   */
  documentIds: Array<number>;
}
/**
 *
 * @export
 * @interface CreateInvestorDto
 */
export interface CreateInvestorDto {
  /**
   *
   * @type {string}
   * @memberof CreateInvestorDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateInvestorDto
   */
  recaptchaToken: string;
}
/**
 *
 * @export
 * @interface CreateKycUrlResponseDto
 */
export interface CreateKycUrlResponseDto {
  /**
   *
   * @type {string}
   * @memberof CreateKycUrlResponseDto
   */
  result: CreateKycUrlResponseDtoResultEnum;
  /**
   *
   * @type {string}
   * @memberof CreateKycUrlResponseDto
   */
  ekycUrl: string;
  /**
   *
   * @type {string}
   * @memberof CreateKycUrlResponseDto
   */
  errorCode?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateKycUrlResponseDtoResultEnum {
  _0 = '0',
  _1 = '1',
}

/**
 *
 * @export
 * @interface CreatePreEntryDto
 */
export interface CreatePreEntryDto {
  /**
   *
   * @type {number}
   * @memberof CreatePreEntryDto
   */
  roundId: number;
  /**
   *
   * @type {string}
   * @memberof CreatePreEntryDto
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof CreatePreEntryDto
   */
  opportunityId: number;
  /**
   *
   * @type {number}
   * @memberof CreatePreEntryDto
   */
  unit: number;
  /**
   *
   * @type {number}
   * @memberof CreatePreEntryDto
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CreatePreEntryDto
   */
  currencyIdentifier: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CreatePreEntryDto
   */
  documentIds: Array<number>;
}
/**
 *
 * @export
 * @interface CreateSessionTokenResponseDto
 */
export interface CreateSessionTokenResponseDto {
  /**
   *
   * @type {SessionTokenResponseDto}
   * @memberof CreateSessionTokenResponseDto
   */
  sessionToken: SessionTokenResponseDto;
  /**
   *
   * @type {boolean}
   * @memberof CreateSessionTokenResponseDto
   */
  isTfaEnabled: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateSessionTokenResponseDto
   */
  externalId: string;
}
/**
 *
 * @export
 * @interface CreateWalletBodyDto
 */
export interface CreateWalletBodyDto {
  /**
   *
   * @type {string}
   * @memberof CreateWalletBodyDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof CreateWalletBodyDto
   */
  source: CreateWalletBodyDtoSourceEnum;
  /**
   *
   * @type {string}
   * @memberof CreateWalletBodyDto
   */
  name: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateWalletBodyDtoSourceEnum {
  Metamask = 'metamask',
  WalletConnect = 'wallet-connect',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum Currency {
  Jpy = 'JPY',
  Usd = 'USD',
}

/**
 *
 * @export
 * @interface DashboardDisplayFlags
 */
export interface DashboardDisplayFlags {
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableUserMenu: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableUserMenuProfile: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableUserMenuBackHome: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableProfilePage: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableDocumentAgreementTab: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableAnnualTransactionReportsTab: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableMobileWizardNavigation: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableUserMenuSecurity: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableSecurityPage: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableLogoNavigation: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableOpportunityProgress: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableTokensPage: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableMenuNavigation: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DashboardDisplayFlags
   */
  enableDetailedDividendHistory: boolean;
}
/**
 *
 * @export
 * @interface DefaultValues
 */
export interface DefaultValues {
  /**
   *
   * @type {string}
   * @memberof DefaultValues
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof DefaultValues
   */
  value: string;
}
/**
 *
 * @export
 * @interface DeleteWalletResponseDto
 */
export interface DeleteWalletResponseDto {
  /**
   *
   * @type {number}
   * @memberof DeleteWalletResponseDto
   */
  deletedWalletId: number;
}
/**
 *
 * @export
 * @interface DepositAccountDto
 */
export interface DepositAccountDto {
  /**
   *
   * @type {number}
   * @memberof DepositAccountDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  domainId: string;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  investorExternalId: string;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  bankAccountProvider: DepositAccountDtoBankAccountProviderEnum;
  /**
   *
   * @type {number}
   * @memberof DepositAccountDto
   */
  balance: number;
  /**
   *
   * @type {number}
   * @memberof DepositAccountDto
   */
  lockedBalance: number;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  holderNameKana: string;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  accountId: string;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  branchCode: string;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  branchNameKana: string;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  accountNumber: string;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof DepositAccountDto
   */
  updatedAt: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DepositAccountDtoBankAccountProviderEnum {
  Eposnet = 'eposnet',
  AozoraBank = 'aozoraBank',
}

/**
 *
 * @export
 * @interface DisableTfaDto
 */
export interface DisableTfaDto {
  /**
   *
   * @type {string}
   * @memberof DisableTfaDto
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof DisableTfaDto
   */
  tfaToken: string;
}
/**
 *
 * @export
 * @interface Distribution
 */
export interface Distribution {
  /**
   *
   * @type {number}
   * @memberof Distribution
   */
  preTaxAmount: number;
  /**
   *
   * @type {number}
   * @memberof Distribution
   */
  withholdingTax: number;
}
/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   *
   * @type {DocumentFace}
   * @memberof Document
   */
  documentFace?: DocumentFace;
  /**
   *
   * @type {DocumentType}
   * @memberof Document
   */
  type: DocumentType;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  investorDocumentId?: string;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  opportunityDocumentId?: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  tokenId: string | null;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  opportunityId?: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  documentTitle: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  expiresDate?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  thumbnailUrl?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum DocumentFace {
  Front = 'front',
  Back = 'back',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum DocumentTitle {
  TermsOfUse = 'terms-of-use',
  ExplanationOfElectronicDelivery = 'explanation-of-electronic-delivery',
  BankInfo = 'bank-info',
  GeneralInfo = 'general-info',
  ImportantMattersOfElectronicTradingOperations = 'important-matters-of-electronic-trading-operations',
  BeforeContractConclusionDocument = 'before-contract-conclusion-document',
  ContractConclusionDocument = 'contract-conclusion-document',
  Selfie = 'selfie',
  Prospectus = 'prospectus',
  AmendmentsForProspectus = 'amendments-for-prospectus',
  DocumentToNotifyImportantMattersForCompaniesAct = 'document-to-notify-important-matters-for-companies-act',
  WrittenConsent = 'written-consent',
  PreEntryImportantMatters = 'pre-entry-important-matters',
  EntryImportantMatters = 'entry-important-matters',
  ProductDescription = 'product-description',
  ConfirmationAboutSendingEmailAndSendDateOfContractConclusionDocument = 'confirmation-about-sending-email-and-send-date-of-contract-conclusion-document',
  GeneralConditionsOfSpecifiedJointRealEstateVentureContract = 'general-conditions-of-specified-joint-real-estate-venture-contract',
  PayoutNotification = 'payout-notification',
  ImportantMattersOfDigitalSecurities = 'important-matters-of-digital-securities',
  AgreementOfDigitalSecurities = 'agreement-of-digital-securities',
  AgreementOfJointMoneyTrust = 'agreement-of-joint-money-trust',
  AgreementOfJointMoneyTrustUsd = 'agreement-of-joint-money-trust-usd',
  BeforeAgreementOfProtectedDepositOfDigitalSecurities = 'before-agreement-of-protected-deposit-of-digital-securities',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum DocumentType {
  Passport = 'passport',
  DriverLicense = 'driver-license',
  NationalIdentityCard = 'national-identity-card',
  ResidencePermit = 'residence-permit',
  Visa = 'visa',
  PassportCard = 'passport-card',
  WorkPermit = 'work-permit',
  StateId = 'state-id',
  UkBiometricResidencePermit = 'uk-biometric-residence-permit',
  TaxId = 'tax-id',
  VoterId = 'voter-id',
  CertificateOfNaturalisation = 'certificate-of-naturalisation',
  HomeOfficeLetter = 'home-office-letter',
  ImmigrationStatusDocument = 'immigration-status-document',
  BirthCertificate = 'birth-certificate',
  Other = 'other',
  Selfie = 'selfie',
  CertificateOfFormation = 'certificate-of-formation',
  ArticlesOfOrganization = 'articles-of-organization',
  OperatingAgreement = 'operating-agreement',
  AddressProof = 'address-proof',
  ShareholdersListAndAuthorisedSignersList = 'shareholders-list-and-authorised-signers-list',
  ByLaws = 'by-laws',
  CashCard = 'cash-card',
  HealthInsuranceCard = 'health-insurance-card',
  MyNumberCard = 'my-number-card',
  DriverHistoryCertificate = 'driver-history-certificate',
  SpecialPermanentResidentCertificate = 'special-permanent-resident-certificate',
  ResidentRecordCard = 'resident-record-card',
  OpportunityDocument = 'opportunity-document',
}

/**
 *
 * @export
 * @interface DomainTokenDto
 */
export interface DomainTokenDto {
  /**
   *
   * @type {number}
   * @memberof DomainTokenDto
   */
  tokenValue: number;
  /**
   *
   * @type {number}
   * @memberof DomainTokenDto
   */
  decimals: number;
  /**
   *
   * @type {string}
   * @memberof DomainTokenDto
   */
  tokenName: string;
  /**
   *
   * @type {string}
   * @memberof DomainTokenDto
   */
  tokenDescription: string;
  /**
   *
   * @type {boolean}
   * @memberof DomainTokenDto
   */
  isTokenRepresentsDebt: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DomainTokenDto
   */
  isDeployed: boolean;
  /**
   *
   * @type {string}
   * @memberof DomainTokenDto
   */
  tokenIconUrl: string;
  /**
   *
   * @type {string}
   * @memberof DomainTokenDto
   */
  tokenVideoUrl: string;
  /**
   *
   * @type {boolean}
   * @memberof DomainTokenDto
   */
  enableFundraise: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DomainTokenDto
   */
  enableTokenLifecycleManagement: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DomainTokenDto
   */
  isInvestorPays: boolean;
  /**
   *
   * @type {string}
   * @memberof DomainTokenDto
   */
  tokenId: string;
}
/**
 *
 * @export
 * @interface EnableTfaDto
 */
export interface EnableTfaDto {
  /**
   *
   * @type {string}
   * @memberof EnableTfaDto
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof EnableTfaDto
   */
  tfaToken: string;
}
/**
 *
 * @export
 * @interface FundraiseOpportunityDocumentDto
 */
export interface FundraiseOpportunityDocumentDto {
  /**
   *
   * @type {number}
   * @memberof FundraiseOpportunityDocumentDto
   */
  documentId: number;
  /**
   *
   * @type {string}
   * @memberof FundraiseOpportunityDocumentDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof FundraiseOpportunityDocumentDto
   */
  documentUrl: string;
  /**
   *
   * @type {number}
   * @memberof FundraiseOpportunityDocumentDto
   */
  order: number;
  /**
   *
   * @type {boolean}
   * @memberof FundraiseOpportunityDocumentDto
   */
  requireQualification: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FundraiseOpportunityDocumentDto
   */
  requireKyc: boolean;
  /**
   *
   * @type {string}
   * @memberof FundraiseOpportunityDocumentDto
   */
  fileType: string;
  /**
   *
   * @type {string}
   * @memberof FundraiseOpportunityDocumentDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof FundraiseOpportunityDocumentDto
   */
  updatedAt: string;
  /**
   *
   * @type {number}
   * @memberof FundraiseOpportunityDocumentDto
   */
  opportunityId: number;
  /**
   *
   * @type {string}
   * @memberof FundraiseOpportunityDocumentDto
   */
  tokenId: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum Gender {
  Male = 'male',
  Female = 'female',
  Empty = '',
}

/**
 *
 * @export
 * @interface GeneralDto
 */
export interface GeneralDto {
  /**
   *
   * @type {string}
   * @memberof GeneralDto
   */
  issuerName: string;
  /**
   *
   * @type {string}
   * @memberof GeneralDto
   */
  permissionServiceSecret: string;
  /**
   *
   * @type {ColorScheme}
   * @memberof GeneralDto
   */
  colorScheme: ColorScheme;
  /**
   *
   * @type {Images}
   * @memberof GeneralDto
   */
  images: Images;
}
/**
 *
 * @export
 * @interface GeneralProfile
 */
export interface GeneralProfile {
  /**
   *
   * @type {Gender}
   * @memberof GeneralProfile
   */
  gender?: Gender;
  /**
   *
   * @type {string}
   * @memberof GeneralProfile
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof GeneralProfile
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof GeneralProfile
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GeneralProfile
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GeneralProfile
   */
  birthdate?: string;
  /**
   *
   * @type {string}
   * @memberof GeneralProfile
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof GeneralProfile
   */
  zipCode?: string;
  /**
   *
   * @type {string}
   * @memberof GeneralProfile
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof GeneralProfile
   */
  additionalInfo?: string;
}
/**
 *
 * @export
 * @interface GenerateTfaSeedDto
 */
export interface GenerateTfaSeedDto {
  /**
   *
   * @type {string}
   * @memberof GenerateTfaSeedDto
   */
  keyName: string;
}
/**
 *
 * @export
 * @interface GenerateTfaSeedResponseDto
 */
export interface GenerateTfaSeedResponseDto {
  /**
   *
   * @type {string}
   * @memberof GenerateTfaSeedResponseDto
   */
  secret: string;
  /**
   *
   * @type {string}
   * @memberof GenerateTfaSeedResponseDto
   */
  uri: string;
  /**
   *
   * @type {string}
   * @memberof GenerateTfaSeedResponseDto
   */
  qr: string;
}
/**
 *
 * @export
 * @interface GetDomainInvestorDocumentResponseDto
 */
export interface GetDomainInvestorDocumentResponseDto {
  /**
   *
   * @type {object}
   * @memberof GetDomainInvestorDocumentResponseDto
   */
  translations: object;
  /**
   *
   * @type {object}
   * @memberof GetDomainInvestorDocumentResponseDto
   */
  languages: object;
}
/**
 *
 * @export
 * @interface GetDomainInvestorTransactionResponseDto
 */
export interface GetDomainInvestorTransactionResponseDto {
  /**
   *
   * @type {number}
   * @memberof GetDomainInvestorTransactionResponseDto
   */
  transactionId: number;
  /**
   *
   * @type {string}
   * @memberof GetDomainInvestorTransactionResponseDto
   */
  transactionTime: string;
  /**
   *
   * @type {string}
   * @memberof GetDomainInvestorTransactionResponseDto
   */
  direction: GetDomainInvestorTransactionResponseDtoDirectionEnum;
  /**
   *
   * @type {number}
   * @memberof GetDomainInvestorTransactionResponseDto
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof GetDomainInvestorTransactionResponseDto
   */
  currencyId: number;
  /**
   *
   * @type {string}
   * @memberof GetDomainInvestorTransactionResponseDto
   */
  data: string | null;
  /**
   *
   * @type {string}
   * @memberof GetDomainInvestorTransactionResponseDto
   */
  source: GetDomainInvestorTransactionResponseDtoSourceEnum;
  /**
   *
   * @type {number}
   * @memberof GetDomainInvestorTransactionResponseDto
   */
  mainCurrencyWorth: number;
  /**
   *
   * @type {string}
   * @memberof GetDomainInvestorTransactionResponseDto
   */
  externalTransactionConfirmation: string;
}

/**
 * @export
 * @enum {string}
 */
export enum GetDomainInvestorTransactionResponseDtoDirectionEnum {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Liquidation = 'liquidation',
  Refund = 'refund',
}
/**
 * @export
 * @enum {string}
 */
export enum GetDomainInvestorTransactionResponseDtoSourceEnum {
  Automated = 'automated',
  Manual = 'manual',
}

/**
 *
 * @export
 * @interface GetDomainOpportunitiesResponseDto
 */
export interface GetDomainOpportunitiesResponseDto {
  /**
   *
   * @type {number}
   * @memberof GetDomainOpportunitiesResponseDto
   */
  totalItems: number;
  /**
   *
   * @type {Array<OpportunityDto>}
   * @memberof GetDomainOpportunitiesResponseDto
   */
  data: Array<OpportunityDto>;
}
/**
 *
 * @export
 * @interface GetDomainQuestionsResponseDto
 */
export interface GetDomainQuestionsResponseDto {
  /**
   *
   * @type {number}
   * @memberof GetDomainQuestionsResponseDto
   */
  totalItems: number;
  /**
   *
   * @type {Array<QuestionDto>}
   * @memberof GetDomainQuestionsResponseDto
   */
  data: Array<QuestionDto>;
}
/**
 *
 * @export
 * @interface GetDomainSettingResponseDto
 */
export interface GetDomainSettingResponseDto {
  /**
   *
   * @type {GeneralDto}
   * @memberof GetDomainSettingResponseDto
   */
  general: GeneralDto;
  /**
   *
   * @type {VariableDto}
   * @memberof GetDomainSettingResponseDto
   */
  variables: VariableDto;
}
/**
 *
 * @export
 * @interface GetEposnetUserInfoResponseDto
 */
export interface GetEposnetUserInfoResponseDto {
  /**
   *
   * @type {string}
   * @memberof GetEposnetUserInfoResponseDto
   */
  eposnetCustomerId: string;
  /**
   *
   * @type {string}
   * @memberof GetEposnetUserInfoResponseDto
   */
  bankCode: string;
  /**
   *
   * @type {string}
   * @memberof GetEposnetUserInfoResponseDto
   */
  branchCode: string;
  /**
   *
   * @type {string}
   * @memberof GetEposnetUserInfoResponseDto
   */
  depositClass: string;
  /**
   *
   * @type {string}
   * @memberof GetEposnetUserInfoResponseDto
   */
  accountNumber: string;
  /**
   *
   * @type {string}
   * @memberof GetEposnetUserInfoResponseDto
   */
  accountName: string;
}
/**
 *
 * @export
 * @interface GetLoginBodyDto
 */
export interface GetLoginBodyDto {
  /**
   *
   * @type {string}
   * @memberof GetLoginBodyDto
   */
  data: string;
}
/**
 *
 * @export
 * @interface GetTransferInstructionsResponseDto
 */
export interface GetTransferInstructionsResponseDto {
  /**
   *
   * @type {string}
   * @memberof GetTransferInstructionsResponseDto
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof GetTransferInstructionsResponseDto
   */
  currencyIdentifier: string;
  /**
   *
   * @type {string}
   * @memberof GetTransferInstructionsResponseDto
   */
  wireTransferInstructions: string;
}
/**
 *
 * @export
 * @interface GetVirtualBankAccountInstructionsResponseDto
 */
export interface GetVirtualBankAccountInstructionsResponseDto {
  /**
   *
   * @type {DepositAccountDto}
   * @memberof GetVirtualBankAccountInstructionsResponseDto
   */
  depositAccount?: DepositAccountDto;
  /**
   *
   * @type {string}
   * @memberof GetVirtualBankAccountInstructionsResponseDto
   */
  transferExpiredAt?: string;
}
/**
 *
 * @export
 * @interface HtmlMetaTagsConfig
 */
export interface HtmlMetaTagsConfig {
  /**
   *
   * @type {string}
   * @memberof HtmlMetaTagsConfig
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof HtmlMetaTagsConfig
   */
  content: string;
}
/**
 *
 * @export
 * @interface IdentificationResultBodyDto
 */
export interface IdentificationResultBodyDto {
  /**
   *
   * @type {number}
   * @memberof IdentificationResultBodyDto
   */
  applicant_id: number;
  /**
   *
   * @type {string}
   * @memberof IdentificationResultBodyDto
   */
  reference_id: string;
  /**
   *
   * @type {string}
   * @memberof IdentificationResultBodyDto
   */
  document_type: IdentificationResultBodyDtoDocumentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof IdentificationResultBodyDto
   */
  accepted_at: string;
  /**
   *
   * @type {boolean}
   * @memberof IdentificationResultBodyDto
   */
  require_face_match: boolean;
  /**
   *
   * @type {string}
   * @memberof IdentificationResultBodyDto
   */
  result_without_face_match: IdentificationResultBodyDtoResultWithoutFaceMatchEnum;
  /**
   *
   * @type {string}
   * @memberof IdentificationResultBodyDto
   */
  completed_at: string;
  /**
   *
   * @type {Array<VerificationErrors>}
   * @memberof IdentificationResultBodyDto
   */
  verification_errors: Array<VerificationErrors>;
  /**
   *
   * @type {RiskDetect}
   * @memberof IdentificationResultBodyDto
   */
  risk_detect: RiskDetect;
  /**
   *
   * @type {string}
   * @memberof IdentificationResultBodyDto
   */
  overall_result: IdentificationResultBodyDtoOverallResultEnum;
  /**
   *
   * @type {string}
   * @memberof IdentificationResultBodyDto
   */
  status: IdentificationResultBodyDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof IdentificationResultBodyDto
   */
  result: IdentificationResultBodyDtoResultEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum IdentificationResultBodyDtoDocumentTypeEnum {
  _1 = '1',
  _2 = '2',
  _3 = '3',
  _4 = '4',
  _5 = '5',
  _6 = '6',
  _7 = '7',
}
/**
 * @export
 * @enum {string}
 */
export enum IdentificationResultBodyDtoResultWithoutFaceMatchEnum {
  Match = 'match',
  Unmatch = 'unmatch',
}
/**
 * @export
 * @enum {string}
 */
export enum IdentificationResultBodyDtoOverallResultEnum {
  Cleared = 'cleared',
  Detected = 'detected',
  Unmatch = 'unmatch',
}
/**
 * @export
 * @enum {string}
 */
export enum IdentificationResultBodyDtoStatusEnum {
  Applying = 'applying',
  Accepted = 'accepted',
  Processing = 'processing',
  Completed = 'completed',
}
/**
 * @export
 * @enum {string}
 */
export enum IdentificationResultBodyDtoResultEnum {
  Match = 'match',
  Unmatch = 'unmatch',
}

/**
 *
 * @export
 * @interface IdentificationResultResponseDto
 */
export interface IdentificationResultResponseDto {
  /**
   *
   * @type {boolean}
   * @memberof IdentificationResultResponseDto
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface ImageResult
 */
export interface ImageResult {
  /**
   *
   * @type {string}
   * @memberof ImageResult
   */
  docFrontImage: string;
  /**
   *
   * @type {string}
   * @memberof ImageResult
   */
  docFrontTrimImage: string;
  /**
   *
   * @type {string}
   * @memberof ImageResult
   */
  docBackImage: string;
  /**
   *
   * @type {string}
   * @memberof ImageResult
   */
  docBackTrimImage: string;
  /**
   *
   * @type {string}
   * @memberof ImageResult
   */
  docTiltedImage: string;
  /**
   *
   * @type {string}
   * @memberof ImageResult
   */
  docTiltedTrimImage: string;
  /**
   *
   * @type {string}
   * @memberof ImageResult
   */
  selfieImage: string;
}
/**
 *
 * @export
 * @interface Images
 */
export interface Images {
  /**
   *
   * @type {string}
   * @memberof Images
   */
  logo: string;
  /**
   *
   * @type {string}
   * @memberof Images
   */
  logoDark: string;
  /**
   *
   * @type {string}
   * @memberof Images
   */
  favicon: string;
}
/**
 *
 * @export
 * @interface InvestableAmountDto
 */
export interface InvestableAmountDto {
  /**
   *
   * @type {number}
   * @memberof InvestableAmountDto
   */
  investLimit: number | null;
  /**
   *
   * @type {number}
   * @memberof InvestableAmountDto
   */
  investableAmount: number | null;
}
/**
 *
 * @export
 * @interface InvestmentDetailDto
 */
export interface InvestmentDetailDto {
  /**
   *
   * @type {Currency}
   * @memberof InvestmentDetailDto
   */
  currency?: Currency;
  /**
   *
   * @type {number}
   * @memberof InvestmentDetailDto
   */
  investmentId: number;
  /**
   *
   * @type {string}
   * @memberof InvestmentDetailDto
   */
  investmentName: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentDetailDto
   */
  status: InvestmentDetailDtoStatusEnum;
  /**
   *
   * @type {number}
   * @memberof InvestmentDetailDto
   */
  appliedAmountPledged: number;
  /**
   *
   * @type {number}
   * @memberof InvestmentDetailDto
   */
  confirmedAmountPledged: number;
  /**
   *
   * @type {number}
   * @memberof InvestmentDetailDto
   */
  ownedAmount: number;
  /**
   *
   * @type {number}
   * @memberof InvestmentDetailDto
   */
  appliedUnits: number;
  /**
   *
   * @type {number}
   * @memberof InvestmentDetailDto
   */
  confirmedUnits: number;
  /**
   *
   * @type {number}
   * @memberof InvestmentDetailDto
   */
  ownedUnits: number;
  /**
   *
   * @type {string}
   * @memberof InvestmentDetailDto
   */
  thumbnailUrl: string;
  /**
   *
   * @type {OpportunityDetailDto}
   * @memberof InvestmentDetailDto
   */
  opportunity: OpportunityDetailDto;
  /**
   *
   * @type {Array<GetDomainInvestorTransactionResponseDto>}
   * @memberof InvestmentDetailDto
   */
  paymentHistory: Array<GetDomainInvestorTransactionResponseDto>;
  /**
   *
   * @type {Array<PayoutDto>}
   * @memberof InvestmentDetailDto
   */
  dividendHistory: Array<PayoutDto>;
  /**
   *
   * @type {string}
   * @memberof InvestmentDetailDto
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof InvestmentDetailDto
   */
  roundId: number;
  /**
   *
   * @type {RoundDto}
   * @memberof InvestmentDetailDto
   */
  round: RoundDto;
}

/**
 * @export
 * @enum {string}
 */
export enum InvestmentDetailDtoStatusEnum {
  Requested = 'requested',
  Confirming = 'confirming',
  Confirmed = 'confirmed',
  Processing = 'processing',
  Completed = 'completed',
  Canceled = 'canceled',
  Rejected = 'rejected',
  OperationEnded = 'operation-ended',
  Redeemed = 'redeemed',
  Failed = 'failed',
  TransferredOrRedeemed = 'transferred-or-redeemed',
}

/**
 *
 * @export
 * @interface InvestmentExperienceTypes
 */
export interface InvestmentExperienceTypes {
  /**
   *
   * @type {string}
   * @memberof InvestmentExperienceTypes
   */
  stock?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperienceTypes
   */
  publicBond?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperienceTypes
   */
  investmentTrust?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperienceTypes
   */
  futures?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperienceTypes
   */
  foreignCurrencyDeposits?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperienceTypes
   */
  forexMarginTrading?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperienceTypes
   */
  marginTrading?: string;
}
/**
 *
 * @export
 * @interface InvestmentExperiences
 */
export interface InvestmentExperiences {
  /**
   *
   * @type {string}
   * @memberof InvestmentExperiences
   */
  stock?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperiences
   */
  publicBond?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperiences
   */
  investmentTrust?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperiences
   */
  futures?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperiences
   */
  foreignCurrencyDeposits?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperiences
   */
  forexMarginTrading?: string;
  /**
   *
   * @type {string}
   * @memberof InvestmentExperiences
   */
  marginTrading?: string;
}
/**
 *
 * @export
 * @interface KycResultBodyDto
 */
export interface KycResultBodyDto {
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  enterpriseId: string;
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  apiAuthKey: string;
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  applyNo: string;
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  ekycUserId: string;
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  ipAddress: string;
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  userAgent: string;
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  ekycStartDate: string;
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  ekycEndDate: string;
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  docType: KycResultBodyDtoDocTypeEnum;
  /**
   *
   * @type {string}
   * @memberof KycResultBodyDto
   */
  identificationType: KycResultBodyDtoIdentificationTypeEnum;
  /**
   *
   * @type {MatchingResult}
   * @memberof KycResultBodyDto
   */
  matchingResult: MatchingResult;
  /**
   *
   * @type {ImageResult}
   * @memberof KycResultBodyDto
   */
  imageResult: ImageResult;
}

/**
 * @export
 * @enum {string}
 */
export enum KycResultBodyDtoDocTypeEnum {
  _01 = '01',
  _02 = '02',
  _03 = '03',
  _04 = '04',
  _05 = '05',
  _06 = '06',
  _07 = '07',
  _08 = '08',
}
/**
 * @export
 * @enum {string}
 */
export enum KycResultBodyDtoIdentificationTypeEnum {
  _01 = '01',
  _02 = '02',
}

/**
 *
 * @export
 * @interface KycResultResponseDto
 */
export interface KycResultResponseDto {
  /**
   *
   * @type {string}
   * @memberof KycResultResponseDto
   */
  result: KycResultResponseDtoResultEnum;
  /**
   *
   * @type {string}
   * @memberof KycResultResponseDto
   */
  errorCode?: KycResultResponseDtoErrorCodeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum KycResultResponseDtoResultEnum {
  Success = 'Success',
  Error = 'Error',
}
/**
 * @export
 * @enum {string}
 */
export enum KycResultResponseDtoErrorCodeEnum {
  BadRequest = 'BadRequest',
  InternalError = 'InternalError',
}

/**
 *
 * @export
 * @interface LoginProviderConfig
 */
export interface LoginProviderConfig {
  /**
   *
   * @type {string}
   * @memberof LoginProviderConfig
   */
  type: LoginProviderConfigTypeEnum;
  /**
   *
   * @type {string}
   * @memberof LoginProviderConfig
   */
  ssoProvider?: LoginProviderConfigSsoProviderEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum LoginProviderConfigTypeEnum {
  EmailPassword = 'email-password',
  Sso = 'sso',
}
/**
 * @export
 * @enum {string}
 */
export enum LoginProviderConfigSsoProviderEnum {
  Sony = 'sony',
}

/**
 *
 * @export
 * @interface MappedDomainInvestorGeneralProfile
 */
export interface MappedDomainInvestorGeneralProfile {
  /**
   *
   * @type {Gender}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  gender?: Gender;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  birthdate?: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  investorType: MappedDomainInvestorGeneralProfileInvestorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  countryCode: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  zipCode?: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  additionalInfo?: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorGeneralProfile
   */
  language: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MappedDomainInvestorGeneralProfileInvestorTypeEnum {
  Individual = 'individual',
  Entity = 'entity',
  CustodianWalletManager = 'custodian-wallet-manager',
  FboIndividual = 'fbo-individual',
  FboEntity = 'fbo-entity',
  Omnibus = 'omnibus',
}

/**
 *
 * @export
 * @interface MappedDomainInvestorResponseDto
 */
export interface MappedDomainInvestorResponseDto {
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorResponseDto
   */
  externalId: string;
  /**
   *
   * @type {MappedDomainInvestorGeneralProfile}
   * @memberof MappedDomainInvestorResponseDto
   */
  generalProfile: MappedDomainInvestorGeneralProfile;
  /**
   *
   * @type {AdditionalProfile}
   * @memberof MappedDomainInvestorResponseDto
   */
  additionalProfile: AdditionalProfile;
  /**
   *
   * @type {Bank}
   * @memberof MappedDomainInvestorResponseDto
   */
  bank: Bank;
  /**
   *
   * @type {Compatibility}
   * @memberof MappedDomainInvestorResponseDto
   */
  compatibility: Compatibility;
  /**
   *
   * @type {object}
   * @memberof MappedDomainInvestorResponseDto
   */
  otherItems: object;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorResponseDto
   */
  jsonOpen: string;
  /**
   *
   * @type {string}
   * @memberof MappedDomainInvestorResponseDto
   */
  jsonPrivate: string;
  /**
   *
   * @type {AdditionalStatuses}
   * @memberof MappedDomainInvestorResponseDto
   */
  additionalStatuses?: AdditionalStatuses;
  /**
   *
   * @type {RegistrationStatuses}
   * @memberof MappedDomainInvestorResponseDto
   */
  registrationStatuses?: RegistrationStatuses;
  /**
   *
   * @type {UpdateRequests}
   * @memberof MappedDomainInvestorResponseDto
   */
  updateRequests?: UpdateRequests;
}
/**
 *
 * @export
 * @interface MatchingResult
 */
export interface MatchingResult {
  /**
   *
   * @type {string}
   * @memberof MatchingResult
   */
  matchingCount: string;
  /**
   *
   * @type {string}
   * @memberof MatchingResult
   */
  docFrontMatch: MatchingResultDocFrontMatchEnum;
  /**
   *
   * @type {string}
   * @memberof MatchingResult
   */
  docTiltedMatch: MatchingResultDocTiltedMatchEnum;
  /**
   *
   * @type {string}
   * @memberof MatchingResult
   */
  docLiveMatch: MatchingResultDocLiveMatchEnum;
  /**
   *
   * @type {string}
   * @memberof MatchingResult
   */
  docFrontScore: string;
  /**
   *
   * @type {string}
   * @memberof MatchingResult
   */
  docTiltedScore: string;
  /**
   *
   * @type {string}
   * @memberof MatchingResult
   */
  docLiveScore: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MatchingResultDocFrontMatchEnum {
  Match = 'MATCH',
  Unmatch = 'UNMATCH',
}
/**
 * @export
 * @enum {string}
 */
export enum MatchingResultDocTiltedMatchEnum {
  Match = 'MATCH',
  Unmatch = 'UNMATCH',
}
/**
 * @export
 * @enum {string}
 */
export enum MatchingResultDocLiveMatchEnum {
  Match = 'MATCH',
  Unmatch = 'UNMATCH',
}

/**
 *
 * @export
 * @interface NationalitySetting
 */
export interface NationalitySetting {
  /**
   *
   * @type {Array<string>}
   * @memberof NationalitySetting
   */
  whitelist: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof NationalitySetting
   */
  blacklist: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof NationalitySetting
   */
  orderList?: Array<string>;
}
/**
 *
 * @export
 * @interface NetworkInfo
 */
export interface NetworkInfo {
  /**
   *
   * @type {string}
   * @memberof NetworkInfo
   */
  tokenId: string;
  /**
   *
   * @type {string}
   * @memberof NetworkInfo
   */
  tokenAddress: string;
  /**
   *
   * @type {string}
   * @memberof NetworkInfo
   */
  deploymentId: string;
  /**
   *
   * @type {string}
   * @memberof NetworkInfo
   */
  network: string;
}
/**
 *
 * @export
 * @interface NetworkInfoDto
 */
export interface NetworkInfoDto {
  /**
   *
   * @type {string}
   * @memberof NetworkInfoDto
   */
  tokenId: string;
  /**
   *
   * @type {string}
   * @memberof NetworkInfoDto
   */
  tokenAddress: string;
  /**
   *
   * @type {string}
   * @memberof NetworkInfoDto
   */
  deploymentId: string;
  /**
   *
   * @type {string}
   * @memberof NetworkInfoDto
   */
  network: string;
}
/**
 *
 * @export
 * @interface OperatorConfirmationStatus
 */
export interface OperatorConfirmationStatus {
  /**
   *
   * @type {string}
   * @memberof OperatorConfirmationStatus
   */
  status: OperatorConfirmationStatusStatusEnum;
  /**
   *
   * @type {string}
   * @memberof OperatorConfirmationStatus
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorConfirmationStatus
   */
  memo?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorConfirmationStatus
   */
  approverExternalId?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorConfirmationStatus
   */
  approverName?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorConfirmationStatus
   */
  approvedAt?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OperatorConfirmationStatusStatusEnum {
  None = 'none',
  Processing = 'processing',
  Verified = 'verified',
  Rejected = 'rejected',
  Reverted = 'reverted',
}

/**
 *
 * @export
 * @interface OpportunityAgreementItem
 */
export interface OpportunityAgreementItem {
  /**
   *
   * @type {string}
   * @memberof OpportunityAgreementItem
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityAgreementItem
   */
  value?: string;
}
/**
 *
 * @export
 * @interface OpportunityDetailDto
 */
export interface OpportunityDetailDto {
  /**
   *
   * @type {number}
   * @memberof OpportunityDetailDto
   */
  opportunityId: number;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  content: string;
  /**
   *
   * @type {boolean}
   * @memberof OpportunityDetailDto
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  image: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  domainId: string;
  /**
   *
   * @type {number}
   * @memberof OpportunityDetailDto
   */
  order: number;
  /**
   *
   * @type {number}
   * @memberof OpportunityDetailDto
   */
  roundId: number;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  tokenId: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  updatedAt: string;
  /**
   *
   * @type {Array<Attribute>}
   * @memberof OpportunityDetailDto
   */
  attributes: Array<Attribute>;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  roundStatus: OpportunityDetailDtoRoundStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof OpportunityDetailDto
   */
  isTokenCalculatorVisible: boolean;
  /**
   *
   * @type {Calculator}
   * @memberof OpportunityDetailDto
   */
  calculator: Calculator;
  /**
   *
   * @type {RoundAdditionalInfo}
   * @memberof OpportunityDetailDto
   */
  roundAdditionalInfo: RoundAdditionalInfo;
  /**
   *
   * @type {number}
   * @memberof OpportunityDetailDto
   */
  amountPledged: number;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  subscriptionAgreementDocusignId: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDetailDto
   */
  tokenName: string;
  /**
   *
   * @type {Array<Content>}
   * @memberof OpportunityDetailDto
   */
  contents: Array<Content>;
  /**
   *
   * @type {Array<OpportunityDocumentDto>}
   * @memberof OpportunityDetailDto
   */
  documents: Array<OpportunityDocumentDto>;
  /**
   *
   * @type {boolean}
   * @memberof OpportunityDetailDto
   */
  isBrokerDealer: boolean;
  /**
   *
   * @type {RoundDto}
   * @memberof OpportunityDetailDto
   */
  round: RoundDto;
}

/**
 * @export
 * @enum {string}
 */
export enum OpportunityDetailDtoRoundStatusEnum {
  NotOpened = 'not-opened',
  Opened = 'opened',
  Completed = 'completed',
  Operating = 'operating',
  OperationEnded = 'operation-ended',
  Redeemed = 'redeemed',
  Failed = 'failed',
}

/**
 *
 * @export
 * @interface OpportunityDocumentDto
 */
export interface OpportunityDocumentDto {
  /**
   *
   * @type {number}
   * @memberof OpportunityDocumentDto
   */
  documentId: number;
  /**
   *
   * @type {number}
   * @memberof OpportunityDocumentDto
   */
  opportunityId: number;
  /**
   *
   * @type {string}
   * @memberof OpportunityDocumentDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDocumentDto
   */
  fileType: string;
  /**
   *
   * @type {number}
   * @memberof OpportunityDocumentDto
   */
  order: number;
  /**
   *
   * @type {boolean}
   * @memberof OpportunityDocumentDto
   */
  requireKyc: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OpportunityDocumentDto
   */
  requireQualification: boolean;
  /**
   *
   * @type {string}
   * @memberof OpportunityDocumentDto
   */
  documentUrl: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDocumentDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDocumentDto
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface OpportunityDto
 */
export interface OpportunityDto {
  /**
   *
   * @type {number}
   * @memberof OpportunityDto
   */
  opportunityId: number;
  /**
   *
   * @type {string}
   * @memberof OpportunityDto
   */
  content: string;
  /**
   *
   * @type {boolean}
   * @memberof OpportunityDto
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof OpportunityDto
   */
  image: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDto
   */
  domainId: string;
  /**
   *
   * @type {number}
   * @memberof OpportunityDto
   */
  order: number;
  /**
   *
   * @type {number}
   * @memberof OpportunityDto
   */
  roundId: number;
  /**
   *
   * @type {string}
   * @memberof OpportunityDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDto
   */
  tokenId: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityDto
   */
  updatedAt: string;
  /**
   *
   * @type {Array<Attribute>}
   * @memberof OpportunityDto
   */
  attributes: Array<Attribute>;
  /**
   *
   * @type {string}
   * @memberof OpportunityDto
   */
  roundStatus: OpportunityDtoRoundStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof OpportunityDto
   */
  isTokenCalculatorVisible: boolean;
  /**
   *
   * @type {Calculator}
   * @memberof OpportunityDto
   */
  calculator: Calculator;
  /**
   *
   * @type {RoundAdditionalInfo}
   * @memberof OpportunityDto
   */
  roundAdditionalInfo: RoundAdditionalInfo;
  /**
   *
   * @type {number}
   * @memberof OpportunityDto
   */
  amountPledged: number;
}

/**
 * @export
 * @enum {string}
 */
export enum OpportunityDtoRoundStatusEnum {
  NotOpened = 'not-opened',
  Opened = 'opened',
  Completed = 'completed',
  Operating = 'operating',
  OperationEnded = 'operation-ended',
  Redeemed = 'redeemed',
  Failed = 'failed',
}

/**
 *
 * @export
 * @interface OpportunityListItemDto
 */
export interface OpportunityListItemDto {
  /**
   *
   * @type {string}
   * @memberof OpportunityListItemDto
   */
  tokenId: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityListItemDto
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof OpportunityListItemDto
   */
  opportunityId: number;
}
/**
 *
 * @export
 * @interface OtherItem
 */
export interface OtherItem {
  /**
   *
   * @type {string}
   * @memberof OtherItem
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof OtherItem
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof OtherItem
   */
  type: OtherItemTypeEnum;
  /**
   *
   * @type {Array<DefaultValues>}
   * @memberof OtherItem
   */
  defaultValues?: Array<DefaultValues>;
  /**
   *
   * @type {boolean}
   * @memberof OtherItem
   */
  visibleOnRegistration?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OtherItem
   */
  visibleOnProfile?: boolean;
  /**
   *
   * @type {ValidationOptions}
   * @memberof OtherItem
   */
  validationOptions?: ValidationOptions;
}

/**
 * @export
 * @enum {string}
 */
export enum OtherItemTypeEnum {
  Input = 'input',
  Select = 'select',
  Checkbox = 'checkbox',
}

/**
 *
 * @export
 * @interface PartialConfirm
 */
export interface PartialConfirm {
  /**
   *
   * @type {boolean}
   * @memberof PartialConfirm
   */
  bank?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartialConfirm
   */
  compatibility?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartialConfirm
   */
  kyc?: boolean;
}
/**
 *
 * @export
 * @interface PayoutDto
 */
export interface PayoutDto {
  /**
   *
   * @type {string}
   * @memberof PayoutDto
   */
  type: PayoutDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PayoutDto
   */
  payoutAt: string;
  /**
   *
   * @type {number}
   * @memberof PayoutDto
   */
  tokenUnit: number;
  /**
   *
   * @type {number}
   * @memberof PayoutDto
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof PayoutDto
   */
  method: PayoutDtoMethodEnum;
  /**
   *
   * @type {string}
   * @memberof PayoutDto
   */
  memo: string;
  /**
   *
   * @type {Distribution}
   * @memberof PayoutDto
   */
  distribution: Distribution;
}

/**
 * @export
 * @enum {string}
 */
export enum PayoutDtoTypeEnum {
  Redemption = 'redemption',
  Interest = 'interest',
  Dividend = 'dividend',
}
/**
 * @export
 * @enum {string}
 */
export enum PayoutDtoMethodEnum {
  Currency = 'currency',
  Point = 'point',
}

/**
 *
 * @export
 * @interface ProviderInfo
 */
export interface ProviderInfo {
  /**
   *
   * @type {string}
   * @memberof ProviderInfo
   */
  rpc: string;
  /**
   *
   * @type {number}
   * @memberof ProviderInfo
   */
  chainId: number;
  /**
   *
   * @type {number}
   * @memberof ProviderInfo
   */
  networkId: number;
}
/**
 *
 * @export
 * @interface ProviderInfoDto
 */
export interface ProviderInfoDto {
  /**
   *
   * @type {string}
   * @memberof ProviderInfoDto
   */
  rpc: string;
  /**
   *
   * @type {number}
   * @memberof ProviderInfoDto
   */
  chainId: number;
  /**
   *
   * @type {number}
   * @memberof ProviderInfoDto
   */
  networkId: number;
}
/**
 *
 * @export
 * @interface ProviderInfoResponse
 */
export interface ProviderInfoResponse {
  /**
   *
   * @type {NetworkInfo}
   * @memberof ProviderInfoResponse
   */
  network: NetworkInfo;
  /**
   *
   * @type {ProviderInfo}
   * @memberof ProviderInfoResponse
   */
  provider: ProviderInfo;
}
/**
 *
 * @export
 * @interface QuestionDto
 */
export interface QuestionDto {
  /**
   *
   * @type {string}
   * @memberof QuestionDto
   */
  questionId: string;
  /**
   *
   * @type {string}
   * @memberof QuestionDto
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof QuestionDto
   */
  answer: string;
  /**
   *
   * @type {string}
   * @memberof QuestionDto
   */
  section: string;
  /**
   *
   * @type {number}
   * @memberof QuestionDto
   */
  order: number;
  /**
   *
   * @type {boolean}
   * @memberof QuestionDto
   */
  isVisible: boolean;
}
/**
 *
 * @export
 * @interface ReadOpportunityDocument
 */
export interface ReadOpportunityDocument {
  /**
   *
   * @type {number}
   * @memberof ReadOpportunityDocument
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ReadOpportunityDocument
   */
  investorExternalId: string;
  /**
   *
   * @type {number}
   * @memberof ReadOpportunityDocument
   */
  opportunityDocumentId: number;
  /**
   *
   * @type {string}
   * @memberof ReadOpportunityDocument
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ReadOpportunityDocument
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface RegisteredMailStatus
 */
export interface RegisteredMailStatus {
  /**
   *
   * @type {string}
   * @memberof RegisteredMailStatus
   */
  status: RegisteredMailStatusStatusEnum;
  /**
   *
   * @type {string}
   * @memberof RegisteredMailStatus
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredMailStatus
   */
  memo?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredMailStatus
   */
  approverExternalId?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredMailStatus
   */
  approverName?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredMailStatus
   */
  approvedAt?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredMailStatus
   */
  orderNo?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum RegisteredMailStatusStatusEnum {
  None = 'none',
  Processing = 'processing',
  Verified = 'verified',
  Rejected = 'rejected',
  Reverted = 'reverted',
}

/**
 *
 * @export
 * @interface RegistrationStatuses
 */
export interface RegistrationStatuses {
  /**
   *
   * @type {string}
   * @memberof RegistrationStatuses
   */
  generalRegistrationStatus: RegistrationStatusesGeneralRegistrationStatusEnum;
  /**
   *
   * @type {string}
   * @memberof RegistrationStatuses
   */
  bankRegistrationStatus: RegistrationStatusesBankRegistrationStatusEnum;
  /**
   *
   * @type {string}
   * @memberof RegistrationStatuses
   */
  compatibilityRegistrationStatus: RegistrationStatusesCompatibilityRegistrationStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof RegistrationStatuses
   */
  isReadyForKyc: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RegistrationStatusesGeneralRegistrationStatusEnum {
  Unregistered = 'unregistered',
  Registered = 'registered',
  Processing = 'processing',
  Verified = 'verified',
  Rejected = 'rejected',
  Reverted = 'reverted',
  WaitingKyc = 'waitingKyc',
}
/**
 * @export
 * @enum {string}
 */
export enum RegistrationStatusesBankRegistrationStatusEnum {
  Unregistered = 'unregistered',
  Registered = 'registered',
  Processing = 'processing',
  Verified = 'verified',
  Rejected = 'rejected',
  Reverted = 'reverted',
  WaitingKyc = 'waitingKyc',
}
/**
 * @export
 * @enum {string}
 */
export enum RegistrationStatusesCompatibilityRegistrationStatusEnum {
  Unregistered = 'unregistered',
  Registered = 'registered',
  Processing = 'processing',
  Verified = 'verified',
  Rejected = 'rejected',
  Reverted = 'reverted',
  WaitingKyc = 'waitingKyc',
}

/**
 *
 * @export
 * @interface RequestBankUpdateDto
 */
export interface RequestBankUpdateDto {
  /**
   *
   * @type {string}
   * @memberof RequestBankUpdateDto
   */
  bankCode: string;
  /**
   *
   * @type {string}
   * @memberof RequestBankUpdateDto
   */
  branchCode: string;
  /**
   *
   * @type {string}
   * @memberof RequestBankUpdateDto
   */
  accountNumber: string;
  /**
   *
   * @type {string}
   * @memberof RequestBankUpdateDto
   */
  accountType: string;
  /**
   *
   * @type {string}
   * @memberof RequestBankUpdateDto
   */
  accountName: string;
  /**
   *
   * @type {Array<UploadDocumentDto>}
   * @memberof RequestBankUpdateDto
   */
  documents?: Array<UploadDocumentDto>;
}
/**
 *
 * @export
 * @interface RequestCompatibilityUpdateDto
 */
export interface RequestCompatibilityUpdateDto {
  /**
   *
   * @type {string}
   * @memberof RequestCompatibilityUpdateDto
   */
  incomeSource: string;
  /**
   *
   * @type {string}
   * @memberof RequestCompatibilityUpdateDto
   */
  job: string;
  /**
   *
   * @type {string}
   * @memberof RequestCompatibilityUpdateDto
   */
  annualIncome: string;
  /**
   *
   * @type {string}
   * @memberof RequestCompatibilityUpdateDto
   */
  assetAmount: string;
  /**
   *
   * @type {string}
   * @memberof RequestCompatibilityUpdateDto
   */
  assetType: string;
  /**
   *
   * @type {string}
   * @memberof RequestCompatibilityUpdateDto
   */
  investmentPurpose: string;
  /**
   *
   * @type {string}
   * @memberof RequestCompatibilityUpdateDto
   */
  investmentPolicy: string;
  /**
   *
   * @type {InvestmentExperienceTypes}
   * @memberof RequestCompatibilityUpdateDto
   */
  investmentExperiences: InvestmentExperienceTypes;
  /**
   *
   * @type {string}
   * @memberof RequestCompatibilityUpdateDto
   */
  financialWorkExperience?: string;
  /**
   *
   * @type {string}
   * @memberof RequestCompatibilityUpdateDto
   */
  foreignPoliticallyExposedPerson: string;
}
/**
 *
 * @export
 * @interface RequestProfileUpdateDto
 */
export interface RequestProfileUpdateDto {
  /**
   *
   * @type {Gender}
   * @memberof RequestProfileUpdateDto
   */
  gender: Gender;
  /**
   *
   * @type {string}
   * @memberof RequestProfileUpdateDto
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof RequestProfileUpdateDto
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof RequestProfileUpdateDto
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof RequestProfileUpdateDto
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof RequestProfileUpdateDto
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof RequestProfileUpdateDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof RequestProfileUpdateDto
   */
  additionalInfo?: string;
  /**
   *
   * @type {AdditionalPrivateInfo}
   * @memberof RequestProfileUpdateDto
   */
  additionalPrivateInfo: AdditionalPrivateInfo;
  /**
   *
   * @type {Array<UploadDocumentDto>}
   * @memberof RequestProfileUpdateDto
   */
  documents?: Array<UploadDocumentDto>;
}
/**
 *
 * @export
 * @interface RequestResetPasswordDto
 */
export interface RequestResetPasswordDto {
  /**
   *
   * @type {string}
   * @memberof RequestResetPasswordDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof RequestResetPasswordDto
   */
  recaptchaToken: string;
}
/**
 *
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordDto
   */
  resetPasswordCode: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordDto
   */
  newPassword: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordDto
   */
  tfaToken: string;
}
/**
 *
 * @export
 * @interface ResetPasswordResponseDto
 */
export interface ResetPasswordResponseDto {
  /**
   *
   * @type {SessionTokenResponseDto}
   * @memberof ResetPasswordResponseDto
   */
  sessionToken: SessionTokenResponseDto;
  /**
   *
   * @type {boolean}
   * @memberof ResetPasswordResponseDto
   */
  isTfaEnabled: boolean;
}
/**
 *
 * @export
 * @interface RiskDetect
 */
export interface RiskDetect {
  /**
   *
   * @type {string}
   * @memberof RiskDetect
   */
  detectedAt: string;
  /**
   *
   * @type {string}
   * @memberof RiskDetect
   */
  result: string;
  /**
   *
   * @type {Array<RiskReport>}
   * @memberof RiskDetect
   */
  reports: Array<RiskReport>;
}
/**
 *
 * @export
 * @interface RiskReport
 */
export interface RiskReport {
  /**
   *
   * @type {string}
   * @memberof RiskReport
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RiskReport
   */
  birthday: string;
  /**
   *
   * @type {string}
   * @memberof RiskReport
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof RiskReport
   */
  organizationName: string;
  /**
   *
   * @type {string}
   * @memberof RiskReport
   */
  journalName: string;
  /**
   *
   * @type {string}
   * @memberof RiskReport
   */
  reportedAt: string;
  /**
   *
   * @type {string}
   * @memberof RiskReport
   */
  antisocialGroup: string;
  /**
   *
   * @type {string}
   * @memberof RiskReport
   */
  note: string;
}
/**
 *
 * @export
 * @interface RoundAdditionalInfo
 */
export interface RoundAdditionalInfo {
  /**
   *
   * @type {Currency}
   * @memberof RoundAdditionalInfo
   */
  currency?: Currency;
  /**
   *
   * @type {number}
   * @memberof RoundAdditionalInfo
   */
  minInvestmentUnit?: number;
  /**
   *
   * @type {number}
   * @memberof RoundAdditionalInfo
   */
  maxInvestmentUnit?: number;
  /**
   *
   * @type {number}
   * @memberof RoundAdditionalInfo
   */
  minAmountToBeRaised: number;
  /**
   *
   * @type {number}
   * @memberof RoundAdditionalInfo
   */
  maxAmountToBeRaised: number;
  /**
   *
   * @type {string}
   * @memberof RoundAdditionalInfo
   */
  applicationStartsAt: string;
  /**
   *
   * @type {string}
   * @memberof RoundAdditionalInfo
   */
  applicationEndsAt: string;
  /**
   *
   * @type {string}
   * @memberof RoundAdditionalInfo
   */
  entryEndsAt: string;
  /**
   *
   * @type {string}
   * @memberof RoundAdditionalInfo
   */
  operationStartsAt: string;
  /**
   *
   * @type {string}
   * @memberof RoundAdditionalInfo
   */
  operationEndsAt: string;
  /**
   *
   * @type {number}
   * @memberof RoundAdditionalInfo
   */
  annualInterestRate: number;
  /**
   *
   * @type {string}
   * @memberof RoundAdditionalInfo
   */
  rejectionStatus: RoundAdditionalInfoRejectionStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof RoundAdditionalInfo
   */
  isRedeemed: boolean;
  /**
   *
   * @type {string}
   * @memberof RoundAdditionalInfo
   */
  operationTerm: string;
  /**
   *
   * @type {string}
   * @memberof RoundAdditionalInfo
   */
  redeemsAt: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RoundAdditionalInfo
   */
  investableCountryCodes?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof RoundAdditionalInfo
   */
  investableRiskLevels?: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum RoundAdditionalInfoRejectionStatusEnum {
  Processing = 'processing',
  Success = 'success',
  Failure = 'failure',
}

/**
 *
 * @export
 * @interface RoundDto
 */
export interface RoundDto {
  /**
   *
   * @type {number}
   * @memberof RoundDto
   */
  roundId: number;
  /**
   *
   * @type {string}
   * @memberof RoundDto
   */
  issuerId: string;
  /**
   *
   * @type {string}
   * @memberof RoundDto
   */
  tokenId: string;
  /**
   *
   * @type {string}
   * @memberof RoundDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RoundDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof RoundDto
   */
  updatedAt: string;
  /**
   *
   * @type {TokenCalculatorData}
   * @memberof RoundDto
   */
  tokenCalculatorData: TokenCalculatorData | null;
  /**
   *
   * @type {RoundAdditionalInfo}
   * @memberof RoundDto
   */
  json: RoundAdditionalInfo;
  /**
   *
   * @type {string}
   * @memberof RoundDto
   */
  status: RoundDtoStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RoundDtoStatusEnum {
  NotOpened = 'not-opened',
  Opened = 'opened',
  Completed = 'completed',
  Operating = 'operating',
  OperationEnded = 'operation-ended',
  Redeemed = 'redeemed',
  Failed = 'failed',
}

/**
 *
 * @export
 * @interface SessionTokenResponseDto
 */
export interface SessionTokenResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionTokenResponseDto
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof SessionTokenResponseDto
   */
  refreshToken: string;
  /**
   *
   * @type {number}
   * @memberof SessionTokenResponseDto
   */
  accessTokenExpiredAt: number;
  /**
   *
   * @type {number}
   * @memberof SessionTokenResponseDto
   */
  refreshTokenExpiredAt: number;
}
/**
 *
 * @export
 * @interface SonyLoginAdditionalDataDto
 */
export interface SonyLoginAdditionalDataDto {
  /**
   *
   * @type {string}
   * @memberof SonyLoginAdditionalDataDto
   */
  key: SonyLoginAdditionalDataDtoKeyEnum;
  /**
   *
   * @type {string}
   * @memberof SonyLoginAdditionalDataDto
   */
  value: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SonyLoginAdditionalDataDtoKeyEnum {
  FamilyNmKanji = 'familyNmKanji',
  NmKanji = 'nmKanji',
  FamilyNmKana = 'familyNmKana',
  NmKana = 'nmKana',
  AddrKanji = 'addrKanji',
  EMail = 'eMail',
  ResidencecntryCd = 'residencecntryCd',
  BrthDate = 'brthDate',
  SuitabilityCd = 'suitabilityCd',
}

/**
 *
 * @export
 * @interface SonyLoginDataDto
 */
export interface SonyLoginDataDto {
  /**
   *
   * @type {string}
   * @memberof SonyLoginDataDto
   */
  brchNum: string;
  /**
   *
   * @type {string}
   * @memberof SonyLoginDataDto
   */
  acTyp: string;
  /**
   *
   * @type {string}
   * @memberof SonyLoginDataDto
   */
  acNum: string;
  /**
   *
   * @type {string}
   * @memberof SonyLoginDataDto
   */
  lnkDt: string;
  /**
   *
   * @type {string}
   * @memberof SonyLoginDataDto
   */
  lnkTm: string;
  /**
   *
   * @type {Array<SonyLoginAdditionalDataDto>}
   * @memberof SonyLoginDataDto
   */
  additional: Array<SonyLoginAdditionalDataDto>;
  /**
   *
   * @type {string}
   * @memberof SonyLoginDataDto
   */
  hash: string;
}
/**
 *
 * @export
 * @interface SsoLoginBodyDto
 */
export interface SsoLoginBodyDto {
  /**
   *
   * @type {SonyLoginDataDto}
   * @memberof SsoLoginBodyDto
   */
  data: SonyLoginDataDto;
  /**
   *
   * @type {string}
   * @memberof SsoLoginBodyDto
   */
  provider: SsoLoginBodyDtoProviderEnum;
  /**
   *
   * @type {string}
   * @memberof SsoLoginBodyDto
   */
  redirectUri: string;
  /**
   *
   * @type {string}
   * @memberof SsoLoginBodyDto
   */
  codeChallenge?: string;
  /**
   *
   * @type {string}
   * @memberof SsoLoginBodyDto
   */
  type?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SsoLoginBodyDtoProviderEnum {
  Sony = 'sony',
}

/**
 *
 * @export
 * @interface SubscriptionAgreementAdditionalStatus
 */
export interface SubscriptionAgreementAdditionalStatus {
  /**
   *
   * @type {string}
   * @memberof SubscriptionAgreementAdditionalStatus
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof SubscriptionAgreementAdditionalStatus
   */
  roundId: number;
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionAgreementAdditionalStatus
   */
  isCanceled: boolean;
}
/**
 *
 * @export
 * @interface TokenCalculatorData
 */
export interface TokenCalculatorData {
  /**
   *
   * @type {number}
   * @memberof TokenCalculatorData
   */
  tokenValue: number;
  /**
   *
   * @type {Array<CalculatorDataTiers>}
   * @memberof TokenCalculatorData
   */
  tiers: Array<CalculatorDataTiers>;
}
/**
 *
 * @export
 * @interface TokenDto
 */
export interface TokenDto {
  /**
   *
   * @type {string}
   * @memberof TokenDto
   */
  tokenId: string;
  /**
   *
   * @type {string}
   * @memberof TokenDto
   */
  tokenIconUrl: string;
  /**
   *
   * @type {string}
   * @memberof TokenDto
   */
  image: string;
  /**
   *
   * @type {string}
   * @memberof TokenDto
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof TokenDto
   */
  opportunityId: number;
  /**
   *
   * @type {number}
   * @memberof TokenDto
   */
  tokensHeld: number;
  /**
   *
   * @type {number}
   * @memberof TokenDto
   */
  tokensPending: number;
  /**
   *
   * @type {number}
   * @memberof TokenDto
   */
  tokensLocked: number;
  /**
   *
   * @type {string}
   * @memberof TokenDto
   */
  tokenName: string;
  /**
   *
   * @type {string}
   * @memberof TokenDto
   */
  tokenNetwork: string;
  /**
   *
   * @type {boolean}
   * @memberof TokenDto
   */
  tokenWithdrawInProgress: boolean;
  /**
   *
   * @type {Array<TokenWalletInfoDto>}
   * @memberof TokenDto
   */
  tokenWallets: Array<TokenWalletInfoDto>;
  /**
   *
   * @type {number}
   * @memberof TokenDto
   */
  tokenValue?: number;
}
/**
 *
 * @export
 * @interface TokenInfoDto
 */
export interface TokenInfoDto {
  /**
   *
   * @type {number}
   * @memberof TokenInfoDto
   */
  tokensHeld: number | null;
  /**
   *
   * @type {number}
   * @memberof TokenInfoDto
   */
  tokensTreasury: number | null;
  /**
   *
   * @type {number}
   * @memberof TokenInfoDto
   */
  roundTokensAssigned: number | null;
  /**
   *
   * @type {number}
   * @memberof TokenInfoDto
   */
  bonusTokens: number | null;
  /**
   *
   * @type {number}
   * @memberof TokenInfoDto
   */
  roundBonusTokensAssigned: number | null;
  /**
   *
   * @type {number}
   * @memberof TokenInfoDto
   */
  roundBonusTokens: number | null;
}
/**
 *
 * @export
 * @interface TokenWalletDto
 */
export interface TokenWalletDto {
  /**
   *
   * @type {string}
   * @memberof TokenWalletDto
   */
  source: TokenWalletDtoSourceEnum;
  /**
   *
   * @type {number}
   * @memberof TokenWalletDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TokenWalletDto
   */
  walletName: string;
  /**
   *
   * @type {number}
   * @memberof TokenWalletDto
   */
  tokenWalletId: number;
  /**
   *
   * @type {string}
   * @memberof TokenWalletDto
   */
  tokenId: string;
  /**
   *
   * @type {boolean}
   * @memberof TokenWalletDto
   */
  tokenInvestorPays: boolean;
  /**
   *
   * @type {string}
   * @memberof TokenWalletDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TokenWalletDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof TokenWalletDto
   */
  tokenName: string;
  /**
   *
   * @type {number}
   * @memberof TokenWalletDto
   */
  tokensHeld: number;
  /**
   *
   * @type {number}
   * @memberof TokenWalletDto
   */
  tokensPending: number;
  /**
   *
   * @type {number}
   * @memberof TokenWalletDto
   */
  tokensLocked: number;
}

/**
 * @export
 * @enum {string}
 */
export enum TokenWalletDtoSourceEnum {
  Metamask = 'metamask',
  WalletConnect = 'wallet-connect',
}

/**
 *
 * @export
 * @interface TokenWalletInfoDto
 */
export interface TokenWalletInfoDto {
  /**
   *
   * @type {TokenWalletStatusEnum}
   * @memberof TokenWalletInfoDto
   */
  status: TokenWalletStatusEnum;
  /**
   *
   * @type {TokenWalletRejectedTransactionTypeEnum}
   * @memberof TokenWalletInfoDto
   */
  rejectedType?: TokenWalletRejectedTransactionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TokenWalletInfoDto
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof TokenWalletInfoDto
   */
  isTransactionSent: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TokenWalletRejectedTransactionTypeEnum {
  Whitelist = 'WHITELIST',
  Withdraw = 'WITHDRAW',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TokenWalletStatusEnum {
  Pending = 'pending',
  Syncing = 'syncing',
  Ready = 'ready',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  SyncingInvestorPays = 'syncing-investor-pays',
}

/**
 *
 * @export
 * @interface TokenWalletsResponseDto
 */
export interface TokenWalletsResponseDto {
  /**
   *
   * @type {string}
   * @memberof TokenWalletsResponseDto
   */
  tokenId: string;
  /**
   *
   * @type {Array<TokenWalletDto>}
   * @memberof TokenWalletsResponseDto
   */
  tokenWallets: Array<TokenWalletDto>;
}
/**
 *
 * @export
 * @interface TradeOpportunityDto
 */
export interface TradeOpportunityDto {
  /**
   *
   * @type {DomainTokenDto}
   * @memberof TradeOpportunityDto
   */
  token: DomainTokenDto;
  /**
   *
   * @type {RoundDto}
   * @memberof TradeOpportunityDto
   */
  round: RoundDto;
  /**
   *
   * @type {TokenInfoDto}
   * @memberof TradeOpportunityDto
   */
  tokenInfo: TokenInfoDto;
  /**
   *
   * @type {ProviderInfoDto}
   * @memberof TradeOpportunityDto
   */
  providerInfo: ProviderInfoDto;
  /**
   *
   * @type {NetworkInfoDto}
   * @memberof TradeOpportunityDto
   */
  networkInfo: NetworkInfoDto;
  /**
   *
   * @type {number}
   * @memberof TradeOpportunityDto
   */
  opportunityId: number;
  /**
   *
   * @type {string}
   * @memberof TradeOpportunityDto
   */
  domainId: string;
  /**
   *
   * @type {string}
   * @memberof TradeOpportunityDto
   */
  tokenId: string;
  /**
   *
   * @type {string}
   * @memberof TradeOpportunityDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof TradeOpportunityDto
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof TradeOpportunityDto
   */
  image: string;
  /**
   *
   * @type {number}
   * @memberof TradeOpportunityDto
   */
  order: number;
  /**
   *
   * @type {boolean}
   * @memberof TradeOpportunityDto
   */
  shadow: boolean;
  /**
   *
   * @type {string}
   * @memberof TradeOpportunityDto
   */
  signedDate: string;
  /**
   *
   * @type {boolean}
   * @memberof TradeOpportunityDto
   */
  qualifiedOnly: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TradeOpportunityDto
   */
  accreditedOnly: boolean;
  /**
   *
   * @type {string}
   * @memberof TradeOpportunityDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof TradeOpportunityDto
   */
  updatedAt: string;
  /**
   *
   * @type {Array<object>}
   * @memberof TradeOpportunityDto
   */
  fields: Array<object>;
  /**
   *
   * @type {Array<FundraiseOpportunityDocumentDto>}
   * @memberof TradeOpportunityDto
   */
  documents: Array<FundraiseOpportunityDocumentDto>;
}
/**
 *
 * @export
 * @interface UpdateKycStatusDto
 */
export interface UpdateKycStatusDto {
  /**
   *
   * @type {string}
   * @memberof UpdateKycStatusDto
   */
  applyNo: string;
}
/**
 *
 * @export
 * @interface UpdateOtherItemsDto
 */
export interface UpdateOtherItemsDto {
  /**
   *
   * @type {object}
   * @memberof UpdateOtherItemsDto
   */
  otherItems: object;
}
/**
 *
 * @export
 * @interface UpdatePasswordDto
 */
export interface UpdatePasswordDto {
  /**
   *
   * @type {string}
   * @memberof UpdatePasswordDto
   */
  currentPassword: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePasswordDto
   */
  newPassword: string;
}
/**
 *
 * @export
 * @interface UpdateProfileAdditionalProfile
 */
export interface UpdateProfileAdditionalProfile {
  /**
   *
   * @type {string}
   * @memberof UpdateProfileAdditionalProfile
   */
  firstNameKana?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileAdditionalProfile
   */
  lastNameKana?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileAdditionalProfile
   */
  nationality?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileAdditionalProfile
   */
  notificationEmail?: string;
}
/**
 *
 * @export
 * @interface UpdateProfileBank
 */
export interface UpdateProfileBank {
  /**
   *
   * @type {string}
   * @memberof UpdateProfileBank
   */
  bankCode?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileBank
   */
  branchCode?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileBank
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileBank
   */
  accountType?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileBank
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileBank
   */
  eposnetCustomerId?: string;
}
/**
 *
 * @export
 * @interface UpdateProfileCompatibility
 */
export interface UpdateProfileCompatibility {
  /**
   *
   * @type {string}
   * @memberof UpdateProfileCompatibility
   */
  incomeSource?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileCompatibility
   */
  job?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileCompatibility
   */
  annualIncome?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileCompatibility
   */
  assetAmount?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileCompatibility
   */
  assetType?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileCompatibility
   */
  investmentPurpose?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileCompatibility
   */
  investmentPolicy?: string;
  /**
   *
   * @type {InvestmentExperienceTypes}
   * @memberof UpdateProfileCompatibility
   */
  investmentExperiences?: InvestmentExperienceTypes;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileCompatibility
   */
  financialWorkExperience?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProfileCompatibility
   */
  foreignPoliticallyExposedPerson?: string;
}
/**
 *
 * @export
 * @interface UpdateProfileDto
 */
export interface UpdateProfileDto {
  /**
   *
   * @type {GeneralProfile}
   * @memberof UpdateProfileDto
   */
  generalProfile?: GeneralProfile;
  /**
   *
   * @type {UpdateProfileAdditionalProfile}
   * @memberof UpdateProfileDto
   */
  additionalProfile?: UpdateProfileAdditionalProfile;
  /**
   *
   * @type {UpdateProfileBank}
   * @memberof UpdateProfileDto
   */
  bank?: UpdateProfileBank;
  /**
   *
   * @type {UpdateProfileCompatibility}
   * @memberof UpdateProfileDto
   */
  compatibility?: UpdateProfileCompatibility;
  /**
   *
   * @type {object}
   * @memberof UpdateProfileDto
   */
  otherItems?: object;
  /**
   *
   * @type {Array<UploadDocumentDto>}
   * @memberof UpdateProfileDto
   */
  documents?: Array<UploadDocumentDto>;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProfileDto
   */
  isConfirm?: boolean;
  /**
   *
   * @type {PartialConfirm}
   * @memberof UpdateProfileDto
   */
  isPartialConfirm?: PartialConfirm;
}
/**
 *
 * @export
 * @interface UpdateRequest
 */
export interface UpdateRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateRequest
   */
  status: UpdateRequestStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateRequest
   */
  reason?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateRequestStatusEnum {
  Requested = 'requested',
  Verified = 'verified',
  Rejected = 'rejected',
}

/**
 *
 * @export
 * @interface UpdateRequests
 */
export interface UpdateRequests {
  /**
   *
   * @type {UpdateRequest}
   * @memberof UpdateRequests
   */
  profile?: UpdateRequest;
  /**
   *
   * @type {UpdateRequest}
   * @memberof UpdateRequests
   */
  compatibility?: UpdateRequest;
  /**
   *
   * @type {UpdateRequest}
   * @memberof UpdateRequests
   */
  bank?: UpdateRequest;
}
/**
 *
 * @export
 * @interface UpdateWalletBodyDto
 */
export interface UpdateWalletBodyDto {
  /**
   *
   * @type {string}
   * @memberof UpdateWalletBodyDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface UploadDocumentDto
 */
export interface UploadDocumentDto {
  /**
   *
   * @type {DocumentType}
   * @memberof UploadDocumentDto
   */
  type: DocumentType;
  /**
   *
   * @type {DocumentFace}
   * @memberof UploadDocumentDto
   */
  documentFace: DocumentFace;
  /**
   *
   * @type {ContentType}
   * @memberof UploadDocumentDto
   */
  contentType: ContentType;
  /**
   *
   * @type {DocumentTitle}
   * @memberof UploadDocumentDto
   */
  documentTitle?: DocumentTitle;
  /**
   *
   * @type {string}
   * @memberof UploadDocumentDto
   */
  file?: string;
  /**
   *
   * @type {string}
   * @memberof UploadDocumentDto
   */
  fileUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UploadDocumentDto
   */
  tokenId?: string;
  /**
   *
   * @type {boolean}
   * @memberof UploadDocumentDto
   */
  showToInvestor?: boolean;
}
/**
 *
 * @export
 * @interface ValidationOptions
 */
export interface ValidationOptions {
  /**
   *
   * @type {string}
   * @memberof ValidationOptions
   */
  regex: string;
  /**
   *
   * @type {string}
   * @memberof ValidationOptions
   */
  errorMessage?: string;
}
/**
 *
 * @export
 * @interface VariableDto
 */
export interface VariableDto {
  /**
   *
   * @type {BankAccountProviderMode}
   * @memberof VariableDto
   */
  bankAccountProviderMode?: BankAccountProviderMode;
  /**
   *
   * @type {Array<Gender>}
   * @memberof VariableDto
   */
  genderSelectOptions: Array<Gender>;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  mainCurrency: string;
  /**
   *
   * @type {Array<string>}
   * @memberof VariableDto
   */
  availableLanguages: Array<string>;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  footer?: string;
  /**
   *
   * @type {Array<OtherItem>}
   * @memberof VariableDto
   */
  otherItems: Array<OtherItem>;
  /**
   *
   * @type {NationalitySetting}
   * @memberof VariableDto
   */
  nationalitySetting?: NationalitySetting;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  termsOfUse?: string;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  explanationOfElectronicDelivery?: string;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  homeUrl?: string;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  privacyPolicyDetailedUrl?: string;
  /**
   *
   * @type {AgeRestriction}
   * @memberof VariableDto
   */
  ageRestriction?: AgeRestriction;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  dashboardMode: VariableDtoDashboardModeEnum;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  wireTransferDestinationSource?: VariableDtoWireTransferDestinationSourceEnum;
  /**
   *
   * @type {ZendeskConfig}
   * @memberof VariableDto
   */
  zendeskConfig?: ZendeskConfig;
  /**
   *
   * @type {Array<OpportunityAgreementItem>}
   * @memberof VariableDto
   */
  opportunityAgreementItems: Array<OpportunityAgreementItem>;
  /**
   *
   * @type {DashboardDisplayFlags}
   * @memberof VariableDto
   */
  dashboardDisplayFlags: DashboardDisplayFlags;
  /**
   *
   * @type {LoginProviderConfig}
   * @memberof VariableDto
   */
  loginProviderConfig: LoginProviderConfig;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  htmlHeadTagsConfig?: string;
  /**
   *
   * @type {Array<HtmlMetaTagsConfig>}
   * @memberof VariableDto
   */
  htmlMetaTagsConfig?: Array<HtmlMetaTagsConfig>;
  /**
   *
   * @type {string}
   * @memberof VariableDto
   */
  lswapUrl?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum VariableDtoDashboardModeEnum {
  Ftk = 'ftk',
  CbSelfOffering = 'cb-self-offering',
}
/**
 * @export
 * @enum {string}
 */
export enum VariableDtoWireTransferDestinationSourceEnum {
  InvestorBankAccount = 'investor-bank-account',
  IssuerWireTransferInstructions = 'issuer-wire-transfer-instructions',
  VirtualBankAccount = 'virtual-bank-account',
}

/**
 *
 * @export
 * @interface VerificationErrors
 */
export interface VerificationErrors {
  /**
   *
   * @type {string}
   * @memberof VerificationErrors
   */
  error_code: string;
  /**
   *
   * @type {string}
   * @memberof VerificationErrors
   */
  error_message: string;
}
/**
 *
 * @export
 * @interface VerifyEmailDto
 */
export interface VerifyEmailDto {
  /**
   *
   * @type {string}
   * @memberof VerifyEmailDto
   */
  registrationToken: string;
}
/**
 *
 * @export
 * @interface VerifyEmailResponseDto
 */
export interface VerifyEmailResponseDto {
  /**
   *
   * @type {string}
   * @memberof VerifyEmailResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailResponseDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailResponseDto
   */
  createDate: string;
}
/**
 *
 * @export
 * @interface VerifyInvestorTokenDto
 */
export interface VerifyInvestorTokenDto {
  /**
   *
   * @type {string}
   * @memberof VerifyInvestorTokenDto
   */
  tokenId: string;
  /**
   *
   * @type {Array<number>}
   * @memberof VerifyInvestorTokenDto
   */
  documentIds?: Array<number>;
}
/**
 *
 * @export
 * @interface VerifyPasswordDto
 */
export interface VerifyPasswordDto {
  /**
   *
   * @type {string}
   * @memberof VerifyPasswordDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface VerifyResetPasswordCodeDto
 */
export interface VerifyResetPasswordCodeDto {
  /**
   *
   * @type {string}
   * @memberof VerifyResetPasswordCodeDto
   */
  resetPasswordCode: string;
}
/**
 *
 * @export
 * @interface WalletDto
 */
export interface WalletDto {
  /**
   *
   * @type {WalletSourceEnum}
   * @memberof WalletDto
   */
  source: WalletSourceEnum;
  /**
   *
   * @type {number}
   * @memberof WalletDto
   */
  walletId: number;
  /**
   *
   * @type {string}
   * @memberof WalletDto
   */
  externalId: string;
  /**
   *
   * @type {string}
   * @memberof WalletDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof WalletDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof WalletDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof WalletDto
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum WalletSourceEnum {
  Metamask = 'metamask',
  WalletConnect = 'wallet-connect',
}

/**
 *
 * @export
 * @interface WalletWithTokensDto
 */
export interface WalletWithTokensDto {
  /**
   *
   * @type {WalletSourceEnum}
   * @memberof WalletWithTokensDto
   */
  source: WalletSourceEnum;
  /**
   *
   * @type {Array<TokenDto>}
   * @memberof WalletWithTokensDto
   */
  tokens: Array<TokenDto>;
  /**
   *
   * @type {number}
   * @memberof WalletWithTokensDto
   */
  walletId: number;
  /**
   *
   * @type {string}
   * @memberof WalletWithTokensDto
   */
  externalId: string;
  /**
   *
   * @type {string}
   * @memberof WalletWithTokensDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof WalletWithTokensDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof WalletWithTokensDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof WalletWithTokensDto
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface WithdrawTokensBodyDto
 */
export interface WithdrawTokensBodyDto {
  /**
   *
   * @type {string}
   * @memberof WithdrawTokensBodyDto
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof WithdrawTokensBodyDto
   */
  walletId: number;
  /**
   *
   * @type {number}
   * @memberof WithdrawTokensBodyDto
   */
  tokenAmount?: number;
}
/**
 *
 * @export
 * @interface WithdrawTokensInvestorPaysBodyDto
 */
export interface WithdrawTokensInvestorPaysBodyDto {
  /**
   *
   * @type {string}
   * @memberof WithdrawTokensInvestorPaysBodyDto
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof WithdrawTokensInvestorPaysBodyDto
   */
  walletId: number;
  /**
   *
   * @type {number}
   * @memberof WithdrawTokensInvestorPaysBodyDto
   */
  tokenAmount?: number;
  /**
   *
   * @type {string}
   * @memberof WithdrawTokensInvestorPaysBodyDto
   */
  payingWalletAddress: string;
}
/**
 *
 * @export
 * @interface WithdrawTokensInvestorPaysCompleteBody
 */
export interface WithdrawTokensInvestorPaysCompleteBody {
  /**
   *
   * @type {string}
   * @memberof WithdrawTokensInvestorPaysCompleteBody
   */
  tokenId: string;
  /**
   *
   * @type {number}
   * @memberof WithdrawTokensInvestorPaysCompleteBody
   */
  signatureTransactionId: number;
  /**
   *
   * @type {string}
   * @memberof WithdrawTokensInvestorPaysCompleteBody
   */
  signature: string;
}
/**
 *
 * @export
 * @interface WithdrawTokensInvestorPaysResponseDto
 */
export interface WithdrawTokensInvestorPaysResponseDto {
  /**
   *
   * @type {string}
   * @memberof WithdrawTokensInvestorPaysResponseDto
   */
  blockchain: string;
  /**
   *
   * @type {object}
   * @memberof WithdrawTokensInvestorPaysResponseDto
   */
  additionalData: object;
  /**
   *
   * @type {object}
   * @memberof WithdrawTokensInvestorPaysResponseDto
   */
  options: object;
  /**
   *
   * @type {number}
   * @memberof WithdrawTokensInvestorPaysResponseDto
   */
  signatureTransactionId: number;
  /**
   *
   * @type {boolean}
   * @memberof WithdrawTokensInvestorPaysResponseDto
   */
  isInvestorPays: boolean;
  /**
   *
   * @type {number}
   * @memberof WithdrawTokensInvestorPaysResponseDto
   */
  blockchainTransactionId: number;
  /**
   *
   * @type {string}
   * @memberof WithdrawTokensInvestorPaysResponseDto
   */
  unsignedTransaction: string;
}
/**
 *
 * @export
 * @interface ZendeskConfig
 */
export interface ZendeskConfig {
  /**
   *
   * @type {string}
   * @memberof ZendeskConfig
   */
  key: string;
  /**
   *
   * @type {boolean}
   * @memberof ZendeskConfig
   */
  enable: boolean;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateEposnetAccessTokenBodyDto} createEposnetAccessTokenBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eposnetControllerCreateAccessToken: async (
      createEposnetAccessTokenBodyDto: CreateEposnetAccessTokenBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createEposnetAccessTokenBodyDto' is not null or undefined
      assertParamExists(
        'eposnetControllerCreateAccessToken',
        'createEposnetAccessTokenBodyDto',
        createEposnetAccessTokenBodyDto,
      );
      const localVarPath = `/api/v1/oauth/eposnet/access-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEposnetAccessTokenBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eposnetControllerGetUserInfo: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/oauth/eposnet/user-info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerLogin: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/session/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerLogout: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/session/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerRefresh: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/session/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} ssoProvider
     * @param {GetLoginBodyDto} getLoginBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerSso: async (
      ssoProvider: string,
      getLoginBodyDto: GetLoginBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ssoProvider' is not null or undefined
      assertParamExists('sessionControllerSso', 'ssoProvider', ssoProvider);
      // verify required parameter 'getLoginBodyDto' is not null or undefined
      assertParamExists('sessionControllerSso', 'getLoginBodyDto', getLoginBodyDto);
      const localVarPath = `/api/v1/session/sso/{ssoProvider}`.replace(
        `{${'ssoProvider'}}`,
        encodeURIComponent(String(ssoProvider)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getLoginBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SsoLoginBodyDto} ssoLoginBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerSsoRedirectLogin: async (
      ssoLoginBodyDto: SsoLoginBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ssoLoginBodyDto' is not null or undefined
      assertParamExists('sessionControllerSsoRedirectLogin', 'ssoLoginBodyDto', ssoLoginBodyDto);
      const localVarPath = `/api/v1/session/sso`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        ssoLoginBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdatePasswordDto} updatePasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerUpdatePassword: async (
      updatePasswordDto: UpdatePasswordDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePasswordDto' is not null or undefined
      assertParamExists('sessionControllerUpdatePassword', 'updatePasswordDto', updatePasswordDto);
      const localVarPath = `/api/v1/session/password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePasswordDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateEposnetAccessTokenBodyDto} createEposnetAccessTokenBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eposnetControllerCreateAccessToken(
      createEposnetAccessTokenBodyDto: CreateEposnetAccessTokenBodyDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eposnetControllerCreateAccessToken(
        createEposnetAccessTokenBodyDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eposnetControllerGetUserInfo(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEposnetUserInfoResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eposnetControllerGetUserInfo(
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionControllerLogin(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSessionTokenResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sessionControllerLogin(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionControllerLogout(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sessionControllerLogout(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionControllerRefresh(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSessionTokenResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sessionControllerRefresh(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} ssoProvider
     * @param {GetLoginBodyDto} getLoginBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionControllerSso(
      ssoProvider: string,
      getLoginBodyDto: GetLoginBodyDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSessionTokenResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sessionControllerSso(
        ssoProvider,
        getLoginBodyDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SsoLoginBodyDto} ssoLoginBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionControllerSsoRedirectLogin(
      ssoLoginBodyDto: SsoLoginBodyDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sessionControllerSsoRedirectLogin(
        ssoLoginBodyDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UpdatePasswordDto} updatePasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionControllerUpdatePassword(
      updatePasswordDto: UpdatePasswordDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sessionControllerUpdatePassword(
        updatePasswordDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @param {CreateEposnetAccessTokenBodyDto} createEposnetAccessTokenBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eposnetControllerCreateAccessToken(
      createEposnetAccessTokenBodyDto: CreateEposnetAccessTokenBodyDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .eposnetControllerCreateAccessToken(createEposnetAccessTokenBodyDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eposnetControllerGetUserInfo(options?: any): AxiosPromise<GetEposnetUserInfoResponseDto> {
      return localVarFp
        .eposnetControllerGetUserInfo(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerLogin(options?: any): AxiosPromise<CreateSessionTokenResponseDto> {
      return localVarFp.sessionControllerLogin(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerLogout(options?: any): AxiosPromise<void> {
      return localVarFp.sessionControllerLogout(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerRefresh(options?: any): AxiosPromise<CreateSessionTokenResponseDto> {
      return localVarFp.sessionControllerRefresh(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} ssoProvider
     * @param {GetLoginBodyDto} getLoginBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerSso(
      ssoProvider: string,
      getLoginBodyDto: GetLoginBodyDto,
      options?: any,
    ): AxiosPromise<CreateSessionTokenResponseDto> {
      return localVarFp
        .sessionControllerSso(ssoProvider, getLoginBodyDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {SsoLoginBodyDto} ssoLoginBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerSsoRedirectLogin(
      ssoLoginBodyDto: SsoLoginBodyDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .sessionControllerSsoRedirectLogin(ssoLoginBodyDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {UpdatePasswordDto} updatePasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionControllerUpdatePassword(
      updatePasswordDto: UpdatePasswordDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .sessionControllerUpdatePassword(updatePasswordDto, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @param {CreateEposnetAccessTokenBodyDto} createEposnetAccessTokenBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public eposnetControllerCreateAccessToken(
    createEposnetAccessTokenBodyDto: CreateEposnetAccessTokenBodyDto,
    options?: any,
  ) {
    return AuthApiFp(this.configuration)
      .eposnetControllerCreateAccessToken(createEposnetAccessTokenBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public eposnetControllerGetUserInfo(options?: any) {
    return AuthApiFp(this.configuration)
      .eposnetControllerGetUserInfo(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public sessionControllerLogin(options?: any) {
    return AuthApiFp(this.configuration)
      .sessionControllerLogin(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public sessionControllerLogout(options?: any) {
    return AuthApiFp(this.configuration)
      .sessionControllerLogout(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public sessionControllerRefresh(options?: any) {
    return AuthApiFp(this.configuration)
      .sessionControllerRefresh(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} ssoProvider
   * @param {GetLoginBodyDto} getLoginBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public sessionControllerSso(
    ssoProvider: string,
    getLoginBodyDto: GetLoginBodyDto,
    options?: any,
  ) {
    return AuthApiFp(this.configuration)
      .sessionControllerSso(ssoProvider, getLoginBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SsoLoginBodyDto} ssoLoginBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public sessionControllerSsoRedirectLogin(ssoLoginBodyDto: SsoLoginBodyDto, options?: any) {
    return AuthApiFp(this.configuration)
      .sessionControllerSsoRedirectLogin(ssoLoginBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdatePasswordDto} updatePasswordDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public sessionControllerUpdatePassword(updatePasswordDto: UpdatePasswordDto, options?: any) {
    return AuthApiFp(this.configuration)
      .sessionControllerUpdatePassword(updatePasswordDto, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * For Health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradeControllerBuy: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/trade/buy`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradeControllerSell: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/trade/sell`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     * For Health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerHealthCheck(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerHealthCheck(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tradeControllerBuy(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeOpportunityDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tradeControllerBuy(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tradeControllerSell(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeOpportunityDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tradeControllerSell(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     * For Health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerHealthCheck(options?: any): AxiosPromise<void> {
      return localVarFp.appControllerHealthCheck(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradeControllerBuy(options?: any): AxiosPromise<Array<TradeOpportunityDto>> {
      return localVarFp.tradeControllerBuy(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tradeControllerSell(options?: any): AxiosPromise<Array<TradeOpportunityDto>> {
      return localVarFp.tradeControllerSell(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * For Health check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerHealthCheck(options?: any) {
    return DefaultApiFp(this.configuration)
      .appControllerHealthCheck(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tradeControllerBuy(options?: any) {
    return DefaultApiFp(this.configuration)
      .tradeControllerBuy(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tradeControllerSell(options?: any) {
    return DefaultApiFp(this.configuration)
      .tradeControllerSell(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} documentId
     * @param {'opportunity' | 'investor'} type
     * @param {string} [tokenId]
     * @param {number} [opportunityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerGetDocument: async (
      documentId: string,
      type: 'opportunity' | 'investor',
      tokenId?: string,
      opportunityId?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('documentsControllerGetDocument', 'documentId', documentId);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('documentsControllerGetDocument', 'type', type);
      const localVarPath = `/api/v1/documents/{documentId}`.replace(
        `{${'documentId'}}`,
        encodeURIComponent(String(documentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (tokenId !== undefined) {
        localVarQueryParameter['tokenId'] = tokenId;
      }

      if (opportunityId !== undefined) {
        localVarQueryParameter['opportunityId'] = opportunityId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {'opportunity' | 'domain' | 'investor' | 'annual-transaction-reports'} type
     * @param {string} [tokenId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerGetDocuments: async (
      type: 'opportunity' | 'domain' | 'investor' | 'annual-transaction-reports',
      tokenId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('documentsControllerGetDocuments', 'type', type);
      const localVarPath = `/api/v1/documents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (tokenId !== undefined) {
        localVarQueryParameter['tokenId'] = tokenId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerGetOpportunityDocumentsList: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/documents/opportunity-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerUploadElectronicDelivery: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/documents/electronic-delivery`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerUploadTermsOfUse: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/documents/terms-of-use`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [opportunityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readDocumentsControllerGetReadDocuments: async (
      opportunityId?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/read-documents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (opportunityId !== undefined) {
        localVarQueryParameter['opportunityId'] = opportunityId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} documentId
     * @param {'opportunity' | 'investor'} type
     * @param {string} [tokenId]
     * @param {number} [opportunityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentsControllerGetDocument(
      documentId: string,
      type: 'opportunity' | 'investor',
      tokenId?: string,
      opportunityId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetDocument(
        documentId,
        type,
        tokenId,
        opportunityId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {'opportunity' | 'domain' | 'investor' | 'annual-transaction-reports'} type
     * @param {string} [tokenId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentsControllerGetDocuments(
      type: 'opportunity' | 'domain' | 'investor' | 'annual-transaction-reports',
      tokenId?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetDocuments(
        type,
        tokenId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentsControllerGetOpportunityDocumentsList(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpportunityListItemDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.documentsControllerGetOpportunityDocumentsList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentsControllerUploadElectronicDelivery(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.documentsControllerUploadElectronicDelivery(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentsControllerUploadTermsOfUse(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerUploadTermsOfUse(
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [opportunityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readDocumentsControllerGetReadDocuments(
      opportunityId?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReadOpportunityDocument>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readDocumentsControllerGetReadDocuments(
          opportunityId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DocumentsApiFp(configuration);
  return {
    /**
     *
     * @param {string} documentId
     * @param {'opportunity' | 'investor'} type
     * @param {string} [tokenId]
     * @param {number} [opportunityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerGetDocument(
      documentId: string,
      type: 'opportunity' | 'investor',
      tokenId?: string,
      opportunityId?: number,
      options?: any,
    ): AxiosPromise<Document> {
      return localVarFp
        .documentsControllerGetDocument(documentId, type, tokenId, opportunityId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {'opportunity' | 'domain' | 'investor' | 'annual-transaction-reports'} type
     * @param {string} [tokenId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerGetDocuments(
      type: 'opportunity' | 'domain' | 'investor' | 'annual-transaction-reports',
      tokenId?: string,
      options?: any,
    ): AxiosPromise<Array<Document>> {
      return localVarFp
        .documentsControllerGetDocuments(type, tokenId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerGetOpportunityDocumentsList(
      options?: any,
    ): AxiosPromise<Array<OpportunityListItemDto>> {
      return localVarFp
        .documentsControllerGetOpportunityDocumentsList(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerUploadElectronicDelivery(options?: any): AxiosPromise<Array<Document>> {
      return localVarFp
        .documentsControllerUploadElectronicDelivery(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsControllerUploadTermsOfUse(options?: any): AxiosPromise<Array<Document>> {
      return localVarFp
        .documentsControllerUploadTermsOfUse(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [opportunityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readDocumentsControllerGetReadDocuments(
      opportunityId?: number,
      options?: any,
    ): AxiosPromise<Array<ReadOpportunityDocument>> {
      return localVarFp
        .readDocumentsControllerGetReadDocuments(opportunityId, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
  /**
   *
   * @param {string} documentId
   * @param {'opportunity' | 'investor'} type
   * @param {string} [tokenId]
   * @param {number} [opportunityId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentsControllerGetDocument(
    documentId: string,
    type: 'opportunity' | 'investor',
    tokenId?: string,
    opportunityId?: number,
    options?: any,
  ) {
    return DocumentsApiFp(this.configuration)
      .documentsControllerGetDocument(documentId, type, tokenId, opportunityId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {'opportunity' | 'domain' | 'investor' | 'annual-transaction-reports'} type
   * @param {string} [tokenId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentsControllerGetDocuments(
    type: 'opportunity' | 'domain' | 'investor' | 'annual-transaction-reports',
    tokenId?: string,
    options?: any,
  ) {
    return DocumentsApiFp(this.configuration)
      .documentsControllerGetDocuments(type, tokenId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentsControllerGetOpportunityDocumentsList(options?: any) {
    return DocumentsApiFp(this.configuration)
      .documentsControllerGetOpportunityDocumentsList(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentsControllerUploadElectronicDelivery(options?: any) {
    return DocumentsApiFp(this.configuration)
      .documentsControllerUploadElectronicDelivery(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentsControllerUploadTermsOfUse(options?: any) {
    return DocumentsApiFp(this.configuration)
      .documentsControllerUploadTermsOfUse(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [opportunityId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public readDocumentsControllerGetReadDocuments(opportunityId?: number, options?: any) {
    return DocumentsApiFp(this.configuration)
      .readDocumentsControllerGetReadDocuments(opportunityId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * I18nApi - axios parameter creator
 * @export
 */
export const I18nApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    i18nControllerGetLanguageInfo: async (
      language: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'language' is not null or undefined
      assertParamExists('i18nControllerGetLanguageInfo', 'language', language);
      const localVarPath = `/api/v1/i18n/{language}`.replace(
        `{${'language'}}`,
        encodeURIComponent(String(language)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * I18nApi - functional programming interface
 * @export
 */
export const I18nApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = I18nApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async i18nControllerGetLanguageInfo(
      language: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDomainInvestorDocumentResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.i18nControllerGetLanguageInfo(
        language,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * I18nApi - factory interface
 * @export
 */
export const I18nApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = I18nApiFp(configuration);
  return {
    /**
     *
     * @param {string} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    i18nControllerGetLanguageInfo(
      language: string,
      options?: any,
    ): AxiosPromise<GetDomainInvestorDocumentResponseDto> {
      return localVarFp
        .i18nControllerGetLanguageInfo(language, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * I18nApi - object-oriented interface
 * @export
 * @class I18nApi
 * @extends {BaseAPI}
 */
export class I18nApi extends BaseAPI {
  /**
   *
   * @param {string} language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof I18nApi
   */
  public i18nControllerGetLanguageInfo(language: string, options?: any) {
    return I18nApiFp(this.configuration)
      .i18nControllerGetLanguageInfo(language, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InvestmentsApi - axios parameter creator
 * @export
 */
export const InvestmentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateEntryDto} createEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerCreateEntry: async (
      createEntryDto: CreateEntryDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createEntryDto' is not null or undefined
      assertParamExists('investmentsControllerCreateEntry', 'createEntryDto', createEntryDto);
      const localVarPath = `/api/v1/investments/entry`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEntryDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateInvestmentDto} createInvestmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerCreateInvestment: async (
      createInvestmentDto: CreateInvestmentDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createInvestmentDto' is not null or undefined
      assertParamExists(
        'investmentsControllerCreateInvestment',
        'createInvestmentDto',
        createInvestmentDto,
      );
      const localVarPath = `/api/v1/investments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createInvestmentDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreatePreEntryDto} createPreEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerCreatePreEntry: async (
      createPreEntryDto: CreatePreEntryDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPreEntryDto' is not null or undefined
      assertParamExists(
        'investmentsControllerCreatePreEntry',
        'createPreEntryDto',
        createPreEntryDto,
      );
      const localVarPath = `/api/v1/investments/pre-entry`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPreEntryDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} investmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetInvestment: async (
      investmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'investmentId' is not null or undefined
      assertParamExists('investmentsControllerGetInvestment', 'investmentId', investmentId);
      const localVarPath = `/api/v1/investments/{investmentId}`.replace(
        `{${'investmentId'}}`,
        encodeURIComponent(String(investmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetInvestments: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/investments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetMaximumInvestableAmount: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/investments/investable-amount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} tokenId
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetTransferInstructions: async (
      tokenId: string,
      roundId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenId' is not null or undefined
      assertParamExists('investmentsControllerGetTransferInstructions', 'tokenId', tokenId);
      // verify required parameter 'roundId' is not null or undefined
      assertParamExists('investmentsControllerGetTransferInstructions', 'roundId', roundId);
      const localVarPath = `/api/v1/investments/funding-address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (tokenId !== undefined) {
        localVarQueryParameter['tokenId'] = tokenId;
      }

      if (roundId !== undefined) {
        localVarQueryParameter['roundId'] = roundId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} tokenId
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetVirtualBankAccountInstructions: async (
      tokenId: string,
      roundId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenId' is not null or undefined
      assertParamExists(
        'investmentsControllerGetVirtualBankAccountInstructions',
        'tokenId',
        tokenId,
      );
      // verify required parameter 'roundId' is not null or undefined
      assertParamExists(
        'investmentsControllerGetVirtualBankAccountInstructions',
        'roundId',
        roundId,
      );
      const localVarPath = `/api/v1/investments/virtual-bank-account-instructions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (tokenId !== undefined) {
        localVarQueryParameter['tokenId'] = tokenId;
      }

      if (roundId !== undefined) {
        localVarQueryParameter['roundId'] = roundId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} investmentId
     * @param {CreateInvestmentDto} createInvestmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerUpdateInvestment: async (
      investmentId: string,
      createInvestmentDto: CreateInvestmentDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'investmentId' is not null or undefined
      assertParamExists('investmentsControllerUpdateInvestment', 'investmentId', investmentId);
      // verify required parameter 'createInvestmentDto' is not null or undefined
      assertParamExists(
        'investmentsControllerUpdateInvestment',
        'createInvestmentDto',
        createInvestmentDto,
      );
      const localVarPath = `/api/v1/investments/{investmentId}`.replace(
        `{${'investmentId'}}`,
        encodeURIComponent(String(investmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createInvestmentDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} investmentId
     * @param {CreatePreEntryDto} createPreEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerUpdatePreEntry: async (
      investmentId: string,
      createPreEntryDto: CreatePreEntryDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'investmentId' is not null or undefined
      assertParamExists('investmentsControllerUpdatePreEntry', 'investmentId', investmentId);
      // verify required parameter 'createPreEntryDto' is not null or undefined
      assertParamExists(
        'investmentsControllerUpdatePreEntry',
        'createPreEntryDto',
        createPreEntryDto,
      );
      const localVarPath = `/api/v1/investments/pre-entry/{investmentId}`.replace(
        `{${'investmentId'}}`,
        encodeURIComponent(String(investmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPreEntryDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InvestmentsApi - functional programming interface
 * @export
 */
export const InvestmentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InvestmentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateEntryDto} createEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerCreateEntry(
      createEntryDto: CreateEntryDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investmentsControllerCreateEntry(
        createEntryDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateInvestmentDto} createInvestmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerCreateInvestment(
      createInvestmentDto: CreateInvestmentDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investmentsControllerCreateInvestment(
          createInvestmentDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreatePreEntryDto} createPreEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerCreatePreEntry(
      createPreEntryDto: CreatePreEntryDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investmentsControllerCreatePreEntry(
        createPreEntryDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} investmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerGetInvestment(
      investmentId: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentDetailDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investmentsControllerGetInvestment(
        investmentId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerGetInvestments(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentDetailDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investmentsControllerGetInvestments(
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerGetMaximumInvestableAmount(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestableAmountDto>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investmentsControllerGetMaximumInvestableAmount(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} tokenId
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerGetTransferInstructions(
      tokenId: string,
      roundId: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransferInstructionsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investmentsControllerGetTransferInstructions(
          tokenId,
          roundId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} tokenId
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerGetVirtualBankAccountInstructions(
      tokenId: string,
      roundId: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetVirtualBankAccountInstructionsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investmentsControllerGetVirtualBankAccountInstructions(
          tokenId,
          roundId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} investmentId
     * @param {CreateInvestmentDto} createInvestmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerUpdateInvestment(
      investmentId: string,
      createInvestmentDto: CreateInvestmentDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investmentsControllerUpdateInvestment(
          investmentId,
          createInvestmentDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} investmentId
     * @param {CreatePreEntryDto} createPreEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investmentsControllerUpdatePreEntry(
      investmentId: string,
      createPreEntryDto: CreatePreEntryDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investmentsControllerUpdatePreEntry(
        investmentId,
        createPreEntryDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InvestmentsApi - factory interface
 * @export
 */
export const InvestmentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InvestmentsApiFp(configuration);
  return {
    /**
     *
     * @param {CreateEntryDto} createEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerCreateEntry(
      createEntryDto: CreateEntryDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investmentsControllerCreateEntry(createEntryDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CreateInvestmentDto} createInvestmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerCreateInvestment(
      createInvestmentDto: CreateInvestmentDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investmentsControllerCreateInvestment(createInvestmentDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CreatePreEntryDto} createPreEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerCreatePreEntry(
      createPreEntryDto: CreatePreEntryDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investmentsControllerCreatePreEntry(createPreEntryDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} investmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetInvestment(
      investmentId: string,
      options?: any,
    ): AxiosPromise<InvestmentDetailDto> {
      return localVarFp
        .investmentsControllerGetInvestment(investmentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetInvestments(options?: any): AxiosPromise<Array<InvestmentDetailDto>> {
      return localVarFp
        .investmentsControllerGetInvestments(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetMaximumInvestableAmount(
      options?: any,
    ): AxiosPromise<InvestableAmountDto> {
      return localVarFp
        .investmentsControllerGetMaximumInvestableAmount(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} tokenId
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetTransferInstructions(
      tokenId: string,
      roundId: number,
      options?: any,
    ): AxiosPromise<GetTransferInstructionsResponseDto> {
      return localVarFp
        .investmentsControllerGetTransferInstructions(tokenId, roundId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} tokenId
     * @param {number} roundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerGetVirtualBankAccountInstructions(
      tokenId: string,
      roundId: number,
      options?: any,
    ): AxiosPromise<GetVirtualBankAccountInstructionsResponseDto> {
      return localVarFp
        .investmentsControllerGetVirtualBankAccountInstructions(tokenId, roundId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} investmentId
     * @param {CreateInvestmentDto} createInvestmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerUpdateInvestment(
      investmentId: string,
      createInvestmentDto: CreateInvestmentDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investmentsControllerUpdateInvestment(investmentId, createInvestmentDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} investmentId
     * @param {CreatePreEntryDto} createPreEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investmentsControllerUpdatePreEntry(
      investmentId: string,
      createPreEntryDto: CreatePreEntryDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investmentsControllerUpdatePreEntry(investmentId, createPreEntryDto, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * InvestmentsApi - object-oriented interface
 * @export
 * @class InvestmentsApi
 * @extends {BaseAPI}
 */
export class InvestmentsApi extends BaseAPI {
  /**
   *
   * @param {CreateEntryDto} createEntryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerCreateEntry(createEntryDto: CreateEntryDto, options?: any) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerCreateEntry(createEntryDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateInvestmentDto} createInvestmentDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerCreateInvestment(
    createInvestmentDto: CreateInvestmentDto,
    options?: any,
  ) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerCreateInvestment(createInvestmentDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreatePreEntryDto} createPreEntryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerCreatePreEntry(createPreEntryDto: CreatePreEntryDto, options?: any) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerCreatePreEntry(createPreEntryDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} investmentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerGetInvestment(investmentId: string, options?: any) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerGetInvestment(investmentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerGetInvestments(options?: any) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerGetInvestments(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerGetMaximumInvestableAmount(options?: any) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerGetMaximumInvestableAmount(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} tokenId
   * @param {number} roundId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerGetTransferInstructions(
    tokenId: string,
    roundId: number,
    options?: any,
  ) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerGetTransferInstructions(tokenId, roundId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} tokenId
   * @param {number} roundId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerGetVirtualBankAccountInstructions(
    tokenId: string,
    roundId: number,
    options?: any,
  ) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerGetVirtualBankAccountInstructions(tokenId, roundId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} investmentId
   * @param {CreateInvestmentDto} createInvestmentDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerUpdateInvestment(
    investmentId: string,
    createInvestmentDto: CreateInvestmentDto,
    options?: any,
  ) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerUpdateInvestment(investmentId, createInvestmentDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} investmentId
   * @param {CreatePreEntryDto} createPreEntryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestmentsApi
   */
  public investmentsControllerUpdatePreEntry(
    investmentId: string,
    createPreEntryDto: CreatePreEntryDto,
    options?: any,
  ) {
    return InvestmentsApiFp(this.configuration)
      .investmentsControllerUpdatePreEntry(investmentId, createPreEntryDto, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InvestorUpdateRequestsApi - axios parameter creator
 * @export
 */
export const InvestorUpdateRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {RequestBankUpdateDto} requestBankUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorUpdateRequestsControllerRequestUpdateBankAccount: async (
      requestBankUpdateDto: RequestBankUpdateDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestBankUpdateDto' is not null or undefined
      assertParamExists(
        'investorUpdateRequestsControllerRequestUpdateBankAccount',
        'requestBankUpdateDto',
        requestBankUpdateDto,
      );
      const localVarPath = `/api/v1/investor-update-requests/bank`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBankUpdateDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RequestCompatibilityUpdateDto} requestCompatibilityUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorUpdateRequestsControllerRequestUpdateCompatibility: async (
      requestCompatibilityUpdateDto: RequestCompatibilityUpdateDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestCompatibilityUpdateDto' is not null or undefined
      assertParamExists(
        'investorUpdateRequestsControllerRequestUpdateCompatibility',
        'requestCompatibilityUpdateDto',
        requestCompatibilityUpdateDto,
      );
      const localVarPath = `/api/v1/investor-update-requests/compatibility`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestCompatibilityUpdateDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RequestProfileUpdateDto} requestProfileUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorUpdateRequestsControllerRequestUpdateProfile: async (
      requestProfileUpdateDto: RequestProfileUpdateDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestProfileUpdateDto' is not null or undefined
      assertParamExists(
        'investorUpdateRequestsControllerRequestUpdateProfile',
        'requestProfileUpdateDto',
        requestProfileUpdateDto,
      );
      const localVarPath = `/api/v1/investor-update-requests/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestProfileUpdateDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InvestorUpdateRequestsApi - functional programming interface
 * @export
 */
export const InvestorUpdateRequestsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InvestorUpdateRequestsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {RequestBankUpdateDto} requestBankUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorUpdateRequestsControllerRequestUpdateBankAccount(
      requestBankUpdateDto: RequestBankUpdateDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investorUpdateRequestsControllerRequestUpdateBankAccount(
          requestBankUpdateDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RequestCompatibilityUpdateDto} requestCompatibilityUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorUpdateRequestsControllerRequestUpdateCompatibility(
      requestCompatibilityUpdateDto: RequestCompatibilityUpdateDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investorUpdateRequestsControllerRequestUpdateCompatibility(
          requestCompatibilityUpdateDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RequestProfileUpdateDto} requestProfileUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorUpdateRequestsControllerRequestUpdateProfile(
      requestProfileUpdateDto: RequestProfileUpdateDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investorUpdateRequestsControllerRequestUpdateProfile(
          requestProfileUpdateDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InvestorUpdateRequestsApi - factory interface
 * @export
 */
export const InvestorUpdateRequestsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InvestorUpdateRequestsApiFp(configuration);
  return {
    /**
     *
     * @param {RequestBankUpdateDto} requestBankUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorUpdateRequestsControllerRequestUpdateBankAccount(
      requestBankUpdateDto: RequestBankUpdateDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investorUpdateRequestsControllerRequestUpdateBankAccount(requestBankUpdateDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {RequestCompatibilityUpdateDto} requestCompatibilityUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorUpdateRequestsControllerRequestUpdateCompatibility(
      requestCompatibilityUpdateDto: RequestCompatibilityUpdateDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investorUpdateRequestsControllerRequestUpdateCompatibility(
          requestCompatibilityUpdateDto,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {RequestProfileUpdateDto} requestProfileUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorUpdateRequestsControllerRequestUpdateProfile(
      requestProfileUpdateDto: RequestProfileUpdateDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investorUpdateRequestsControllerRequestUpdateProfile(requestProfileUpdateDto, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * InvestorUpdateRequestsApi - object-oriented interface
 * @export
 * @class InvestorUpdateRequestsApi
 * @extends {BaseAPI}
 */
export class InvestorUpdateRequestsApi extends BaseAPI {
  /**
   *
   * @param {RequestBankUpdateDto} requestBankUpdateDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorUpdateRequestsApi
   */
  public investorUpdateRequestsControllerRequestUpdateBankAccount(
    requestBankUpdateDto: RequestBankUpdateDto,
    options?: any,
  ) {
    return InvestorUpdateRequestsApiFp(this.configuration)
      .investorUpdateRequestsControllerRequestUpdateBankAccount(requestBankUpdateDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RequestCompatibilityUpdateDto} requestCompatibilityUpdateDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorUpdateRequestsApi
   */
  public investorUpdateRequestsControllerRequestUpdateCompatibility(
    requestCompatibilityUpdateDto: RequestCompatibilityUpdateDto,
    options?: any,
  ) {
    return InvestorUpdateRequestsApiFp(this.configuration)
      .investorUpdateRequestsControllerRequestUpdateCompatibility(
        requestCompatibilityUpdateDto,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RequestProfileUpdateDto} requestProfileUpdateDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorUpdateRequestsApi
   */
  public investorUpdateRequestsControllerRequestUpdateProfile(
    requestProfileUpdateDto: RequestProfileUpdateDto,
    options?: any,
  ) {
    return InvestorUpdateRequestsApiFp(this.configuration)
      .investorUpdateRequestsControllerRequestUpdateProfile(requestProfileUpdateDto, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InvestorsApi - axios parameter creator
 * @export
 */
export const InvestorsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ActivateInvestorDto} activateInvestorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerActivateInvestor: async (
      activateInvestorDto: ActivateInvestorDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'activateInvestorDto' is not null or undefined
      assertParamExists(
        'investorsControllerActivateInvestor',
        'activateInvestorDto',
        activateInvestorDto,
      );
      const localVarPath = `/api/v1/investors/activate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        activateInvestorDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerCreateKycUrl: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/investors/kyc`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DisableTfaDto} disableTfaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerDisableTfa: async (
      disableTfaDto: DisableTfaDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'disableTfaDto' is not null or undefined
      assertParamExists('investorsControllerDisableTfa', 'disableTfaDto', disableTfaDto);
      const localVarPath = `/api/v1/investors/disable-tfa`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        disableTfaDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EnableTfaDto} enableTfaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerEnableTfa: async (
      enableTfaDto: EnableTfaDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enableTfaDto' is not null or undefined
      assertParamExists('investorsControllerEnableTfa', 'enableTfaDto', enableTfaDto);
      const localVarPath = `/api/v1/investors/enable-tfa`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        enableTfaDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GenerateTfaSeedDto} generateTfaSeedDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerGenerateTfaSeed: async (
      generateTfaSeedDto: GenerateTfaSeedDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'generateTfaSeedDto' is not null or undefined
      assertParamExists(
        'investorsControllerGenerateTfaSeed',
        'generateTfaSeedDto',
        generateTfaSeedDto,
      );
      const localVarPath = `/api/v1/investors/two-factor-seed`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        generateTfaSeedDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} zipCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerGetAddressComplete: async (
      zipCode: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'zipCode' is not null or undefined
      assertParamExists('investorsControllerGetAddressComplete', 'zipCode', zipCode);
      const localVarPath = `/api/v1/investors/address-complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (zipCode !== undefined) {
        localVarQueryParameter['zipCode'] = zipCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerGetInvestor: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/investors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerGetInvestorVerifiedTokens: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/investors/verified-tokens`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateInvestorDto} createInvestorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerRegister: async (
      createInvestorDto: CreateInvestorDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createInvestorDto' is not null or undefined
      assertParamExists('investorsControllerRegister', 'createInvestorDto', createInvestorDto);
      const localVarPath = `/api/v1/investors/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createInvestorDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RequestResetPasswordDto} requestResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerRequestResetPassword: async (
      requestResetPasswordDto: RequestResetPasswordDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestResetPasswordDto' is not null or undefined
      assertParamExists(
        'investorsControllerRequestResetPassword',
        'requestResetPasswordDto',
        requestResetPasswordDto,
      );
      const localVarPath = `/api/v1/investors/request-reset-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestResetPasswordDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResetPasswordDto} resetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerResetPassword: async (
      resetPasswordDto: ResetPasswordDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resetPasswordDto' is not null or undefined
      assertParamExists('investorsControllerResetPassword', 'resetPasswordDto', resetPasswordDto);
      const localVarPath = `/api/v1/investors/reset-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateProfileDto} updateProfileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerUpdateInvestor: async (
      updateProfileDto: UpdateProfileDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateProfileDto' is not null or undefined
      assertParamExists('investorsControllerUpdateInvestor', 'updateProfileDto', updateProfileDto);
      const localVarPath = `/api/v1/investors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProfileDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateOtherItemsDto} updateOtherItemsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerUpdateInvestorOtherItems: async (
      updateOtherItemsDto: UpdateOtherItemsDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateOtherItemsDto' is not null or undefined
      assertParamExists(
        'investorsControllerUpdateInvestorOtherItems',
        'updateOtherItemsDto',
        updateOtherItemsDto,
      );
      const localVarPath = `/api/v1/investors/other-items`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOtherItemsDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateKycStatusDto} updateKycStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerUpdateKycStatus: async (
      updateKycStatusDto: UpdateKycStatusDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateKycStatusDto' is not null or undefined
      assertParamExists(
        'investorsControllerUpdateKycStatus',
        'updateKycStatusDto',
        updateKycStatusDto,
      );
      const localVarPath = `/api/v1/investors/kyc-status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateKycStatusDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VerifyEmailDto} verifyEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerVerifyEmail: async (
      verifyEmailDto: VerifyEmailDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyEmailDto' is not null or undefined
      assertParamExists('investorsControllerVerifyEmail', 'verifyEmailDto', verifyEmailDto);
      const localVarPath = `/api/v1/investors/verify-email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyEmailDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VerifyInvestorTokenDto} verifyInvestorTokenDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerVerifyInvestorToken: async (
      verifyInvestorTokenDto: VerifyInvestorTokenDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyInvestorTokenDto' is not null or undefined
      assertParamExists(
        'investorsControllerVerifyInvestorToken',
        'verifyInvestorTokenDto',
        verifyInvestorTokenDto,
      );
      const localVarPath = `/api/v1/investors/verified-tokens`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyInvestorTokenDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VerifyPasswordDto} verifyPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerVerifyPassword: async (
      verifyPasswordDto: VerifyPasswordDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyPasswordDto' is not null or undefined
      assertParamExists(
        'investorsControllerVerifyPassword',
        'verifyPasswordDto',
        verifyPasswordDto,
      );
      const localVarPath = `/api/v1/investors/verify-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyPasswordDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VerifyResetPasswordCodeDto} verifyResetPasswordCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerVerifyResetPasswordCode: async (
      verifyResetPasswordCodeDto: VerifyResetPasswordCodeDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyResetPasswordCodeDto' is not null or undefined
      assertParamExists(
        'investorsControllerVerifyResetPasswordCode',
        'verifyResetPasswordCodeDto',
        verifyResetPasswordCodeDto,
      );
      const localVarPath = `/api/v1/investors/verify-reset-password-code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyResetPasswordCodeDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InvestorsApi - functional programming interface
 * @export
 */
export const InvestorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InvestorsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ActivateInvestorDto} activateInvestorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerActivateInvestor(
      activateInvestorDto: ActivateInvestorDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerActivateInvestor(
        activateInvestorDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerCreateKycUrl(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateKycUrlResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerCreateKycUrl(
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DisableTfaDto} disableTfaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerDisableTfa(
      disableTfaDto: DisableTfaDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthInfoResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerDisableTfa(
        disableTfaDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EnableTfaDto} enableTfaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerEnableTfa(
      enableTfaDto: EnableTfaDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthInfoResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerEnableTfa(
        enableTfaDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GenerateTfaSeedDto} generateTfaSeedDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerGenerateTfaSeed(
      generateTfaSeedDto: GenerateTfaSeedDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTfaSeedResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerGenerateTfaSeed(
        generateTfaSeedDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} zipCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerGetAddressComplete(
      zipCode: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressCompleteResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investorsControllerGetAddressComplete(zipCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerGetInvestor(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappedDomainInvestorResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerGetInvestor(
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerGetInvestorVerifiedTokens(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investorsControllerGetInvestorVerifiedTokens(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateInvestorDto} createInvestorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerRegister(
      createInvestorDto: CreateInvestorDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerRegister(
        createInvestorDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RequestResetPasswordDto} requestResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerRequestResetPassword(
      requestResetPasswordDto: RequestResetPasswordDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investorsControllerRequestResetPassword(
          requestResetPasswordDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ResetPasswordDto} resetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerResetPassword(
      resetPasswordDto: ResetPasswordDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerResetPassword(
        resetPasswordDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UpdateProfileDto} updateProfileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerUpdateInvestor(
      updateProfileDto: UpdateProfileDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappedDomainInvestorResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerUpdateInvestor(
        updateProfileDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UpdateOtherItemsDto} updateOtherItemsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerUpdateInvestorOtherItems(
      updateOtherItemsDto: UpdateOtherItemsDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappedDomainInvestorResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investorsControllerUpdateInvestorOtherItems(
          updateOtherItemsDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UpdateKycStatusDto} updateKycStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerUpdateKycStatus(
      updateKycStatusDto: UpdateKycStatusDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerUpdateKycStatus(
        updateKycStatusDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {VerifyEmailDto} verifyEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerVerifyEmail(
      verifyEmailDto: VerifyEmailDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyEmailResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerVerifyEmail(
        verifyEmailDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {VerifyInvestorTokenDto} verifyInvestorTokenDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerVerifyInvestorToken(
      verifyInvestorTokenDto: VerifyInvestorTokenDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investorsControllerVerifyInvestorToken(
          verifyInvestorTokenDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {VerifyPasswordDto} verifyPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerVerifyPassword(
      verifyPasswordDto: VerifyPasswordDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.investorsControllerVerifyPassword(
        verifyPasswordDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {VerifyResetPasswordCodeDto} verifyResetPasswordCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async investorsControllerVerifyResetPasswordCode(
      verifyResetPasswordCodeDto: VerifyResetPasswordCodeDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.investorsControllerVerifyResetPasswordCode(
          verifyResetPasswordCodeDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InvestorsApi - factory interface
 * @export
 */
export const InvestorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InvestorsApiFp(configuration);
  return {
    /**
     *
     * @param {ActivateInvestorDto} activateInvestorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerActivateInvestor(
      activateInvestorDto: ActivateInvestorDto,
      options?: any,
    ): AxiosPromise<ActivateResponseDto> {
      return localVarFp
        .investorsControllerActivateInvestor(activateInvestorDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerCreateKycUrl(options?: any): AxiosPromise<CreateKycUrlResponseDto> {
      return localVarFp
        .investorsControllerCreateKycUrl(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {DisableTfaDto} disableTfaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerDisableTfa(
      disableTfaDto: DisableTfaDto,
      options?: any,
    ): AxiosPromise<AuthInfoResponseDto> {
      return localVarFp
        .investorsControllerDisableTfa(disableTfaDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {EnableTfaDto} enableTfaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerEnableTfa(
      enableTfaDto: EnableTfaDto,
      options?: any,
    ): AxiosPromise<AuthInfoResponseDto> {
      return localVarFp
        .investorsControllerEnableTfa(enableTfaDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GenerateTfaSeedDto} generateTfaSeedDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerGenerateTfaSeed(
      generateTfaSeedDto: GenerateTfaSeedDto,
      options?: any,
    ): AxiosPromise<GenerateTfaSeedResponseDto> {
      return localVarFp
        .investorsControllerGenerateTfaSeed(generateTfaSeedDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} zipCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerGetAddressComplete(
      zipCode: string,
      options?: any,
    ): AxiosPromise<AddressCompleteResponseDto> {
      return localVarFp
        .investorsControllerGetAddressComplete(zipCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerGetInvestor(options?: any): AxiosPromise<MappedDomainInvestorResponseDto> {
      return localVarFp
        .investorsControllerGetInvestor(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerGetInvestorVerifiedTokens(options?: any): AxiosPromise<Array<string>> {
      return localVarFp
        .investorsControllerGetInvestorVerifiedTokens(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CreateInvestorDto} createInvestorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerRegister(
      createInvestorDto: CreateInvestorDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investorsControllerRegister(createInvestorDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {RequestResetPasswordDto} requestResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerRequestResetPassword(
      requestResetPasswordDto: RequestResetPasswordDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investorsControllerRequestResetPassword(requestResetPasswordDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ResetPasswordDto} resetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerResetPassword(
      resetPasswordDto: ResetPasswordDto,
      options?: any,
    ): AxiosPromise<ResetPasswordResponseDto> {
      return localVarFp
        .investorsControllerResetPassword(resetPasswordDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateProfileDto} updateProfileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerUpdateInvestor(
      updateProfileDto: UpdateProfileDto,
      options?: any,
    ): AxiosPromise<MappedDomainInvestorResponseDto> {
      return localVarFp
        .investorsControllerUpdateInvestor(updateProfileDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateOtherItemsDto} updateOtherItemsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerUpdateInvestorOtherItems(
      updateOtherItemsDto: UpdateOtherItemsDto,
      options?: any,
    ): AxiosPromise<MappedDomainInvestorResponseDto> {
      return localVarFp
        .investorsControllerUpdateInvestorOtherItems(updateOtherItemsDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateKycStatusDto} updateKycStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerUpdateKycStatus(
      updateKycStatusDto: UpdateKycStatusDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investorsControllerUpdateKycStatus(updateKycStatusDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {VerifyEmailDto} verifyEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerVerifyEmail(
      verifyEmailDto: VerifyEmailDto,
      options?: any,
    ): AxiosPromise<VerifyEmailResponseDto> {
      return localVarFp
        .investorsControllerVerifyEmail(verifyEmailDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {VerifyInvestorTokenDto} verifyInvestorTokenDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerVerifyInvestorToken(
      verifyInvestorTokenDto: VerifyInvestorTokenDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investorsControllerVerifyInvestorToken(verifyInvestorTokenDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {VerifyPasswordDto} verifyPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerVerifyPassword(
      verifyPasswordDto: VerifyPasswordDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investorsControllerVerifyPassword(verifyPasswordDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {VerifyResetPasswordCodeDto} verifyResetPasswordCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    investorsControllerVerifyResetPasswordCode(
      verifyResetPasswordCodeDto: VerifyResetPasswordCodeDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .investorsControllerVerifyResetPasswordCode(verifyResetPasswordCodeDto, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * InvestorsApi - object-oriented interface
 * @export
 * @class InvestorsApi
 * @extends {BaseAPI}
 */
export class InvestorsApi extends BaseAPI {
  /**
   *
   * @param {ActivateInvestorDto} activateInvestorDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerActivateInvestor(
    activateInvestorDto: ActivateInvestorDto,
    options?: any,
  ) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerActivateInvestor(activateInvestorDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerCreateKycUrl(options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerCreateKycUrl(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DisableTfaDto} disableTfaDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerDisableTfa(disableTfaDto: DisableTfaDto, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerDisableTfa(disableTfaDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EnableTfaDto} enableTfaDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerEnableTfa(enableTfaDto: EnableTfaDto, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerEnableTfa(enableTfaDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GenerateTfaSeedDto} generateTfaSeedDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerGenerateTfaSeed(generateTfaSeedDto: GenerateTfaSeedDto, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerGenerateTfaSeed(generateTfaSeedDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} zipCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerGetAddressComplete(zipCode: string, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerGetAddressComplete(zipCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerGetInvestor(options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerGetInvestor(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerGetInvestorVerifiedTokens(options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerGetInvestorVerifiedTokens(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateInvestorDto} createInvestorDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerRegister(createInvestorDto: CreateInvestorDto, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerRegister(createInvestorDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RequestResetPasswordDto} requestResetPasswordDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerRequestResetPassword(
    requestResetPasswordDto: RequestResetPasswordDto,
    options?: any,
  ) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerRequestResetPassword(requestResetPasswordDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ResetPasswordDto} resetPasswordDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerResetPassword(resetPasswordDto: ResetPasswordDto, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerResetPassword(resetPasswordDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateProfileDto} updateProfileDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerUpdateInvestor(updateProfileDto: UpdateProfileDto, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerUpdateInvestor(updateProfileDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateOtherItemsDto} updateOtherItemsDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerUpdateInvestorOtherItems(
    updateOtherItemsDto: UpdateOtherItemsDto,
    options?: any,
  ) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerUpdateInvestorOtherItems(updateOtherItemsDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateKycStatusDto} updateKycStatusDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerUpdateKycStatus(updateKycStatusDto: UpdateKycStatusDto, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerUpdateKycStatus(updateKycStatusDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VerifyEmailDto} verifyEmailDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerVerifyEmail(verifyEmailDto: VerifyEmailDto, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerVerifyEmail(verifyEmailDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VerifyInvestorTokenDto} verifyInvestorTokenDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerVerifyInvestorToken(
    verifyInvestorTokenDto: VerifyInvestorTokenDto,
    options?: any,
  ) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerVerifyInvestorToken(verifyInvestorTokenDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VerifyPasswordDto} verifyPasswordDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerVerifyPassword(verifyPasswordDto: VerifyPasswordDto, options?: any) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerVerifyPassword(verifyPasswordDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VerifyResetPasswordCodeDto} verifyResetPasswordCodeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvestorsApi
   */
  public investorsControllerVerifyResetPasswordCode(
    verifyResetPasswordCodeDto: VerifyResetPasswordCodeDto,
    options?: any,
  ) {
    return InvestorsApiFp(this.configuration)
      .investorsControllerVerifyResetPasswordCode(verifyResetPasswordCodeDto, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OpportunitiesApi - axios parameter creator
 * @export
 */
export const OpportunitiesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [opportunityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunitiesControllerGetById: async (
      opportunityId?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/opportunities/detail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (opportunityId !== undefined) {
        localVarQueryParameter['opportunityId'] = opportunityId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunitiesControllerGetOpportunities: async (
      limit?: number,
      page?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/opportunities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} opportunityId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunitiesControllerGetOpportunityDocument: async (
      opportunityId: number,
      documentId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'opportunityId' is not null or undefined
      assertParamExists(
        'opportunitiesControllerGetOpportunityDocument',
        'opportunityId',
        opportunityId,
      );
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('opportunitiesControllerGetOpportunityDocument', 'documentId', documentId);
      const localVarPath = `/api/v1/opportunities/documents/detail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (opportunityId !== undefined) {
        localVarQueryParameter['opportunityId'] = opportunityId;
      }

      if (documentId !== undefined) {
        localVarQueryParameter['documentId'] = documentId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OpportunitiesApi - functional programming interface
 * @export
 */
export const OpportunitiesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OpportunitiesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [opportunityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunitiesControllerGetById(
      opportunityId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDetailDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.opportunitiesControllerGetById(
        opportunityId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunitiesControllerGetOpportunities(
      limit?: number,
      page?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDomainOpportunitiesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.opportunitiesControllerGetOpportunities(
          limit,
          page,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} opportunityId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunitiesControllerGetOpportunityDocument(
      opportunityId: number,
      documentId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDocumentDto>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.opportunitiesControllerGetOpportunityDocument(
          opportunityId,
          documentId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OpportunitiesApi - factory interface
 * @export
 */
export const OpportunitiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OpportunitiesApiFp(configuration);
  return {
    /**
     *
     * @param {number} [opportunityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunitiesControllerGetById(
      opportunityId?: number,
      options?: any,
    ): AxiosPromise<OpportunityDetailDto> {
      return localVarFp
        .opportunitiesControllerGetById(opportunityId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunitiesControllerGetOpportunities(
      limit?: number,
      page?: number,
      options?: any,
    ): AxiosPromise<GetDomainOpportunitiesResponseDto> {
      return localVarFp
        .opportunitiesControllerGetOpportunities(limit, page, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} opportunityId
     * @param {number} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunitiesControllerGetOpportunityDocument(
      opportunityId: number,
      documentId: number,
      options?: any,
    ): AxiosPromise<OpportunityDocumentDto> {
      return localVarFp
        .opportunitiesControllerGetOpportunityDocument(opportunityId, documentId, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * OpportunitiesApi - object-oriented interface
 * @export
 * @class OpportunitiesApi
 * @extends {BaseAPI}
 */
export class OpportunitiesApi extends BaseAPI {
  /**
   *
   * @param {number} [opportunityId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunitiesApi
   */
  public opportunitiesControllerGetById(opportunityId?: number, options?: any) {
    return OpportunitiesApiFp(this.configuration)
      .opportunitiesControllerGetById(opportunityId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [limit]
   * @param {number} [page]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunitiesApi
   */
  public opportunitiesControllerGetOpportunities(limit?: number, page?: number, options?: any) {
    return OpportunitiesApiFp(this.configuration)
      .opportunitiesControllerGetOpportunities(limit, page, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} opportunityId
   * @param {number} documentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunitiesApi
   */
  public opportunitiesControllerGetOpportunityDocument(
    opportunityId: number,
    documentId: number,
    options?: any,
  ) {
    return OpportunitiesApiFp(this.configuration)
      .opportunitiesControllerGetOpportunityDocument(opportunityId, documentId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ProvidersApi - axios parameter creator
 * @export
 */
export const ProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} tokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    providersControllerGetProviderInfo: async (
      tokenId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenId' is not null or undefined
      assertParamExists('providersControllerGetProviderInfo', 'tokenId', tokenId);
      const localVarPath = `/api/v1/providers/{tokenId}`.replace(
        `{${'tokenId'}}`,
        encodeURIComponent(String(tokenId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProvidersApi - functional programming interface
 * @export
 */
export const ProvidersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProvidersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} tokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async providersControllerGetProviderInfo(
      tokenId: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.providersControllerGetProviderInfo(
        tokenId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ProvidersApi - factory interface
 * @export
 */
export const ProvidersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ProvidersApiFp(configuration);
  return {
    /**
     *
     * @param {string} tokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    providersControllerGetProviderInfo(
      tokenId: string,
      options?: any,
    ): AxiosPromise<ProviderInfoResponse> {
      return localVarFp
        .providersControllerGetProviderInfo(tokenId, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ProvidersApi - object-oriented interface
 * @export
 * @class ProvidersApi
 * @extends {BaseAPI}
 */
export class ProvidersApi extends BaseAPI {
  /**
   *
   * @param {string} tokenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvidersApi
   */
  public providersControllerGetProviderInfo(tokenId: string, options?: any) {
    return ProvidersApiFp(this.configuration)
      .providersControllerGetProviderInfo(tokenId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * QuestionsApi - axios parameter creator
 * @export
 */
export const QuestionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [page]
     * @param {number} [limit]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionsControllerList: async (
      page?: number,
      limit?: number,
      q?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questions/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QuestionsApi - functional programming interface
 * @export
 */
export const QuestionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = QuestionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [page]
     * @param {number} [limit]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionsControllerList(
      page?: number,
      limit?: number,
      q?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDomainQuestionsResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionsControllerList(
        page,
        limit,
        q,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * QuestionsApi - factory interface
 * @export
 */
export const QuestionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = QuestionsApiFp(configuration);
  return {
    /**
     *
     * @param {number} [page]
     * @param {number} [limit]
     * @param {string} [q]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionsControllerList(
      page?: number,
      limit?: number,
      q?: string,
      options?: any,
    ): AxiosPromise<GetDomainQuestionsResponseDto> {
      return localVarFp
        .questionsControllerList(page, limit, q, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * QuestionsApi - object-oriented interface
 * @export
 * @class QuestionsApi
 * @extends {BaseAPI}
 */
export class QuestionsApi extends BaseAPI {
  /**
   *
   * @param {number} [page]
   * @param {number} [limit]
   * @param {string} [q]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionsApi
   */
  public questionsControllerList(page?: number, limit?: number, q?: string, options?: any) {
    return QuestionsApiFp(this.configuration)
      .questionsControllerList(page, limit, q, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingControllerGetSetting: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/domains/setting`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingControllerGetSetting(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDomainSettingResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerGetSetting(
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SettingApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingControllerGetSetting(options?: any): AxiosPromise<GetDomainSettingResponseDto> {
      return localVarFp
        .settingControllerGetSetting(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingApi
   */
  public settingControllerGetSetting(options?: any) {
    return SettingApiFp(this.configuration)
      .settingControllerGetSetting(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TokenWalletsApi - axios parameter creator
 * @export
 */
export const TokenWalletsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AuthorizeBodyDto} authorizeBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerAuthorize: async (
      authorizeBodyDto: AuthorizeBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorizeBodyDto' is not null or undefined
      assertParamExists('tokenWalletsControllerAuthorize', 'authorizeBodyDto', authorizeBodyDto);
      const localVarPath = `/api/v1/token-wallets/authorize`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        authorizeBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuthorizeInvestorPaysBodyDto} authorizeInvestorPaysBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerAuthorizeInvestorPays: async (
      authorizeInvestorPaysBodyDto: AuthorizeInvestorPaysBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorizeInvestorPaysBodyDto' is not null or undefined
      assertParamExists(
        'tokenWalletsControllerAuthorizeInvestorPays',
        'authorizeInvestorPaysBodyDto',
        authorizeInvestorPaysBodyDto,
      );
      const localVarPath = `/api/v1/token-wallets/authorize-investor-pays`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        authorizeInvestorPaysBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuthorizeInvestorPaysCompleteBodyDto} authorizeInvestorPaysCompleteBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerAuthorizeInvestorPaysComplete: async (
      authorizeInvestorPaysCompleteBodyDto: AuthorizeInvestorPaysCompleteBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorizeInvestorPaysCompleteBodyDto' is not null or undefined
      assertParamExists(
        'tokenWalletsControllerAuthorizeInvestorPaysComplete',
        'authorizeInvestorPaysCompleteBodyDto',
        authorizeInvestorPaysCompleteBodyDto,
      );
      const localVarPath = `/api/v1/token-wallets/authorize-investor-pays-complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        authorizeInvestorPaysCompleteBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} tokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerGetTokenWallets: async (
      tokenId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenId' is not null or undefined
      assertParamExists('tokenWalletsControllerGetTokenWallets', 'tokenId', tokenId);
      const localVarPath = `/api/v1/token-wallets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (tokenId !== undefined) {
        localVarQueryParameter['tokenId'] = tokenId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {WithdrawTokensBodyDto} withdrawTokensBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerWithdrawTokens: async (
      withdrawTokensBodyDto: WithdrawTokensBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'withdrawTokensBodyDto' is not null or undefined
      assertParamExists(
        'tokenWalletsControllerWithdrawTokens',
        'withdrawTokensBodyDto',
        withdrawTokensBodyDto,
      );
      const localVarPath = `/api/v1/token-wallets/withdraw-tokens`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        withdrawTokensBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {WithdrawTokensInvestorPaysBodyDto} withdrawTokensInvestorPaysBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerWithdrawTokensInvestorPays: async (
      withdrawTokensInvestorPaysBodyDto: WithdrawTokensInvestorPaysBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'withdrawTokensInvestorPaysBodyDto' is not null or undefined
      assertParamExists(
        'tokenWalletsControllerWithdrawTokensInvestorPays',
        'withdrawTokensInvestorPaysBodyDto',
        withdrawTokensInvestorPaysBodyDto,
      );
      const localVarPath = `/api/v1/token-wallets/withdraw-tokens-investor-pays`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        withdrawTokensInvestorPaysBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {WithdrawTokensInvestorPaysCompleteBody} withdrawTokensInvestorPaysCompleteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerWithdrawTokensInvestorPaysComplete: async (
      withdrawTokensInvestorPaysCompleteBody: WithdrawTokensInvestorPaysCompleteBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'withdrawTokensInvestorPaysCompleteBody' is not null or undefined
      assertParamExists(
        'tokenWalletsControllerWithdrawTokensInvestorPaysComplete',
        'withdrawTokensInvestorPaysCompleteBody',
        withdrawTokensInvestorPaysCompleteBody,
      );
      const localVarPath = `/api/v1/token-wallets/withdraw-tokens-investor-pays-complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        withdrawTokensInvestorPaysCompleteBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TokenWalletsApi - functional programming interface
 * @export
 */
export const TokenWalletsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TokenWalletsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AuthorizeBodyDto} authorizeBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenWalletsControllerAuthorize(
      authorizeBodyDto: AuthorizeBodyDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizeResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tokenWalletsControllerAuthorize(
        authorizeBodyDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuthorizeInvestorPaysBodyDto} authorizeInvestorPaysBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenWalletsControllerAuthorizeInvestorPays(
      authorizeInvestorPaysBodyDto: AuthorizeInvestorPaysBodyDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizeInvestorPaysResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tokenWalletsControllerAuthorizeInvestorPays(
          authorizeInvestorPaysBodyDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuthorizeInvestorPaysCompleteBodyDto} authorizeInvestorPaysCompleteBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenWalletsControllerAuthorizeInvestorPaysComplete(
      authorizeInvestorPaysCompleteBodyDto: AuthorizeInvestorPaysCompleteBodyDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tokenWalletsControllerAuthorizeInvestorPaysComplete(
          authorizeInvestorPaysCompleteBodyDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} tokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenWalletsControllerGetTokenWallets(
      tokenId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWalletsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tokenWalletsControllerGetTokenWallets(tokenId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {WithdrawTokensBodyDto} withdrawTokensBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenWalletsControllerWithdrawTokens(
      withdrawTokensBodyDto: WithdrawTokensBodyDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tokenWalletsControllerWithdrawTokens(
          withdrawTokensBodyDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {WithdrawTokensInvestorPaysBodyDto} withdrawTokensInvestorPaysBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenWalletsControllerWithdrawTokensInvestorPays(
      withdrawTokensInvestorPaysBodyDto: WithdrawTokensInvestorPaysBodyDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WithdrawTokensInvestorPaysResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tokenWalletsControllerWithdrawTokensInvestorPays(
          withdrawTokensInvestorPaysBodyDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {WithdrawTokensInvestorPaysCompleteBody} withdrawTokensInvestorPaysCompleteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenWalletsControllerWithdrawTokensInvestorPaysComplete(
      withdrawTokensInvestorPaysCompleteBody: WithdrawTokensInvestorPaysCompleteBody,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tokenWalletsControllerWithdrawTokensInvestorPaysComplete(
          withdrawTokensInvestorPaysCompleteBody,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TokenWalletsApi - factory interface
 * @export
 */
export const TokenWalletsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TokenWalletsApiFp(configuration);
  return {
    /**
     *
     * @param {AuthorizeBodyDto} authorizeBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerAuthorize(
      authorizeBodyDto: AuthorizeBodyDto,
      options?: any,
    ): AxiosPromise<AuthorizeResponseDto> {
      return localVarFp
        .tokenWalletsControllerAuthorize(authorizeBodyDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {AuthorizeInvestorPaysBodyDto} authorizeInvestorPaysBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerAuthorizeInvestorPays(
      authorizeInvestorPaysBodyDto: AuthorizeInvestorPaysBodyDto,
      options?: any,
    ): AxiosPromise<AuthorizeInvestorPaysResponseDto> {
      return localVarFp
        .tokenWalletsControllerAuthorizeInvestorPays(authorizeInvestorPaysBodyDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {AuthorizeInvestorPaysCompleteBodyDto} authorizeInvestorPaysCompleteBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerAuthorizeInvestorPaysComplete(
      authorizeInvestorPaysCompleteBodyDto: AuthorizeInvestorPaysCompleteBodyDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .tokenWalletsControllerAuthorizeInvestorPaysComplete(
          authorizeInvestorPaysCompleteBodyDto,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} tokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerGetTokenWallets(
      tokenId: string,
      options?: any,
    ): AxiosPromise<TokenWalletsResponseDto> {
      return localVarFp
        .tokenWalletsControllerGetTokenWallets(tokenId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {WithdrawTokensBodyDto} withdrawTokensBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerWithdrawTokens(
      withdrawTokensBodyDto: WithdrawTokensBodyDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .tokenWalletsControllerWithdrawTokens(withdrawTokensBodyDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {WithdrawTokensInvestorPaysBodyDto} withdrawTokensInvestorPaysBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerWithdrawTokensInvestorPays(
      withdrawTokensInvestorPaysBodyDto: WithdrawTokensInvestorPaysBodyDto,
      options?: any,
    ): AxiosPromise<WithdrawTokensInvestorPaysResponseDto> {
      return localVarFp
        .tokenWalletsControllerWithdrawTokensInvestorPays(
          withdrawTokensInvestorPaysBodyDto,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {WithdrawTokensInvestorPaysCompleteBody} withdrawTokensInvestorPaysCompleteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenWalletsControllerWithdrawTokensInvestorPaysComplete(
      withdrawTokensInvestorPaysCompleteBody: WithdrawTokensInvestorPaysCompleteBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .tokenWalletsControllerWithdrawTokensInvestorPaysComplete(
          withdrawTokensInvestorPaysCompleteBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * TokenWalletsApi - object-oriented interface
 * @export
 * @class TokenWalletsApi
 * @extends {BaseAPI}
 */
export class TokenWalletsApi extends BaseAPI {
  /**
   *
   * @param {AuthorizeBodyDto} authorizeBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenWalletsApi
   */
  public tokenWalletsControllerAuthorize(authorizeBodyDto: AuthorizeBodyDto, options?: any) {
    return TokenWalletsApiFp(this.configuration)
      .tokenWalletsControllerAuthorize(authorizeBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthorizeInvestorPaysBodyDto} authorizeInvestorPaysBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenWalletsApi
   */
  public tokenWalletsControllerAuthorizeInvestorPays(
    authorizeInvestorPaysBodyDto: AuthorizeInvestorPaysBodyDto,
    options?: any,
  ) {
    return TokenWalletsApiFp(this.configuration)
      .tokenWalletsControllerAuthorizeInvestorPays(authorizeInvestorPaysBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthorizeInvestorPaysCompleteBodyDto} authorizeInvestorPaysCompleteBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenWalletsApi
   */
  public tokenWalletsControllerAuthorizeInvestorPaysComplete(
    authorizeInvestorPaysCompleteBodyDto: AuthorizeInvestorPaysCompleteBodyDto,
    options?: any,
  ) {
    return TokenWalletsApiFp(this.configuration)
      .tokenWalletsControllerAuthorizeInvestorPaysComplete(
        authorizeInvestorPaysCompleteBodyDto,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} tokenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenWalletsApi
   */
  public tokenWalletsControllerGetTokenWallets(tokenId: string, options?: any) {
    return TokenWalletsApiFp(this.configuration)
      .tokenWalletsControllerGetTokenWallets(tokenId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {WithdrawTokensBodyDto} withdrawTokensBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenWalletsApi
   */
  public tokenWalletsControllerWithdrawTokens(
    withdrawTokensBodyDto: WithdrawTokensBodyDto,
    options?: any,
  ) {
    return TokenWalletsApiFp(this.configuration)
      .tokenWalletsControllerWithdrawTokens(withdrawTokensBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {WithdrawTokensInvestorPaysBodyDto} withdrawTokensInvestorPaysBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenWalletsApi
   */
  public tokenWalletsControllerWithdrawTokensInvestorPays(
    withdrawTokensInvestorPaysBodyDto: WithdrawTokensInvestorPaysBodyDto,
    options?: any,
  ) {
    return TokenWalletsApiFp(this.configuration)
      .tokenWalletsControllerWithdrawTokensInvestorPays(withdrawTokensInvestorPaysBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {WithdrawTokensInvestorPaysCompleteBody} withdrawTokensInvestorPaysCompleteBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenWalletsApi
   */
  public tokenWalletsControllerWithdrawTokensInvestorPaysComplete(
    withdrawTokensInvestorPaysCompleteBody: WithdrawTokensInvestorPaysCompleteBody,
    options?: any,
  ) {
    return TokenWalletsApiFp(this.configuration)
      .tokenWalletsControllerWithdrawTokensInvestorPaysComplete(
        withdrawTokensInvestorPaysCompleteBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * WalletsApi - axios parameter creator
 * @export
 */
export const WalletsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateWalletBodyDto} createWalletBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletsControllerCreateWallet: async (
      createWalletBodyDto: CreateWalletBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createWalletBodyDto' is not null or undefined
      assertParamExists(
        'walletsControllerCreateWallet',
        'createWalletBodyDto',
        createWalletBodyDto,
      );
      const localVarPath = `/api/v1/wallets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWalletBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} walletId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletsControllerDeleteWallet: async (
      walletId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists('walletsControllerDeleteWallet', 'walletId', walletId);
      const localVarPath = `/api/v1/wallets/{walletId}`.replace(
        `{${'walletId'}}`,
        encodeURIComponent(String(walletId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletsControllerGetWallets: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/wallets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} walletId
     * @param {UpdateWalletBodyDto} updateWalletBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletsControllerUpdateWallet: async (
      walletId: number,
      updateWalletBodyDto: UpdateWalletBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists('walletsControllerUpdateWallet', 'walletId', walletId);
      // verify required parameter 'updateWalletBodyDto' is not null or undefined
      assertParamExists(
        'walletsControllerUpdateWallet',
        'updateWalletBodyDto',
        updateWalletBodyDto,
      );
      const localVarPath = `/api/v1/wallets/{walletId}`.replace(
        `{${'walletId'}}`,
        encodeURIComponent(String(walletId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateWalletBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WalletsApi - functional programming interface
 * @export
 */
export const WalletsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WalletsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateWalletBodyDto} createWalletBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async walletsControllerCreateWallet(
      createWalletBodyDto: CreateWalletBodyDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerCreateWallet(
        createWalletBodyDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} walletId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async walletsControllerDeleteWallet(
      walletId: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteWalletResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerDeleteWallet(
        walletId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async walletsControllerGetWallets(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletWithTokensDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerGetWallets(
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} walletId
     * @param {UpdateWalletBodyDto} updateWalletBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async walletsControllerUpdateWallet(
      walletId: number,
      updateWalletBodyDto: UpdateWalletBodyDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerUpdateWallet(
        walletId,
        updateWalletBodyDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WalletsApi - factory interface
 * @export
 */
export const WalletsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WalletsApiFp(configuration);
  return {
    /**
     *
     * @param {CreateWalletBodyDto} createWalletBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletsControllerCreateWallet(
      createWalletBodyDto: CreateWalletBodyDto,
      options?: any,
    ): AxiosPromise<WalletDto> {
      return localVarFp
        .walletsControllerCreateWallet(createWalletBodyDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} walletId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletsControllerDeleteWallet(
      walletId: number,
      options?: any,
    ): AxiosPromise<DeleteWalletResponseDto> {
      return localVarFp
        .walletsControllerDeleteWallet(walletId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletsControllerGetWallets(options?: any): AxiosPromise<Array<WalletWithTokensDto>> {
      return localVarFp
        .walletsControllerGetWallets(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} walletId
     * @param {UpdateWalletBodyDto} updateWalletBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletsControllerUpdateWallet(
      walletId: number,
      updateWalletBodyDto: UpdateWalletBodyDto,
      options?: any,
    ): AxiosPromise<WalletDto> {
      return localVarFp
        .walletsControllerUpdateWallet(walletId, updateWalletBodyDto, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * WalletsApi - object-oriented interface
 * @export
 * @class WalletsApi
 * @extends {BaseAPI}
 */
export class WalletsApi extends BaseAPI {
  /**
   *
   * @param {CreateWalletBodyDto} createWalletBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public walletsControllerCreateWallet(createWalletBodyDto: CreateWalletBodyDto, options?: any) {
    return WalletsApiFp(this.configuration)
      .walletsControllerCreateWallet(createWalletBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} walletId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public walletsControllerDeleteWallet(walletId: number, options?: any) {
    return WalletsApiFp(this.configuration)
      .walletsControllerDeleteWallet(walletId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public walletsControllerGetWallets(options?: any) {
    return WalletsApiFp(this.configuration)
      .walletsControllerGetWallets(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} walletId
   * @param {UpdateWalletBodyDto} updateWalletBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public walletsControllerUpdateWallet(
    walletId: number,
    updateWalletBodyDto: UpdateWalletBodyDto,
    options?: any,
  ) {
    return WalletsApiFp(this.configuration)
      .walletsControllerUpdateWallet(walletId, updateWalletBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {IdentificationResultBodyDto} identificationResultBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nexwayControllerIdentificationResult: async (
      identificationResultBodyDto: IdentificationResultBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'identificationResultBodyDto' is not null or undefined
      assertParamExists(
        'nexwayControllerIdentificationResult',
        'identificationResultBodyDto',
        identificationResultBodyDto,
      );
      const localVarPath = `/api/v1/webhooks/nexway/identification-result`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        identificationResultBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {KycResultBodyDto} kycResultBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nexwayControllerKycResult: async (
      kycResultBodyDto: KycResultBodyDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kycResultBodyDto' is not null or undefined
      assertParamExists('nexwayControllerKycResult', 'kycResultBodyDto', kycResultBodyDto);
      const localVarPath = `/api/v1/webhooks/nexway/kyc-result`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        kycResultBodyDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {IdentificationResultBodyDto} identificationResultBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nexwayControllerIdentificationResult(
      identificationResultBodyDto: IdentificationResultBodyDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentificationResultResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nexwayControllerIdentificationResult(
          identificationResultBodyDto,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {KycResultBodyDto} kycResultBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nexwayControllerKycResult(
      kycResultBodyDto: KycResultBodyDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycResultResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nexwayControllerKycResult(
        kycResultBodyDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WebhooksApiFp(configuration);
  return {
    /**
     *
     * @param {IdentificationResultBodyDto} identificationResultBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nexwayControllerIdentificationResult(
      identificationResultBodyDto: IdentificationResultBodyDto,
      options?: any,
    ): AxiosPromise<IdentificationResultResponseDto> {
      return localVarFp
        .nexwayControllerIdentificationResult(identificationResultBodyDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {KycResultBodyDto} kycResultBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nexwayControllerKycResult(
      kycResultBodyDto: KycResultBodyDto,
      options?: any,
    ): AxiosPromise<KycResultResponseDto> {
      return localVarFp
        .nexwayControllerKycResult(kycResultBodyDto, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
  /**
   *
   * @param {IdentificationResultBodyDto} identificationResultBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public nexwayControllerIdentificationResult(
    identificationResultBodyDto: IdentificationResultBodyDto,
    options?: any,
  ) {
    return WebhooksApiFp(this.configuration)
      .nexwayControllerIdentificationResult(identificationResultBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {KycResultBodyDto} kycResultBodyDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public nexwayControllerKycResult(kycResultBodyDto: KycResultBodyDto, options?: any) {
    return WebhooksApiFp(this.configuration)
      .nexwayControllerKycResult(kycResultBodyDto, options)
      .then(request => request(this.axios, this.basePath));
  }
}
