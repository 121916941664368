import React, { useEffect } from 'react';
import { CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdown } from '@coreui/react';
import { getTranslations } from '@state/i18n/actions';
import { useI18nSelector } from '@state/i18n/hooks';
import './LanguageSelect.scss';
import dayjs from 'dayjs';
import { useSettingSelector } from '@state/setting/hooks';

export interface ILanguageSelectProps {
  displayForced?: boolean;
}

const LanguageSelect = ({ displayForced = false }: ILanguageSelectProps) => {
  const { languages, activeLanguage } = useI18nSelector();
  const { setting } = useSettingSelector();

  useEffect(() => {
    dayjs.locale(activeLanguage.toLocaleLowerCase());
    if (!languages[activeLanguage]) {
      getTranslations(activeLanguage);
    }
  }, [activeLanguage]);

  const availableLanguages = Object.keys(languages).filter(lang =>
    setting?.variables.availableLanguages
      .map(lng => lng.toLowerCase())
      .includes(lang.toLowerCase()),
  );

  return !displayForced && availableLanguages?.length <= 1 ? (
    <></>
  ) : (
    <CDropdown className='LanguageSelectComponent'>
      <CDropdownToggle caret id='language-toggle'>
        {activeLanguage.toUpperCase()}
        <i className='icon-point-down font-20 ml-1' />
      </CDropdownToggle>
      <CDropdownMenu>
        {availableLanguages.map(language => (
          <CDropdownItem
            id={`language-item-${language}`}
            onClick={() => getTranslations(language)}
            key={language}
          >
            {languages[language].label}
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default LanguageSelect;
