import classNames from 'classnames';
import React, { PropsWithChildren, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  CLabel,
  CInput,
  CFormGroup,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
} from '@coreui/react';
import { translate } from '@utils/translate';
import { successToast } from '../../Toast';
import { Translate } from '../../Translate';
import './CopiableInput.scss';

export interface IInputProps extends PropsWithChildren<CInput> {
  label?: string;
}

const CopiableInput = ({ children, className, label, value, ...props }: IInputProps) => {
  const [copied, setCopied] = useState(false);
  const onCopy = () => {
    setCopied(true);
    successToast(translate('Toasts.copiable-input-copied'));
  };

  return (
    <CFormGroup className={classNames('CopiableInput', className)}>
      {label && (
        <CLabel htmlFor={props.htmlFor}>
          <Translate translationKey={label} />
        </CLabel>
      )}
      <CInputGroup>
        <CInput readOnly value={value} {...props} />
        <CInputGroupAppend>
          <CInputGroupText>
            <CopyToClipboard text={value ? value.toString() : ''} onCopy={onCopy}>
              <i className={`icon-copy font-20 text-${copied ? 'primary' : 'muted'}`} />
            </CopyToClipboard>
          </CInputGroupText>
        </CInputGroupAppend>
      </CInputGroup>
    </CFormGroup>
  );
};

export default CopiableInput;
