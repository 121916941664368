/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* const EUCountries = [
  'BE', 'BG', 'CZ', 'DK', 'DE', 'DE', 'EE', 'IE', 'EL',
  'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU',
  'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE']; */

// MixPanel is loaded asynchronously due to GDPR, so on failure we retry later
// to see if it's loaded/allowed now.
const withRetries = (fn: () => void, attempts: number, timeout: number, err: (e: any) => void) => {
  try {
    fn();
  } catch (e) {
    if (attempts > 0) {
      setTimeout(() => withRetries(fn, attempts - 1, timeout, err), timeout);
    } else {
      err(e);
    }
  }
};
export const mixPanel = {
  alias: (investorId: string) => {
    withRetries(
      () => {
        (window as any).mixpanel.alias(investorId);
      },
      3,
      5000,
      e => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  identify: (uniqueIdentifier: string) => {
    withRetries(
      () => {
        (window as any).mixpanel.identify(uniqueIdentifier);
      },
      3,
      5000,
      e => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  register: (data: any = {}) => {
    withRetries(
      () => {
        (window as any).mixpanel.register(data);
      },
      3,
      5000,
      e => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  track: (event: string, data: any = {}) => {
    withRetries(
      () => {
        (window as any).mixpanel.track(event, data);
      },
      3,
      5000,
      e => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  getMixpanelId: () => {
    withRetries(
      () => {
        return (window as any).mixpanel.get_distinct_id() ?? '';
      },
      3,
      5000,
      e => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  resetMixPanel: (): void => {
    withRetries(
      () => {
        (window as any).mixpanel.reset();
      },
      3,
      5000,
      e => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  people: {
    set: (params: any) => {
      withRetries(
        () => {
          (window as any).mixpanel.people.set(params);
        },
        3,
        5000,
        e => console.warn('Mixpanel is not active', e, e.stack),
      );
    },
    set_once: (params: any) => {
      withRetries(
        () => {
          (window as any).mixpanel.people.set_once(params);
        },
        3,
        5000,
        e => console.warn('Mixpanel is not active', e, e.stack),
      );
    },
  },
};
