import { useSelector } from 'react-redux';
import { InvestableAmountDto } from '@web-api-client';
import { RootState } from '../store';

export function useInvestmentSelector(): RootState['investment'] {
  return useSelector<RootState, RootState['investment']>(state => state.investment);
}

interface IUseInvestableAmountResult {
  investableAmount: InvestableAmountDto | null;
  isInvestableAmountLoading: boolean;
}

export function useInvestableAmount(): IUseInvestableAmountResult {
  return useSelector<RootState, IUseInvestableAmountResult>(state => ({
    investableAmount: state.investment.investableAmount,
    isInvestableAmountLoading: state.investment.isInvestableAmountLoading,
  }));
}
