import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from '@components';
import { translate } from '@utils/translate';
import { Button, Card, Chip, Currency, SecuritizePage } from '@components';
import { getPortfolio } from '@state/portfolio/actions';
import { usePortfolioSelector } from '@state/portfolio/hooks';
import { CCardImg, CCol, CRow } from '@coreui/react';
import styles from './Portfolio.module.scss';
import { useHistory } from 'react-router-dom';
import {
  InvestmentDetailDto,
  InvestmentDetailDtoStatusEnum,
  RoundDtoStatusEnum,
} from '@web-api-client';
import { useDashboardMode } from '@hooks/dashboard-mode.hook';
import { getValueByInvestmentStatus } from '@utils/get-value-by-investment-status';
import { PATH_PORTFOLIO, getPath } from '@routes';

const Portfolio = () => {
  const dispatch = useDispatch();
  const { investments, isLoading, isError } = usePortfolioSelector();
  const fetchPortfolio = () => dispatch(getPortfolio());

  useEffect(() => {
    fetchPortfolio();
  }, []);

  return (
    <SecuritizePage
      title={translate('Texts.portfolio.title')}
      isLoading={isLoading}
      isError={isError}
      legacy
    >
      {!investments?.length && (
        // TODO: No item message component
        <Translate translationKey='Texts.portfolio.transfer-account.not-found' />
      )}
      {investments?.map(investment => (
        <PortfolioCard key={investment.investmentName} investment={investment} />
      ))}
    </SecuritizePage>
  );
};

export const chipColor = (
  status: InvestmentDetailDtoStatusEnum,
): 'disabled' | 'primary' | 'secondary' | 'danger' => {
  switch (status) {
    case InvestmentDetailDtoStatusEnum.Processing:
      return 'primary';
    case InvestmentDetailDtoStatusEnum.Confirming:
    case InvestmentDetailDtoStatusEnum.Confirmed:
      return 'secondary';
    default:
      return 'disabled';
  }
};

interface IPortfolioCard {
  investment: InvestmentDetailDto;
}
export const PortfolioCard = ({ investment }: PropsWithChildren<IPortfolioCard>) => {
  const history = useHistory();
  const { getTranslateByMode } = useDashboardMode();

  const detailButtonText = () => {
    switch (investment.status) {
      case InvestmentDetailDtoStatusEnum.Confirmed:
        return 'Texts.portfolio.transfer';
      case InvestmentDetailDtoStatusEnum.Confirming:
        return 'Texts.portfolio.detail-to-entry';
      case InvestmentDetailDtoStatusEnum.Requested:
      case InvestmentDetailDtoStatusEnum.Canceled:
        if (investment.round.status === RoundDtoStatusEnum.Opened) {
          return 'Texts.portfolio.detail-to-change-pre-entry';
        } else {
          return 'Texts.portfolio.detail';
        }
      default:
        return 'Texts.portfolio.detail';
    }
  };

  return (
    <Card key={investment.investmentName} className={styles.portfolioCard}>
      <div className={styles.imageCol}>
        <CCardImg src={investment.thumbnailUrl} className={styles.cardImage} />
      </div>
      <CRow className={styles.cardBody}>
        <CCol className='p-0'>
          <CRow>
            <CCol>
              <Chip
                color={chipColor(investment.status)}
                text={translate(`Texts.portfolio.fund.status.${investment.status}`)}
                className='m-0 max-width-180'
              />
              <CRow className='align-items-start justify-content-between'>
                <CCol xl={6} md={12} sm={12} className='mt-3'>
                  <span className='subtext d-block mb-1'>
                    {getTranslateByMode('Texts.portfolio.fund.name')}
                  </span>
                  <h5 className='m-0'>{investment.investmentName}</h5>
                </CCol>
                {![
                  InvestmentDetailDtoStatusEnum.Redeemed,
                  InvestmentDetailDtoStatusEnum.TransferredOrRedeemed,
                ].includes(investment?.status) && (
                  <>
                    <CCol xl={2} md={4} sm={12} className='mt-3'>
                      <span className='subtext d-block mb-1'>
                        <Translate
                          translationKey={
                            getValueByInvestmentStatus(
                              investment?.status,
                              'Texts.portfolio.fund.owned-units',
                              'Texts.portfolio.fund.confirmed-units',
                              'Texts.portfolio.fund.applied-units',
                            ) as string
                          }
                        />
                      </span>
                      <h5 className='m-0'>
                        <Translate
                          translationKey='Texts.portfolio.unit'
                          parameters={{
                            unit: (
                              getValueByInvestmentStatus(
                                investment?.status,
                                investment.ownedUnits,
                                investment.confirmedUnits,
                                investment.appliedUnits,
                              ) as number
                            )?.toLocaleString(),
                          }}
                        />
                      </h5>
                    </CCol>
                    <CCol xl={2} md={4} sm={12} className='mt-3'>
                      <span className='subtext d-block mb-1'>
                        <Translate
                          translationKey={
                            getValueByInvestmentStatus(
                              investment?.status,
                              'Texts.portfolio.fund.owned-amount',
                              'Texts.portfolio.fund.confirmed-amount-pledged',
                              'Texts.portfolio.fund.applied-amount-pledged',
                            ) as string
                          }
                        />
                      </span>
                      <h5 className='m-0'>
                        <Currency
                          amount={
                            getValueByInvestmentStatus(
                              investment?.status,
                              investment.ownedAmount,
                              investment.confirmedAmountPledged,
                              investment.appliedAmountPledged,
                            ) as number
                          }
                          currency={investment?.currency}
                        />
                      </h5>
                    </CCol>
                  </>
                )}
                <CCol xl={2} md={4} sm={12} className='align-self-xl-end'>
                  <Button
                    className='mt-3 w-100'
                    size='sm'
                    onClick={async () =>
                      history.push(
                        getPath(PATH_PORTFOLIO.portfolio, {
                          id: investment.investmentId,
                        }),
                      )
                    }
                  >
                    <Translate translationKey={detailButtonText()} />
                  </Button>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </Card>
  );
};

export default Portfolio;
