/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, Reducer } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { SET_LANGUAGE, i18nNext } from './actionTypes';

const defaultState = {
  isLoading: true,
  isError: false,
  translations: {},
  languages: { EN: 'English' },
  activeLanguage: Cookies.get('active-language') || 'EN',
};

const _i18n = (state = defaultState, { type, payload }: any) => {
  switch (type) {
    case SET_LANGUAGE:
      return {
        ...state,
        activeLanguage: payload,
      };
    case i18nNext.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case i18nNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        translations: {},
        languages: { EN: 'English' },
      };
    case i18nNext.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        translations: payload.translations || {},
        languages: payload.languages || { EN: 'English' },
      };
    default:
      return state;
  }
};

export interface I18nState {
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly translations: { [key: string]: string };
  readonly languages: { [key: string]: { language: string; label: string } };
  readonly activeLanguage: string;
}

export const initialState: I18nState = {
  isLoading: false,
  isError: false,
  translations: {},
  languages: {},
  activeLanguage: 'JA',
};

export const i18n = ((state = initialState, action: AnyAction) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return _i18n(state as any, action);
}) as Reducer<I18nState, AnyAction>;
