/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import { registerLocale } from 'i18n-iso-countries'; // TODO: Remove this dependency if possible
import moment from 'moment'; // TODO: Remove this dependency if possible
import { createHttpAction } from '../http-action';
import { SET_LANGUAGE, i18nNext } from './actionTypes';

const setActiveLanguage = (language = 'EN') => {
  Cookies.set('active-language', language, { expires: 31, path: '/' });
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
};

export const getTranslations = (language = 'EN') =>
  createHttpAction(
    i18nNext,
    {
      params: {
        language: (language || 'EN').toLowerCase(),
      },
    },
    {
      onSuccess(response: any) {
        const languages = Object.keys(response.languages).map(key => key.toLowerCase());
        languages.forEach(language => {
          registerLocale(require(`i18n-iso-countries/langs/${language}.json`)); // eslint-disable-line
        });
        let activeLang = language.toLowerCase();
        if (activeLang === 'en') activeLang = 'en-gb';
        if (activeLang === 'zh') activeLang = 'zh-cn';
        require(`moment/locale/${activeLang}.js`); // eslint-disable-line
        moment.locale(activeLang);
        return setActiveLanguage(language);
      },
    },
  );
