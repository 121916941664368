import React, { PropsWithChildren } from 'react';
import { useNationality } from '@hooks/nationality.hook';
import { ISelectInputProps, SelectInput } from '../Form';

const NationalitySelect = (
  props: PropsWithChildren<Omit<ISelectInputProps, 'options' | 'ref'>>,
) => {
  const { getNationalities } = useNationality();
  return <SelectInput {...props} options={getNationalities()} />;
};

export default NationalitySelect;
