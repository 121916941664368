import React, { PropsWithChildren, forwardRef } from 'react';
import { CFormGroup, CLabel, CInputCheckbox, innerRef } from '@coreui/react';
import classNames from 'classnames';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import './CheckboxInput.scss';

export interface ICheckboxInputProps extends PropsWithChildren<CInputCheckbox> {
  label?: string;
  errors?: DeepMap<FieldValues, FieldError>;
}

const CheckboxInput = forwardRef(function CheckboxInput(
  { children, className, label, errors, name = '', ...props }: ICheckboxInputProps,
  ref,
) {
  return (
    <CFormGroup>
      <CLabel className={classNames('CheckboxInputComponent m-0', className)}>
        <CInputCheckbox
          innerRef={ref as innerRef}
          className='position-static ml-0'
          name={name}
          {...props}
        />
        <span>{children}</span>
      </CLabel>
      {errors && (
        <ErrorMessage className='errorMessage text-danger' errors={errors} name={name} as='small' />
      )}
    </CFormGroup>
  );
});

export default CheckboxInput;
