import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { PATH_OPPORTUNITIES, getPath } from '@routes';
import { Button } from '../../Button';
import { Translate } from '../../Translate';

interface IDetailButton {
  opportunityId: number;
  isListCard?: boolean;
}
const RedirectToDetailButton = ({ opportunityId, isListCard }: IDetailButton) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(getPath(PATH_OPPORTUNITIES.opportunity, { id: opportunityId }));
  };

  return (
    <Button
      className={classNames('w-100', {
        ['max-width-180']: isListCard,
      })}
      size='sm'
      onClick={handleClick}
    >
      <Translate translationKey='Buttons.detail' />
    </Button>
  );
};

export default RedirectToDetailButton;
