import React, { PropsWithChildren, useState, forwardRef } from 'react';
import { CFormGroup, CLabel, CSelect, CSpinner, innerRef } from '@coreui/react';
import classNames from 'classnames';
import './SelectInput.scss';
import { Translate } from '../../Translate';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { selectOptionSeparator } from '@utils/constants';

export type Option = { label: string; value: string | number };
export type Options = Option[];
export type GroupedOption = { label: string; value: Option[] };
export type GroupedOptions = GroupedOption[];
export interface ISelectInputProps extends Omit<PropsWithChildren<CSelect>, 'label'> {
  options: Array<Option | GroupedOption>;
  errors?: DeepMap<FieldValues, FieldError>;
  error?: boolean;
  loading?: boolean;
  label?: PropsWithChildren<CSelect>['label'] | React.ReactElement;
}

const SelectInput = forwardRef(function SelectInput(
  {
    children,
    className,
    label,
    options = [],
    name = '',
    errors,
    placeholder,
    onChange,
    error,
    loading,
    disabled,
    ...props
  }: ISelectInputProps,
  ref,
) {
  const [isPlaceholder, setIsPlaceholder] = useState(!!placeholder);
  return (
    <CFormGroup className={classNames('SelectInputComponent', className)}>
      {label &&
        (typeof label === 'string' ? (
          <CLabel>
            <Translate translationKey={label} />
          </CLabel>
        ) : (
          label
        ))}
      <div className='position-relative'>
        <CSelect
          name={name}
          innerRef={ref as innerRef}
          className={classNames({
            'placeholder-color': isPlaceholder || props.value === '',
            error: error,
          })}
          disabled={loading || disabled}
          {...props}
          onChange={e => {
            const { value } = e.target as HTMLInputElement;
            setIsPlaceholder(!value);
            onChange && onChange(e);
          }}
        >
          {placeholder ? (
            <option className='placeholder' key='' value=''>
              {placeholder}
            </option>
          ) : (
            ''
          )}
          {options.map((item, idx) => {
            return Array.isArray(item.value) ? (
              <optgroup
                label={item.label}
                key={item.label === selectOptionSeparator ? idx : item.label}
              >
                {item.value.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
            ) : (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </CSelect>
        {loading && (
          <CSpinner
            className='position-absolute'
            style={{ left: 'calc(50% - 1rem)', top: 'calc(50% - 1rem)' }}
            color='primary'
          />
        )}
      </div>
      {errors && (
        <ErrorMessage className='errorMessage text-danger' errors={errors} name={name} as='small' />
      )}
    </CFormGroup>
  );
});

export default SelectInput;
