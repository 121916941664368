import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getPortfolioNext } from './actionTypes';
import { InvestmentDetailDto } from '@web-api-client';

export interface IPortfolioState {
  isLoading: boolean;
  isError: boolean;
  readonly investments?: InvestmentDetailDto[];
}

export const initialState: IPortfolioState = {
  isLoading: true,
  isError: false,
  investments: undefined,
};

export const portfolio = createReducer(initialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getPortfolioNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<InvestmentDetailDto[]>>(
      getPortfolioNext.SUCCESS,
      (state, { payload }) => {
        state.investments = payload;
        state.isLoading = false;
      },
    )
    .addCase<string, PayloadAction<void>>(getPortfolioNext.ERROR, state => {
      state.isLoading = false;
      state.isError = true;
    }),
);
