import { CLabel, CRow } from '@coreui/react';
import { Translate } from '../Translate';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import styles from './PasswordCheckChip.module.scss';

interface IPasswordCheckChip {
  isValid: boolean;
}

interface IPasswordCheckChips {
  isDirty: boolean;
  password: string;
}

// Password check
const isPwdMinLength = (value: string) => value?.length >= 8;
const hasPwdNumber = (value: string) => /\d/.test(value);
const isPwdUppercaseChar = (value: string) => /[A-Z]/.test(value);
const isPwdLowercaseChar = (value: string) => /[a-z]/.test(value);

const PasswordCheckChip = ({ isValid, children }: PropsWithChildren<IPasswordCheckChip>) => {
  return (
    <div className={classNames(isValid ? styles.requirementsCompleted : styles.requirements)}>
      {children}
    </div>
  );
};

const PasswordCheckChips = ({ isDirty, password }: PropsWithChildren<IPasswordCheckChips>) => {
  return (
    <CRow className={classNames('m-0 justify-content-between')}>
      <PasswordCheckChip isValid={isDirty && isPwdLowercaseChar(password)}>
        <CLabel className='mb-0'>
          <Translate translationKey='Texts.registration.password.lowercase' />
        </CLabel>
      </PasswordCheckChip>
      <PasswordCheckChip isValid={isDirty && isPwdUppercaseChar(password)}>
        <Translate translationKey='Texts.registration.password.uppercase' />
      </PasswordCheckChip>
      <PasswordCheckChip isValid={isDirty && hasPwdNumber(password)}>
        <Translate translationKey='Texts.registration.password.number' />
      </PasswordCheckChip>
      <PasswordCheckChip isValid={isDirty && isPwdMinLength(password)}>
        <Translate
          translationKey='Texts.registration.password.min-length'
          parameters={{ min: 8 }}
        />
      </PasswordCheckChip>
    </CRow>
  );
};

export default PasswordCheckChips;
