import { createEntityAdapter, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { TradeOpportunityDto } from '@web-api-client';
import { getBuyOpportunitiesNext, getSellOpportunitiesNext } from './actionTypes';

export const sellOpportunitiesAdapter = createEntityAdapter<TradeOpportunityDto>({
  selectId: sellOpportunity => sellOpportunity.opportunityId,
});

export const buyOpportunitiesAdapter = createEntityAdapter<TradeOpportunityDto>({
  selectId: buyOpportunity => buyOpportunity.opportunityId,
});

const sellOpportunitiesInitialState = sellOpportunitiesAdapter.getInitialState({
  isLoading: false,
  isError: false,
});

const buyOpportunitiesInitialState = buyOpportunitiesAdapter.getInitialState({
  isLoading: false,
  isError: false,
});

export const tradeSellOpportunities = createReducer(sellOpportunitiesInitialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getSellOpportunitiesNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<TradeOpportunityDto[]>>(
      getSellOpportunitiesNext.SUCCESS,
      (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        sellOpportunitiesAdapter.upsertMany(state, payload);
      },
    )
    .addCase<string, PayloadAction<void>>(getSellOpportunitiesNext.ERROR, state => {
      state.isLoading = false;
      state.isError = true;
    }),
);

export const tradeBuyOpportunities = createReducer(buyOpportunitiesInitialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getBuyOpportunitiesNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<TradeOpportunityDto[]>>(
      getBuyOpportunitiesNext.SUCCESS,
      (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        buyOpportunitiesAdapter.upsertMany(state, payload);
      },
    )
    .addCase<string, PayloadAction<void>>(getBuyOpportunitiesNext.ERROR, state => {
      state.isLoading = false;
      state.isError = true;
    }),
);

export type ITradeSellOpportunities = ReturnType<typeof tradeSellOpportunities.getInitialState>;
export type ITradeBuyOpportunities = ReturnType<typeof tradeBuyOpportunities.getInitialState>;
