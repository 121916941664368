import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CCol, CRow } from '@coreui/react';
import { useCurrency } from '@hooks/currency.hook';
import { ErrorMessage } from '@hookform/error-message';
import { useInvestRestrictions } from '@hooks/invest-restrictions.hook';
import { getInvestableAmount } from '@state/investment/actions';
import { useInvestableAmount } from '@state/investment/hooks';
import { ComputePreciseOperation, computePrecise } from '@utils/compute-precise';
import { translate } from '@utils/translate';
import { InvestmentDetailDto, OpportunityDetailDto } from '@web-api-client';
import { Card } from '../Card';
import { Currency } from '../Currency';
import { FormGroup, Input } from '../Form';
import { Translate } from '../Translate';
import './InvestUnitInput.scss';
import { useAuthSelector } from '@state/auth/hooks';

interface IInvestUnitInputForm {
  unit: string;
}

export interface IInvestUnitInput {
  opportunity: OpportunityDetailDto;
  investment?: InvestmentDetailDto;
  className?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    state: { unit: number; amount: number },
  ) => void;
  disabled?: boolean;
}

const InvestUnitInput = ({
  className,
  opportunity,
  investment,
  onChange,
  disabled,
}: PropsWithChildren<IInvestUnitInput>) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuthSelector();
  const { investableAmount, isInvestableAmountLoading } = useInvestableAmount();
  const { minInvestmentUnit, maxInvestmentUnit, maxInvestableUnit, investLimit, unitValue } =
    useInvestRestrictions({ opportunity, investableAmount });

  const [, setInvestUnit] = useState(0);
  const [investAmount, setInvestAmount] = useState(0);
  const { formatCurrency } = useCurrency({
    currency: opportunity.roundAdditionalInfo.currency,
  });
  const {
    control,
    formState: { errors },
  } = useForm<IInvestUnitInputForm>({ mode: 'onChange' });

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getInvestableAmount());
    }
  }, [isAuthenticated]);

  return (
    <>
      <Card className={classNames('mb-2', className)}>
        <CRow className='w-100 m-0'>
          <CCol xs='12' sm='6' className='p-4'>
            <div className='w-100'>
              {investment && (
                <p className='inputUnitNote mb-1 text-dark'>
                  <Translate
                    translationKey='Texts.opportunity.invested-unit'
                    parameters={{ unit: investment?.appliedUnits.toLocaleString() }}
                  />
                </p>
              )}
              <FormGroup row={true}>
                <Controller
                  name='unit'
                  control={control}
                  defaultValue=''
                  rules={{
                    min: {
                      value: minInvestmentUnit,
                      message: translate('Errors.min', {
                        min: minInvestmentUnit.toLocaleString(),
                      }),
                    },
                    pattern: {
                      value: /^\d+$/,
                      message: translate('Errors.number-invalid'),
                    },
                    validate: (value: string) => {
                      const unit = Number(value);
                      if (unit > maxInvestableUnit) {
                        if (maxInvestmentUnit === maxInvestableUnit) {
                          return translate('Errors.max', {
                            max: maxInvestmentUnit.toLocaleString(),
                          });
                        }
                        return translate('Errors.cross-opportunity-investment-limit-exceeded', {
                          investable_amount: formatCurrency(
                            computePrecise(
                              maxInvestableUnit,
                              unitValue,
                              ComputePreciseOperation.Multiply,
                            ),
                          ),
                          invest_limit: formatCurrency(investLimit),
                        });
                      }
                      return false;
                    },
                  }}
                  render={({ field: { value, onChange: _onChange } }) => (
                    <Input
                      forceFullToHalf
                      className='flex-grow-1'
                      value={value}
                      name='unit'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const unit = Number(e.target.value) || 0;
                        const amount = computePrecise(
                          unit,
                          unitValue,
                          ComputePreciseOperation.Multiply,
                        );

                        setInvestUnit(unit);
                        setInvestAmount(amount);
                        onChange?.(e, { unit, amount });
                        _onChange(e);
                      }}
                      disabled={disabled || isInvestableAmountLoading}
                    />
                  )}
                />
                <span className='font-24'>
                  <Translate translationKey='Texts.opportunity.unit' parameters={{ unit: '' }} />
                </span>
              </FormGroup>
              <div className='text-danger font-12 mt-1'>
                <ErrorMessage errors={errors} name='unit' />
              </div>
            </div>
          </CCol>
          <CCol xs='12' sm='6' className='p-4 d-flex justify-content-center flex-column grayBG'>
            {investment && (
              <p className='inputUnitNote text-dark mb-0'>
                <Translate
                  translationKey='Texts.opportunity.invested-amount'
                  parameters={{ amount: formatCurrency(investment?.appliedAmountPledged) }}
                />
              </p>
            )}
            <span className='font-24 mr-2 grayFont d-flex align-items-center align-self-center flex-grow-1'>
              <Currency amount={investAmount} currency={opportunity.roundAdditionalInfo.currency} />
            </span>
            <div
              className={classNames(
                'd-flex justify-content-center align-items-center unitArrowCircle',
              )}
            >
              <i className={classNames('icon-arrow-right font-20 unitArrow')} />
            </div>
          </CCol>
        </CRow>
      </Card>

      <div className='mb-4 px-4 text-dark'>
        {investment && (
          <p className='inputUnitNote mb-1'>
            {translate('Texts.opportunity.input-unit-after-change')}
          </p>
        )}
        <p className='inputUnitNote'>
          {translate('Texts.opportunity.investing-amount-restriction', {
            amountPerUnit: formatCurrency(unitValue),
            min: translate('Texts.opportunity.unit', {
              unit: minInvestmentUnit.toLocaleString(),
            }),
            max:
              maxInvestableUnit === Infinity
                ? translate('Texts.opportunity.no-upper-limit')
                : translate('Texts.opportunity.unit', {
                    unit: maxInvestableUnit.toLocaleString(),
                  }),
          })}
        </p>
      </div>
    </>
  );
};

export default InvestUnitInput;
