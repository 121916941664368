import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { CCard } from '@coreui/react';
import Card from './Card';
import './SparseCard.scss';

type ISparseCard = PropsWithChildren<CCard>;

const SparseCard = ({ children, className, ...props }: ISparseCard) => {
  return (
    <Card className={(classNames(className), 'SparseCardComponent')} {...props}>
      {children}
    </Card>
  );
};
export default SparseCard;
