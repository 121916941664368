import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { toast, ToastOptions } from 'react-toastify';
import { translate } from '@utils/translate';
import './Toast.scss';

export interface IToastProps {
  icon?: string;
  children?: ReactNode;
}

const Toast = ({ icon = '', children }: IToastProps) => (
  <div className='securitize-toast'>
    <i className={classNames('securitize-toast__icon', icon)} />
    <div className='securitize-toast__body'>
      {children && <div className='securitize-toast__body__content'>{children}</div>}
    </div>
  </div>
);

export const successToast = (message: string, options?: ToastOptions | undefined): void => {
  toast.success(<Toast icon='icon icon-success'>{translate(message)}</Toast>, options);
};

export const errorToast = (message: string, options?: ToastOptions | undefined): void => {
  toast.error(<Toast icon='icon icon-error'>{translate(message)}</Toast>, {
    ...options,
    toastId: message,
  });
};

export default Toast;
