import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSettingSelector } from '@state/setting/hooks';
import { ServerError } from '../ServerError';
import { ServerErrorPage } from '../ServerErrorPage';
import { Loading } from '../Loading';
import PageHeader from './PageHeader';
import Footer from './Footer';

export interface ISecuritizePage {
  isLoading?: boolean;
  isError?: boolean;
  isTranslationError?: boolean;
  className?: string;
  errorText?: string;
  title?: string;
  subTitle?: string;
  legacy?: boolean;
  noUserMenu?: boolean;
  errorDisplay?: JSX.Element;
  showBackButton?: boolean;
  showFooter?: boolean;
  headerAction?: JSX.Element;
}

const SecuritizePage = ({
  isLoading = false,
  isError = false,
  isTranslationError = false,
  errorText = 'Errors.server-error',
  title,
  subTitle,
  legacy = false,
  noUserMenu = false,
  errorDisplay,
  showBackButton,
  children,
  showFooter,
  headerAction,
}: PropsWithChildren<ISecuritizePage>) => {
  const { setting } = useSettingSelector();
  const history = useHistory();
  const location = useLocation();
  const [canBack, setCanBack] = useState(false);

  useEffect(() => {
    const unregister = history.listen((_, action) => {
      if (action === 'PUSH') {
        sessionStorage.setItem('canBack', String(true));
      } else {
        sessionStorage.removeItem('canBack');
      }
    });
    return unregister;
  }, []);

  useEffect(() => {
    const canBackItem = sessionStorage.getItem('canBack');
    setCanBack(canBackItem === String(true));
  }, [location]);

  return (
    <>
      {isTranslationError ? (
        <ServerErrorPage />
      ) : isError ? (
        <ServerError errorText={errorText}>{errorDisplay}</ServerError>
      ) : (
        <>
          <PageHeader
            noUserMenu={noUserMenu}
            title={title}
            subtitle={subTitle}
            legacy={legacy}
            showBackButton={showBackButton && canBack}
            action={headerAction}
          />
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {children}
              {showFooter && setting?.variables.footer && <Footer />}
            </>
          )}
        </>
      )}
    </>
  );
};

export default SecuritizePage;
