import { createReducer, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import {
  AuthorizeInvestorPaysResponseDto,
  AuthorizeResponseDto,
  TokenWalletsResponseDto,
  WithdrawTokensInvestorPaysResponseDto,
} from '@web-api-client';
import {
  getTokenWalletsNext,
  authorizeNext,
  authorizeInvestorPaysNext,
  authorizeInvestorPaysCompleteNext,
  withdrawTokensNext,
  withdrawTokensInvestorPaysNext,
  withdrawTokensInvestorPaysCompleteNext,
} from './actionTypes';

type IBaseState = {
  isLoading: boolean;
  isError: boolean;
  error: string | Record<string, string> | null;

  isAuthorizeLoading: boolean;
  isAuthorizeError: boolean;

  isAuthorizeInvestorPaysLoading: boolean;
  isAuthorizeInvestorPaysError: boolean;

  isAuthorizeInvestorPaysCompleteLoading: boolean;
  isAuthorizeInvestorPaysCompleteError: boolean;

  isWithdrawTokensLoading: boolean;
  isWithdrawTokensError: boolean;

  isWithdrawTokensInvestorPaysLoading: boolean;
  isWithdrawTokensInvestorPaysError: boolean;

  isWithdrawTokensInvestorPaysCompleteLoading: boolean;
  isWithdrawTokensInvestorPaysCompleteError: boolean;
};

type TokenWalletsBase = {
  addresses: TokenWalletsResponseDto['tokenWallets'][0]['address'][];
  tokenWallets: Record<
    TokenWalletsResponseDto['tokenWallets'][0]['address'],
    TokenWalletsResponseDto['tokenWallets'][0]
  >;
};
export type TokenWallets = TokenWalletsBase & {
  tokenId: TokenWalletsResponseDto['tokenId'];
};

export const tokenWalletsAdapter = createEntityAdapter<TokenWallets>({
  selectId: wallet => wallet.tokenId,
});

const initialState = tokenWalletsAdapter.getInitialState<IBaseState>({
  isLoading: false,
  isError: false,
  error: null,

  isAuthorizeLoading: false,
  isAuthorizeError: false,

  isAuthorizeInvestorPaysLoading: false,
  isAuthorizeInvestorPaysError: false,

  isAuthorizeInvestorPaysCompleteLoading: false,
  isAuthorizeInvestorPaysCompleteError: false,

  isWithdrawTokensLoading: false,
  isWithdrawTokensError: false,

  isWithdrawTokensInvestorPaysLoading: false,
  isWithdrawTokensInvestorPaysError: false,

  isWithdrawTokensInvestorPaysCompleteLoading: false,
  isWithdrawTokensInvestorPaysCompleteError: false,
});

export const tokenWallets = createReducer(initialState, builder =>
  builder
    .addCase(getTokenWalletsNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<TokenWalletsResponseDto>>(
      getTokenWalletsNext.SUCCESS,
      (state, { payload: tokenWalletsResponse }) => {
        state.isLoading = false;
        state.isError = false;
        state.error = null;

        const normalizedTokenWallets = tokenWalletsResponse.tokenWallets.reduce(
          (acc, tokenWallet) => {
            acc.addresses.push(tokenWallet.address);
            acc.tokenWallets[tokenWallet.address] = tokenWallet;
            return acc;
          },
          { addresses: [], tokenWallets: {} } as TokenWalletsBase,
        );

        tokenWalletsAdapter.upsertOne(state, {
          tokenId: tokenWalletsResponse.tokenId,
          ...normalizedTokenWallets,
        });
      },
    )
    .addCase<string, PayloadAction<Record<string, string>>>(
      getTokenWalletsNext.ERROR,
      (state, { payload: error }) => {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<void>>(authorizeNext.INIT, state => {
      state.isAuthorizeLoading = true;
      state.isAuthorizeError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<AuthorizeResponseDto>>(authorizeNext.SUCCESS, state => {
      state.isAuthorizeLoading = false;
      state.isAuthorizeError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<Record<string, string>>>(
      authorizeNext.ERROR,
      (state, { payload: error }) => {
        state.isAuthorizeLoading = false;
        state.isAuthorizeError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<void>>(authorizeInvestorPaysNext.INIT, state => {
      state.isAuthorizeInvestorPaysLoading = true;
      state.isAuthorizeInvestorPaysError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<AuthorizeInvestorPaysResponseDto>>(
      authorizeInvestorPaysNext.SUCCESS,
      state => {
        state.isAuthorizeInvestorPaysLoading = false;
        state.isAuthorizeInvestorPaysError = false;
        state.error = null;
      },
    )
    .addCase<string, PayloadAction<Record<string, string>>>(
      authorizeInvestorPaysNext.ERROR,
      (state, { payload: error }) => {
        state.isAuthorizeInvestorPaysLoading = false;
        state.isAuthorizeInvestorPaysError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<void>>(authorizeInvestorPaysCompleteNext.INIT, state => {
      state.isAuthorizeInvestorPaysCompleteLoading = true;
      state.isAuthorizeInvestorPaysCompleteError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<void>>(authorizeInvestorPaysCompleteNext.SUCCESS, state => {
      state.isAuthorizeInvestorPaysCompleteLoading = false;
      state.isAuthorizeInvestorPaysCompleteError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<Record<string, string>>>(
      authorizeInvestorPaysCompleteNext.ERROR,
      (state, { payload: error }) => {
        state.isAuthorizeInvestorPaysCompleteLoading = false;
        state.isAuthorizeInvestorPaysCompleteError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<void>>(withdrawTokensNext.INIT, state => {
      state.isWithdrawTokensLoading = true;
      state.isWithdrawTokensError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<void>>(withdrawTokensNext.SUCCESS, state => {
      state.isWithdrawTokensLoading = false;
      state.isWithdrawTokensError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<Record<string, string>>>(
      withdrawTokensNext.ERROR,
      (state, { payload: error }) => {
        state.isWithdrawTokensLoading = false;
        state.isWithdrawTokensError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<void>>(withdrawTokensInvestorPaysNext.INIT, state => {
      state.isWithdrawTokensInvestorPaysLoading = true;
      state.isWithdrawTokensInvestorPaysError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<WithdrawTokensInvestorPaysResponseDto>>(
      withdrawTokensInvestorPaysNext.SUCCESS,
      state => {
        state.isWithdrawTokensInvestorPaysLoading = false;
        state.isWithdrawTokensInvestorPaysError = false;
        state.error = null;
      },
    )
    .addCase<string, PayloadAction<Record<string, string>>>(
      withdrawTokensInvestorPaysNext.ERROR,
      (state, { payload: error }) => {
        state.isWithdrawTokensInvestorPaysLoading = false;
        state.isWithdrawTokensInvestorPaysError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<void>>(withdrawTokensInvestorPaysCompleteNext.INIT, state => {
      state.isWithdrawTokensInvestorPaysCompleteLoading = true;
      state.isWithdrawTokensInvestorPaysCompleteError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<void>>(withdrawTokensInvestorPaysCompleteNext.SUCCESS, state => {
      state.isWithdrawTokensInvestorPaysCompleteLoading = false;
      state.isWithdrawTokensInvestorPaysCompleteError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<Record<string, string>>>(
      withdrawTokensInvestorPaysCompleteNext.ERROR,
      (state, { payload: error }) => {
        state.isWithdrawTokensInvestorPaysCompleteLoading = false;
        state.isWithdrawTokensInvestorPaysCompleteError = true;
        state.error = error;
      },
    ),
);

export type ITokenWalletsState = ReturnType<typeof tokenWallets.getInitialState>;
