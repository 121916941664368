import React, { PropsWithChildren, useState } from 'react';
import LaddaButton, { ZOOM_OUT } from 'react-ladda-button'; // TODO: Remove dependency if possible
import boxImg from '@assets/img/other/box-image.svg';
import { translate } from '@utils/translate';
import { SafeReactHtmlParser } from '../SafeReactHtmlParser';
import { Translate } from '../Translate';

interface IErrorPage {
  className?: string;
  title?: string;
  description?: string;
  showButton?: boolean;
}

export const ErrorPage = ({
  className,
  title = '',
  description = '',
  showButton = true,
}: PropsWithChildren<IErrorPage>) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      className={`server-error-page d-flex align-items-center justify-content-center ${className}`}
    >
      <div className='animated fadeIn server-error-page__content d-flex align-items-center flex-column p-2'>
        <img src={boxImg as string} alt='error' />
        <div className='font-20 mt-5'>
          <Translate translationKey={title} />
        </div>
        <div className='mt-4'>{SafeReactHtmlParser(translate(description))}</div>
        {showButton && (
          <LaddaButton
            className='btn m-0 p-3 mt-5 btn-primary btn-block font-weight-bold text-white'
            loading={isLoading}
            color='primary'
            data-style={ZOOM_OUT}
            onClick={() => {
              setIsLoading(true);
              window.location.reload();
            }}
          >
            <Translate translationKey='Buttons.reload' />
          </LaddaButton>
        )}
      </div>
    </div>
  );
};
