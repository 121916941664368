import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import './Badge.scss';

export interface IBadgeProps {
  className?: string;
  children: ReactNode | ReactNode[];
}

const Badge: FC<IBadgeProps> = ({ className, children }) => {
  return (
    <div className={classNames('BadgeComponent', 'd-flex', 'flex-wrap')}>
      <span className={classNames('badge', className)}>{children}</span>
    </div>
  );
};

export default Badge;
