import React, { PropsWithChildren } from 'react';
import { CCardBody } from '@coreui/react';
import classNames from 'classnames';

import './CardBody.scss';

export interface ICardBody extends PropsWithChildren<CCardBody> {
  sparse?: boolean;
}

const CardBody = ({ sparse, children, className, ...props }: ICardBody) => (
  <CCardBody
    className={classNames('CardBodyComponent', sparse ? 'sparse' : '', className)}
    {...props}
  >
    {children}
  </CCardBody>
);

export default CardBody;
