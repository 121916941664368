export const parseArrayValue = (str?: string): string[] => {
  try {
    return JSON.parse(str || '[]');
  } catch (e) {
    return [];
  }
};

export const fullToHalf = (str: string): string => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
};

interface IRemoveNonNumericCharactersOptions {
  allowFullwidth?: boolean;
}

export const removeNonNumericCharacters = (
  str: string,
  options?: IRemoveNonNumericCharactersOptions,
): string => {
  const regex = options?.allowFullwidth ? /[^\d０-９]/g : /[^\d]/g;
  return str ? str.replace(regex, '') : '';
};
