import LaddaButton, { ZOOM_OUT } from 'react-ladda-button'; // TODO: Remove dependency if possible
import React, { useState } from 'react';
import boxImg from '@assets/img/other/box-image.svg';
import './ServerErrorPage.scss';

export const ServerErrorPage: React.FC<{
  className?: string;
  errorText?: string;
  children?: JSX.Element;
}> = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className='server-error-page d-flex align-items-center justify-content-center'>
      <div className='animated fadeIn server-error-page__content  d-flex align-items-center flex-column'>
        <img src={boxImg as string} alt='error' />
        <div className='font-20 mt-5'>Something went wrong!</div>
        <div className='mt-4 text-center'>
          We have problems to load this page.
          <br />
          Please, try again.
        </div>
        <LaddaButton
          className='btn m-0 p-3 mt-5 btn-primary btn-block font-weight-bold text-white'
          loading={isLoading}
          color='primary'
          data-style={ZOOM_OUT}
          onClick={() => {
            setIsLoading(true);
            window.location.reload();
          }}
        >
          Refresh
        </LaddaButton>
      </div>
    </div>
  );
};
