/*
  Thin-layer utility function for precision computation. We avoid using decimal.js directly in case
  we want to replace decimal.js with another library and/or if we want to add additional logic.
  Furthermore, this improves testability.

  Some assumptions about usage:
  1. Input numbers are <= 15 significant digits (TypeScript cannot handle larger decimal numbers)
  2. Output number is <= 15 significant digits (for the same reason as mentioned above)

  If these assumptions are broken, then we may need to consider storing/retrieving/sending/receiving
  numbers as string values to avoid precision loss due to TypeScript limitations.
*/

import { Decimal } from 'decimal.js';

export enum ComputePreciseOperation {
  Add = 'add',
  Subtract = 'subtract',
  Multiply = 'multiply',
  Divide = 'divide',
}

export const computePrecise = (
  a: number,
  b: number,
  operation: ComputePreciseOperation,
): number => {
  let value: Decimal = new Decimal(a);

  if (operation === ComputePreciseOperation.Add) {
    value = value.plus(b);
  } else if (operation === ComputePreciseOperation.Subtract) {
    value = value.minus(b);
  } else if (operation === ComputePreciseOperation.Multiply) {
    value = value.times(b);
  } else {
    value = value.dividedBy(b);
  }

  return Number(value.toString());
};
