import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  CLEAR_OPPORTUNITY_DETAIL,
  getOpportunityDocumentNext,
  getOpportunityNext,
} from './actionTypes';
import { OpportunityDetailDto, OpportunityDocumentDto } from '@web-api-client';
import { HttpCommonErrorTypes } from '@utils/error-types';
import { IHttpErrorResponse } from '../types';

type FetchedOpportunityDocumentDto = OpportunityDocumentDto & {
  fetchedAt: number;
};

export interface IOpportunityState {
  isLoading: boolean;
  isError: boolean;
  readonly opportunity?: OpportunityDetailDto;
  readonly opportunityDocuments: Record<number, FetchedOpportunityDocumentDto>;
}

export const initialOpportunityState: IOpportunityState = {
  isLoading: true,
  isError: false,
  opportunityDocuments: {},
};

export const opportunityDetail = createReducer(initialOpportunityState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getOpportunityNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
      state.opportunityDocuments = {};
    })
    .addCase<string, PayloadAction<IHttpErrorResponse<HttpCommonErrorTypes>>>(
      getOpportunityNext.ERROR,
      (state, { payload }) => {
        if (payload.name === HttpCommonErrorTypes.NOT_FOUND_EXCEPTION) {
          location.hash = '#/not-found';
        }
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      },
    )
    .addCase<string, PayloadAction<OpportunityDetailDto>>(
      getOpportunityNext.SUCCESS,
      (state, { payload: opportunity }) => {
        state.isLoading = false;
        state.opportunity = opportunity;
      },
    )
    .addCase<string, PayloadAction<void>>(CLEAR_OPPORTUNITY_DETAIL, state => {
      state.opportunity = undefined;
      state.opportunityDocuments = {};
    })
    .addCase<string, PayloadAction<OpportunityDocumentDto>>(
      getOpportunityDocumentNext.SUCCESS,
      (state, { payload: opportunityDocument }) => {
        state.opportunityDocuments[opportunityDocument.documentId] = {
          ...opportunityDocument,
          fetchedAt: Date.now(),
        };
      },
    ),
);
