import React from 'react';
import { useSettingSelector } from '@state/setting/hooks';
import { VariableDtoDashboardModeEnum } from '@web-api-client';

export interface ISwitchComponentByMode {
  ftkComponent?: JSX.Element;
  cbSelfOfferingComponent?: JSX.Element;
}

const ModeComponentSwitch = ({ ftkComponent, cbSelfOfferingComponent }: ISwitchComponentByMode) => {
  const { setting } = useSettingSelector();
  switch (setting?.variables.dashboardMode) {
    case VariableDtoDashboardModeEnum.Ftk:
      return ftkComponent || <></>;
    case VariableDtoDashboardModeEnum.CbSelfOffering:
      return cbSelfOfferingComponent || <></>;
    default:
      return <></>;
  }
};

export default ModeComponentSwitch;
