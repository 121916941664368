import React from 'react';
import classNames from 'classnames';
import styles from './Content.module.scss';

interface IContent {
  children?: React.ReactNode;
  isListCard?: boolean;
}

const Content = ({ children, isListCard }: IContent) => {
  return (
    <div
      className={classNames(styles.Content, {
        [styles.ListContent]: isListCard,
      })}
    >
      {children}
    </div>
  );
};

export default Content;
