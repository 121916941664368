import { PATH_ENTRY, PATH_PRE_ENTRY, PATH_REGISTRATION } from './paths';
import { routes } from './routes';
import type { WizardRoute } from './types';

export const PRE_ENTRY_WIZARD_STEPS = [
  PATH_PRE_ENTRY.description,
  PATH_PRE_ENTRY.documents,
  PATH_PRE_ENTRY.form,
  PATH_PRE_ENTRY.confirm,
  PATH_PRE_ENTRY.complete,
];

export const ENTRY_WIZARD_STEPS = [
  PATH_ENTRY.prospectus,
  PATH_ENTRY.consent,
  PATH_ENTRY.confirm,
  PATH_ENTRY.complete,
];

export const CB_REGISTRATION_WIZARD_STEPS = [
  PATH_REGISTRATION.termsOfUse,
  PATH_REGISTRATION.profile,
  PATH_REGISTRATION.bank,
  PATH_REGISTRATION.compatibility,
  PATH_REGISTRATION.otherInformation,
  PATH_REGISTRATION.confirm,
];

export const FTK_REGISTRATION_WIZARD_STEPS = [
  PATH_REGISTRATION.termsOfUse,
  PATH_REGISTRATION.electronicDelivery,
  PATH_REGISTRATION.profile,
  PATH_REGISTRATION.bank,
  PATH_REGISTRATION.compatibility,
  PATH_REGISTRATION.otherInformation,
  PATH_REGISTRATION.confirm,
  PATH_REGISTRATION.kyc,
  PATH_REGISTRATION.verifyKycStatus,
];

export const STEP_TITLES = {
  [PATH_PRE_ENTRY.description]: 'Texts.pre-entry.description.title',
  [PATH_PRE_ENTRY.documents]: 'Texts.pre-entry.documents.title',
  [PATH_PRE_ENTRY.form]: 'Texts.pre-entry.form.title',
  [PATH_PRE_ENTRY.confirm]: 'Texts.pre-entry.confirm.title',
  [PATH_PRE_ENTRY.complete]: 'Texts.pre-entry.complete.title',
  [PATH_ENTRY.prospectus]: 'Texts.entry.prospectus.title',
  [PATH_ENTRY.consent]: 'Texts.entry.consent.title',
  [PATH_ENTRY.confirm]: 'Texts.entry.confirm.title',
  [PATH_ENTRY.complete]: 'Texts.entry.complete.title',
  [PATH_REGISTRATION.termsOfUse]: 'Texts.registration.terms-of-use.title',
  [PATH_REGISTRATION.electronicDelivery]: 'Texts.registration.electronic-delivery.title',
  [PATH_REGISTRATION.profile]: 'Texts.registration.profile.title',
  [PATH_REGISTRATION.bank]: 'Texts.registration.bank.title',
  [PATH_REGISTRATION.compatibility]: 'Texts.registration.compatibility.title',
  [PATH_REGISTRATION.otherInformation]: 'Texts.registration.other-information.title',
  [PATH_REGISTRATION.confirm]: 'Texts.registration.confirm.title',
  [PATH_REGISTRATION.kyc]: 'Texts.registration.kyc.title',
  [PATH_REGISTRATION.verifyKycStatus]: 'Texts.registration.verify-kyc-status.title',
};

export enum WizardType {
  RegistrationWithKyc,
  Registration,
  PreEntry,
  Entry,
}

export const wizardSteps = new Map([
  [WizardType.RegistrationWithKyc, FTK_REGISTRATION_WIZARD_STEPS],
  [WizardType.Registration, CB_REGISTRATION_WIZARD_STEPS],
  [WizardType.PreEntry, PRE_ENTRY_WIZARD_STEPS],
  [WizardType.Entry, ENTRY_WIZARD_STEPS],
]);

const getWizardRoutes = (wizardType: WizardType): WizardRoute[] => {
  const steps = wizardSteps.get(wizardType) || [];
  return steps.map(path => {
    const { component, ...route } = routes[path];
    return { ...route, path, title: STEP_TITLES[path] };
  });
};

export const wizardRoutes = new Map([
  [WizardType.RegistrationWithKyc, getWizardRoutes(WizardType.RegistrationWithKyc)],
  [WizardType.Registration, getWizardRoutes(WizardType.Registration)],
  [WizardType.PreEntry, getWizardRoutes(WizardType.PreEntry)],
  [WizardType.Entry, getWizardRoutes(WizardType.Entry)],
]);
