import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { TokenState } from './models';

export function useAuthSelector(): RootState['auth'] {
  return useSelector<RootState, RootState['auth']>(state => state.auth);
}

function getTokenState(expires: number | null, now: number) {
  if (!expires) {
    return TokenState.NOT_SET;
  }
  return expires < now ? TokenState.EXPIRED : TokenState.VALID;
}

export function useSessionExpiration() {
  const { accessTokenExpiredAt, refreshTokenExpiredAt } = useAuthSelector();

  const now = Date.now();
  const accessTokenState = getTokenState(accessTokenExpiredAt, now);
  const refreshTokenState = getTokenState(refreshTokenExpiredAt, now);

  return {
    accessTokenState,
    refreshTokenState,
  };
}
