import React, { PropsWithChildren } from 'react';
import { CButton, CSpinner } from '@coreui/react';
import classNames from 'classnames';
import './Button.scss';

export interface IButtonProps extends PropsWithChildren<CButton> {
  variant?: 'ghost' | 'outline';
  size?: 'sm' | 'lg';
  color?: 'primary' | 'secondary' | 'danger' | 'link';
  icon?: string;
  iconRight?: string;
  disabled?: boolean;
  isLoading?: boolean;
  block?: boolean;
}

const Button = ({
  children,
  className,
  size,
  icon,
  iconRight,
  disabled,
  isLoading,
  ...props
}: IButtonProps) => {
  return (
    <CButton
      disabled={disabled || isLoading}
      className={classNames('transparent ButtonComponent', className)}
      size={size}
      {...props}
    >
      <div className={classNames('d-flex justify-content-center', isLoading && 'loading')}>
        {isLoading && <CSpinner className='spinner' size={size} />}
        {icon ? <i className={classNames(icon, 'mr-2 text-primary')} /> : null}
        {children}
        {iconRight ? <i className={classNames(iconRight, 'ml-2 text-primary')} /> : null}
      </div>
    </CButton>
  );
};

Button.defaultProps = {
  size: 'sm',
  color: 'primary',
} as IButtonProps;

export default Button;
