import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { CDropdown, CButton, CDropdownMenu, CDropdownToggle, CSpinner } from '@coreui/react';
import { Input } from '../Form';
import { Translate } from '../Translate';
import './AutoComplete.scss';

type RenderInputProps = {
  inputProps: {
    className: string;
    onFocus(): void;
    onBlur?(e: React.FocusEvent<HTMLInputElement>): void | Promise<void>;
  };
};

type AutoCompleteProps<TOption> = {
  isOpen: boolean;
  onOpen?(): void;
  onClose?(): void;
  onSelect?(e: React.ChangeEvent<HTMLInputElement>, item?: TOption): void;
  loading?: boolean;
  getOptionLabel(option: TOption): string;
  getOptionValue(option: TOption): string;
  renderInput(props: RenderInputProps): React.ReactNode;
  renderOption?(props: RenderInputProps): React.ReactNode;
  options: TOption[];
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void | Promise<void>;
  className?: string;
  enableFilter?: boolean;
};

function AutoComplete<TOption>(props: AutoCompleteProps<TOption>) {
  const {
    isOpen = false,
    onOpen,
    onClose,
    onSelect,
    loading = false,
    getOptionLabel: getOptionLabelProp,
    getOptionValue: getOptionValueProp,
    renderInput,
    options,
    onBlur,
    className,
    enableFilter = false,
  } = props;

  const [refEl, setRefEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    if (!isOpen) {
      setFilter('');
    }
  }, [isOpen]);

  const getOptionLabel = (option: TOption) => {
    const label = getOptionLabelProp ? getOptionLabelProp(option) : option;
    if (typeof label === 'string') {
      return label;
    }
    return String(option);
  };

  const clickItemHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = options.find(item => getOptionValueProp(item) === e.target?.value);
    onSelect?.(e, selected);
    setFilter('');
    onClose?.();
  };

  const renderListOption = (option: TOption) => {
    return (
      <CButton
        key={getOptionLabel(option)}
        color='white'
        shape='rounded-0'
        className='dropdown-item'
        onClick={clickItemHandler}
        value={getOptionValueProp(option)}
      >
        {getOptionLabel(option)}
      </CButton>
    );
  };

  const filteredOptions = (options || []).filter(option => {
    const label = getOptionLabel(option);
    return label.toLowerCase().includes(filter.toLowerCase());
  });

  useEffect(() => {
    // This is a hack to detect when the dropdown is opened or closed
    // because the CDropdown component does not provide a callback for that
    // And you can't make custom CDropdownToggle components

    let mutationObserver: MutationObserver;
    if (refEl) {
      mutationObserver = new MutationObserver(mutationList => {
        for (const item of mutationList) {
          if (item.attributeName === 'class') {
            const classString = refEl.classList.toString();
            if (classString.includes('show')) {
              onOpen?.();
            } else {
              onClose?.();
            }
          }
        }
      });

      mutationObserver.observe(refEl, { attributes: true });
    }

    return () => {
      mutationObserver?.disconnect();
    };
  }, [refEl]);

  return (
    <>
      <CDropdown className={classNames('AutoComplete', className)} innerRef={setRefEl}>
        {/* 
          Disabled CDropdownToggle so we can manually set open state
          CDropdownToggle is used to handle state internally when blurred (reference)
        */}
        <CDropdownToggle tag={'div'} disabled>
          {renderInput({
            inputProps: {
              className: classNames('AutoComplete_input'),
              onFocus: () => {
                onOpen?.();
              },
              onBlur: onBlur,
            },
          })}
        </CDropdownToggle>
        <CDropdownMenu
          show={isOpen}
          className={classNames('AutoComplete_menu', { loading: loading, show: isOpen })}
        >
          {loading ? (
            <CSpinner size='sm' color='primary' />
          ) : (
            <>
              {isOpen && (
                <>
                  {enableFilter && options.length > 5 && (
                    <Input
                      onChange={e => setFilter((e.target as HTMLInputElement).value)}
                      value={filter}
                      className='w-100 px-2 mb-2 AutoComplete_filter'
                      size='sm'
                    />
                  )}
                  <div className='AutoComplete_list'>
                    {filteredOptions.length > 0 ? (
                      filteredOptions.map(item => renderListOption(item))
                    ) : (
                      <div className='dropdown-item'>
                        <Translate translationKey='Texts.empty-search-result' />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </CDropdownMenu>
      </CDropdown>
    </>
  );
}

export default AutoComplete;
