import { Next } from '../next';

export const createAccountNext = new Next('/investors/register', 'CREATE_ACCOUNT', {
  method: 'POST',
});

export const activateAccountNext = new Next('/investors/activate', 'ACTIVATE_ACCOUNT', {
  method: 'POST',
});

export const SAVE_USER_INPUT = 'SAVE_USER_INPUT';
