import { useEffect } from 'react';

export enum LocalStorageKeys {
  LoggedInExternalId = 'logged-in-external-id',
}

interface IUseLocalStorage {
  onEvent(event: StorageEvent): void;
}

export const useLocalStorage = ({ onEvent }: IUseLocalStorage) => {
  useEffect(() => {
    window.addEventListener('storage', onEvent);
    return () => {
      window.removeEventListener('storage', onEvent);
    };
  }, [onEvent]);
};

export const getLocalStorageItem = (key: LocalStorageKeys) => window.localStorage.getItem(key);
export const setLocalStorageItem = (key: LocalStorageKeys, value: string) =>
  window.localStorage.setItem(key, value);
export const deleteLocalStorageItem = (key: LocalStorageKeys) =>
  window.localStorage.removeItem(key);
