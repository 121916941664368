import React from 'react';
import {
  PATH_ROOT,
  PATH_AUTH,
  PATH_ENTRY,
  PATH_OPPORTUNITIES,
  PATH_OTHER,
  PATH_PORTFOLIO,
  PATH_PRE_ENTRY,
  PATH_REGISTRATION,
  PATH_RESET_PASSWORD,
  PATH_USER,
} from './paths';
import type { Routes } from './types';

// REGISTRATION COMPONENTS
const Registration = React.lazy(() => import('@views/Registration'));
const VerifyEmail = React.lazy(() => import('@views/Registration/VerifyEmail'));
const VerificationEmailSent = React.lazy(() => import('@views/Registration/VerificationEmailSent'));
const TermsOfUse = React.lazy(() => import('@views/Registration/TermsOfUse'));
const ElectronicDelivery = React.lazy(() => import('@views/Registration/ElectronicDelivery'));
const RegistrationProfile = React.lazy(() => import('@views/Registration/Profile'));
const RegistrationBank = React.lazy(() => import('@views/Registration/Bank'));
const RegistrationCompatibility = React.lazy(() => import('@views/Registration/Compatibility'));
const RegistrationOtherInformation = React.lazy(
  () => import('@views/Registration/OtherInformation'),
);
const RegistrationConfirm = React.lazy(() => import('@views/Registration/Confirm'));
const RegistrationKyc = React.lazy(() => import('@views/Registration/Kyc'));
const RegistrationVerifyKycStatus = React.lazy(() => import('@views/Registration/VerifyKycStatus'));

// OPPORTUNITIES COMPONENTS
const Opportunities = React.lazy(() => import('@views/Opportunities'));
const Opportunity = React.lazy(() => import('@views/Opportunities/OpportunityDetail'));
const OpportunityComplete = React.lazy(() => import('@views/Opportunities/Complete'));

const PreEntryDescription = React.lazy(() => import('@views/PreEntry/Description'));
const PreEntryDocuments = React.lazy(() => import('@views/PreEntry/Documents'));
const PreEntryForm = React.lazy(() => import('@views/PreEntry/Form'));
const PreEntryConfirm = React.lazy(() => import('@views/PreEntry/Confirm'));
const PreEntryComplete = React.lazy(() => import('@views/PreEntry/Complete'));

const EntryProspectus = React.lazy(() => import('@views/Entry/Prospectus'));
const EntryConsent = React.lazy(() => import('@views/Entry/Consent'));
const EntryConfirm = React.lazy(() => import('@views/Entry/Confirm'));
const EntryComplete = React.lazy(() => import('@views/Entry/Complete'));

// PORTFOLIO COMPONENTS
const Portfolio = React.lazy(() => import('@views/Portfolio'));
const PortfolioDetail = React.lazy(() => import('@views/Portfolio/PortfolioDetail'));

// AUTH COMPONENTS
const Login = React.lazy(() => import('@views/Login'));
const Logout = React.lazy(() => import('@views/Logout'));
const Profile = React.lazy(() => import('@views/Profile'));
const Security = React.lazy(() => import('@views/Security'));
const RequestResetPassword = React.lazy(() => import('@views/ResetPassword'));
const ResetPasswordRecoveryEmailSent = React.lazy(
  () => import('@views/ResetPassword/RecoveryEmailSent'),
);
const ResetPasswordVerifyEmail = React.lazy(() => import('@views/ResetPassword/VerifyEmail'));
const ResetPassword = React.lazy(() => import('@views/ResetPassword/NewPassword'));

// OTHER COMPONENTS
const Documents = React.lazy(() => import('@views/Documents'));
const Tokens = React.lazy(() => import('@views/Tokens'));
const FAQ = React.lazy(() => import('@views/FAQ'));
const NotFound = React.lazy(() => import('@views/NotFound'));

export const routes: Routes = {
  [PATH_REGISTRATION.registration]: {
    name: 'Registration',
    component: Registration,
  },
  [PATH_REGISTRATION.verifyEmail]: {
    name: 'VerifyEmail',
    component: VerifyEmail,
  },
  [PATH_REGISTRATION.verificationEmailSent]: {
    name: 'VerificationEmailSent',
    component: VerificationEmailSent,
  },
  [PATH_REGISTRATION.termsOfUse]: {
    name: 'TermsOfUse',
    component: TermsOfUse,
  },
  [PATH_REGISTRATION.electronicDelivery]: {
    name: 'ElectronicDelivery',
    component: ElectronicDelivery,
  },
  [PATH_REGISTRATION.profile]: {
    name: 'RegistrationProfile',
    component: RegistrationProfile,
  },
  [PATH_REGISTRATION.bank]: {
    name: 'RegistrationBank',
    component: RegistrationBank,
  },
  [PATH_REGISTRATION.compatibility]: {
    name: 'RegistrationCompatibility',
    component: RegistrationCompatibility,
  },
  [PATH_REGISTRATION.otherInformation]: {
    name: 'RegistrationOtherInformation',
    component: RegistrationOtherInformation,
  },
  [PATH_REGISTRATION.confirm]: {
    name: 'RegistrationConfirm',
    component: RegistrationConfirm,
  },
  [PATH_REGISTRATION.kyc]: {
    name: 'RegistrationKyc',
    component: RegistrationKyc,
    isWizardControlBlock: true,
  },
  [PATH_REGISTRATION.verifyKycStatus]: {
    name: 'RegistrationVerifyKycStatus',
    component: RegistrationVerifyKycStatus,
    isWizardControlBlock: true,
  },
  [PATH_OPPORTUNITIES.opportunities]: {
    name: 'Opportunities',
    component: Opportunities,
  },
  [PATH_OPPORTUNITIES.opportunity]: {
    name: 'Opportunity',
    component: Opportunity,
  },
  [PATH_OPPORTUNITIES.complete]: {
    name: 'OpportunityComplete',
    component: OpportunityComplete,
  },
  [PATH_PRE_ENTRY.description]: {
    name: 'PreEntryDescription',
    component: PreEntryDescription,
  },
  [PATH_PRE_ENTRY.documents]: {
    name: 'PreEntryDocuments',
    component: PreEntryDocuments,
  },
  [PATH_PRE_ENTRY.form]: {
    name: 'PreEntryForm',
    component: PreEntryForm,
  },
  [PATH_PRE_ENTRY.confirm]: {
    name: 'PreEntryConfirm',
    component: PreEntryConfirm,
  },
  [PATH_PRE_ENTRY.complete]: {
    name: 'PreEntryComplete',
    component: PreEntryComplete,
    isWizardControlBlock: true,
  },
  [PATH_ENTRY.prospectus]: {
    name: 'EntryProspectus',
    component: EntryProspectus,
  },
  [PATH_ENTRY.consent]: {
    name: 'EntryConsent',
    component: EntryConsent,
  },
  [PATH_ENTRY.confirm]: {
    name: 'EntryConfirm',
    component: EntryConfirm,
  },
  [PATH_ENTRY.complete]: {
    name: 'EntryComplete',
    component: EntryComplete,
    isWizardControlBlock: true,
  },
  [PATH_PORTFOLIO.portfolios]: {
    name: 'Portfolio',
    component: Portfolio,
  },
  [PATH_PORTFOLIO.portfolio]: {
    name: 'PortfolioDetail',
    component: PortfolioDetail,
  },
  [PATH_AUTH.login]: {
    name: 'Login',
    component: Login,
  },
  [PATH_AUTH.logout]: {
    name: 'Logout',
    component: Logout,
  },
  [PATH_USER.profile]: {
    name: 'Profile',
    component: Profile,
  },
  [PATH_USER.security]: {
    name: 'Security',
    component: Security,
  },
  [PATH_RESET_PASSWORD.resetPassword]: {
    name: 'RequestResetPassword',
    component: RequestResetPassword,
  },
  [PATH_RESET_PASSWORD.recoveryEmailSent]: {
    name: 'ResetPasswordRecoveryEmailSent',
    component: ResetPasswordRecoveryEmailSent,
  },
  [PATH_RESET_PASSWORD.verifyEmail]: {
    name: 'ResetPasswordVerifyEmail',
    component: ResetPasswordVerifyEmail,
  },
  [PATH_RESET_PASSWORD.newPassword]: {
    name: 'ResetPassword',
    component: ResetPassword,
  },
  [PATH_ROOT]: {
    name: 'Opportunities',
  },
  [PATH_OTHER.documents]: {
    name: 'Documents',
    component: Documents,
  },
  [PATH_OTHER.tokens]: {
    name: 'Tokens',
    component: Tokens,
  },
  [PATH_OTHER.faq]: {
    name: 'FAQ',
    component: FAQ,
  },
  [PATH_OTHER.notFound]: {
    name: 'NotFound',
    component: NotFound,
  },
};
