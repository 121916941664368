import CIcon from '@coreui/icons-react';
import classNames from 'classnames';
import React from 'react';

export interface IIconProps {
  icon?: string;
  name?: string;
  content?: CIcon['content'];
  src?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Icon = ({ icon, name, content, src, className, style }: IIconProps) => {
  if (icon) {
    return <i className={classNames(icon, className)} style={style} />;
  }

  if (name) {
    return <CIcon className={className} name={name} style={style} />;
  }

  if (content) {
    return <CIcon className={className} content={content} style={style} />;
  }

  if (src) {
    return <CIcon className={className} src={src} style={style} />;
  }

  return null;
};

export default Icon;
