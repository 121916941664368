import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react';
import { IconButton } from '../Button';
import { Icon } from '../Icon';
import { Translate } from '../Translate';
import './Modal.scss';

export interface IModalComponentProps {
  title: string;
  name: string;
  isOpen: boolean;
  className?: string;
  image?: string;
  icon?: string;
  iconContent?: string;
  iconName?: string;
  iconSrc?: string;
  counter?: string;
  contentClassName?: string;
  closeOnBackdrop?: boolean;
  onClose?: (name?: string) => void;
  size?: 'sm' | 'lg' | 'xl';
  showCloseButton?: boolean;
  footer?: JSX.Element;
}

const ModalComponent = ({
  title,
  name,
  isOpen,
  className,
  image,
  icon,
  iconContent,
  iconName,
  iconSrc,
  counter,
  contentClassName,
  closeOnBackdrop = true,
  onClose,
  children,
  size,
  showCloseButton = false,
  footer,
}: PropsWithChildren<IModalComponentProps>) => {
  const handleClose = () => onClose && onClose(name);

  return (
    <CModal
      show={isOpen}
      className={className}
      centered
      onClose={handleClose}
      closeOnBackdrop={closeOnBackdrop}
      size={size}
    >
      <CModalHeader tag='div' className={classNames('modal-header', contentClassName)}>
        {showCloseButton && (
          <IconButton
            className='position-absolute icon-exit font-24'
            style={{ right: 0 }}
            onClick={handleClose}
            rounded
          />
        )}
        {counter && <div className='modal-header__counter font-12'>{counter}</div>}
        <div className='d-flex flex-column'>
          {!!image && <img src={image} className='w-100 pt-4 mb-4 pb-2' alt='' />}
          <h4 className='modal-title font-20 text-center'>
            <Icon
              className='modal-icon'
              icon={icon}
              content={iconContent}
              name={iconName}
              src={iconSrc}
            />
            <Translate translationKey={title} />
          </h4>
        </div>
      </CModalHeader>
      <CModalBody className='modal-body'>{children}</CModalBody>
      {footer && <CModalFooter className='modal-footer'>{footer}</CModalFooter>}
    </CModal>
  );
};

export default ModalComponent;
