/* eslint-disable @typescript-eslint/no-explicit-any */
import { getStore } from '@state';

export const getTranslation = (
  translations = {} as Record<string, string>,
  translationKey = '-',
  parameters = {} as Record<string, string>,
) => {
  const translationKeys = translationKey.split(' ');
  return translationKeys
    .map(translationKey =>
      Object.keys(parameters).reduce(
        (translation, key) => translation.replace(new RegExp(`{${key}}`, 'g'), parameters[key]),
        translations[translationKey] || translationKey,
      ),
    )
    .join(' ');
};

export const translate = (key: string, params: any = {}) => {
  const store = getStore();
  const translations = store.getState()?.i18n?.translations || {};
  return getTranslation(translations, key, params);
};

export const tryTranslate = (
  key: string,
  params?: Record<string, string>,
  backupKey?: string,
): string | undefined => {
  const translation = translate(key, params);
  if (translation === key && backupKey) {
    return tryTranslate(backupKey, params);
  }
  return translation;
};
