import classNames from 'classnames';
import React from 'react';
import './CardMedia.scss';

interface ICardMedia {
  image: string;
  backupImage?: string;
  alt?: string;
  style?: React.CSSProperties;
  className?: string;
}
const CardMedia = ({ image, alt, style, className, backupImage }: ICardMedia) => {
  return (
    <img
      src={image}
      alt={alt}
      style={style}
      className={classNames('CardMedia', className)}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        if (backupImage) {
          currentTarget.src = backupImage;
        }
      }}
    />
  );
};

export default CardMedia;
