import { useInvestorSelector } from '@state/investor/hooks';
import { useSettingSelector } from '@state/setting/hooks';
import { useMemo } from 'react';

const getAge = (birthdate: string): number => {
  const now = new Date();
  const birthDate = new Date(birthdate);

  const age = now.getFullYear() - birthDate.getFullYear();
  const month = now.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && now.getDate() < birthDate.getDate())) {
    return age - 1;
  }

  return age;
};

export const useAgeRestriction = () => {
  const { investor } = useInvestorSelector();
  const { setting } = useSettingSelector();

  const isAgeRestricted = useMemo(() => {
    if (!investor || !investor.generalProfile.birthdate) {
      return false;
    }

    const ignoreAgeRestriction = investor?.additionalStatuses?.ignoreAgeRestriction;
    if (ignoreAgeRestriction) {
      return false;
    }

    const ageRestriction = setting?.variables.ageRestriction;
    if (!ageRestriction) {
      return false;
    }

    const age = getAge(investor.generalProfile.birthdate);

    if (
      (ageRestriction.max && age > ageRestriction.max) ||
      (ageRestriction.min && age < ageRestriction.min)
    )
      return true;

    return false;
  }, [investor, setting]);

  return { isAgeRestricted, ageRestriction: setting?.variables.ageRestriction };
};
