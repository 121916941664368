import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { translate } from '@utils/translate';
import { successToast } from '@components';
import { HttpCommonErrorTypes } from '@utils/error-types';
import { IHttpErrorResponse } from '../types';
import { changePasswordNext, SAVE_USER_INPUT } from './actionTypes';
import { ChangePasswordErrorTypes, IUserInput } from './models';

type ISecurityErrorResponse = IHttpErrorResponse<
  HttpCommonErrorTypes | ChangePasswordErrorTypes | null
>;

export interface ISecurityPageState {
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly error: ISecurityErrorResponse | null;
  readonly userInput: IUserInput;
}

const initialState: ISecurityPageState = {
  isLoading: false,
  isError: false,
  error: null,
  userInput: {
    currentPassword: '',
    newPassword: '',
  },
};

export const security = createReducer(initialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(changePasswordNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
    }))
    .addCase<string, PayloadAction<void>>(changePasswordNext.SUCCESS, () => {
      window.setTimeout(() =>
        successToast(
          translate('Toasts.update-success', { data: translate('Texts.security.password') }),
        ),
      );
      return initialState;
    })
    .addCase<string, PayloadAction<ISecurityErrorResponse>>(
      changePasswordNext.ERROR,
      (state, payload) => ({
        ...state,
        isLoading: false,
        isError: true,
        error: payload.payload,
      }),
    )
    .addCase<string, PayloadAction<IUserInput>>(SAVE_USER_INPUT, (state, { payload }) => ({
      ...state,
      userInput: payload,
    })),
);
