import { Next } from '../next';

export const loginNext = new Next('/session/login', 'USER_LOGIN', { method: 'POST' });
export const loginSonyNext = new Next('/session/sso/sony', 'USER_LOGIN_SONY', {
  method: 'POST',
});
export const logoutNext = new Next('/session/logout', 'USER_LOGOUT', { method: 'POST' });
export const refreshSessionNext = new Next('/session/refresh', 'REFRESH_SESSION', {
  method: 'POST',
});
export const verifyEmailNext = new Next('/investors/verify-email', 'VERIFY_EMAIL', {
  method: 'POST',
});
export const verifyPasswordNext = new Next('/investors/verify-password', 'VERIFY_PASSWORD', {
  method: 'POST',
});
export const generateTFASeedNext = new Next('/investors/two-factor-seed', 'TFA_SEED', {
  method: 'POST',
});
export const enableTFANext = new Next('/investors/enable-tfa', 'ENABLE_TFA', {
  method: 'POST',
});
export const disableTFANext = new Next('/investors/disable-tfa', 'DISABLE_TFA', {
  method: 'POST',
});
export const SAVE_TFA_TEMP_DATA = 'SAVE_TFA_TEMP_DATA';
export const requestResetPasswordNext = new Next(
  '/investors/request-reset-password',
  'REQUEST_RESET_PASSWORD',
  {
    method: 'POST',
  },
);
export const verifyResetPasswordEmailNext = new Next(
  '/investors/verify-reset-password-code',
  'VERIFY_RESET_PASSWORD_EMAIL',
  {
    method: 'POST',
  },
);
export const resetPasswordNext = new Next('/investors/reset-password', 'RESET_PASSWORD', {
  method: 'POST',
});
export const RESET_USER_SESSION = 'RESET_USER_SESSION';
export const INIT_ERROR = 'INIT_ERROR';
