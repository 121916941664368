import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { CCard } from '@coreui/react';
import './Card.scss';

export type ICard = PropsWithChildren<CCard> & { flat?: boolean };

const Card = ({ children, className, flat = false, ...props }: ICard) => {
  return (
    <CCard className={classNames(className, flat && 'flat', 'CardComponent')} {...props}>
      {children}
    </CCard>
  );
};

export default Card;
