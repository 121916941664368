import { createReducer, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { DeleteWalletResponseDto, WalletDto, WalletWithTokensDto } from '@web-api-client';
import {
  ADD_POLL_TOKEN,
  INIT_TOKEN_STEP,
  REMOVE_POLL_TOKEN,
  RESET_TOKEN_STEPS,
  SET_TOKEN_STEP,
  createWalletNext,
  deleteWalletNext,
  getSilentWalletsNext,
  getWalletsNext,
  updateWalletNext,
} from './actionTypes';

export enum Step {
  Disabled,
  InvestorInformationStep,
  WalletRegistrationStep,
  TokenWithdrawStep,
  TradeStep,
}

type IBaseState = {
  isLoading: boolean;
  isSilentLoading: boolean;
  isError: boolean;
  error: string | Record<string, string> | null;

  isCreateLoading: boolean;
  isCreateError: boolean;
  isUpdateLoading: boolean;
  isUpdateError: boolean;
  isDeleteLoading: boolean;
  isDeleteError: boolean;

  steps: Record<string, Step>;
  pollTokens: string[];
};

export const walletsAdapter = createEntityAdapter<WalletWithTokensDto>({
  selectId: wallet => wallet.walletId,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = walletsAdapter.getInitialState<IBaseState>({
  isLoading: false,
  isSilentLoading: false,
  isError: false,
  error: null,

  isCreateLoading: false,
  isCreateError: false,
  isUpdateLoading: false,
  isUpdateError: false,
  isDeleteLoading: false,
  isDeleteError: false,

  steps: {},
  pollTokens: [],
});

export const wallets = createReducer(initialState, builder =>
  builder
    .addCase(getWalletsNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<WalletWithTokensDto[]>>(
      getWalletsNext.SUCCESS,
      (state, { payload: wallets }) => {
        state.isLoading = false;
        state.isError = false;
        state.error = null;
        walletsAdapter.upsertMany(state, wallets);
      },
    )
    .addCase<string, PayloadAction<Record<string, string>>>(
      getWalletsNext.ERROR,
      (state, { payload: error }) => {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      },
    )
    .addCase(getSilentWalletsNext.INIT, state => {
      state.isSilentLoading = true;
      state.isError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<WalletWithTokensDto[]>>(
      getSilentWalletsNext.SUCCESS,
      (state, { payload: wallets }) => {
        state.isSilentLoading = false;
        state.isError = false;
        state.error = null;
        walletsAdapter.upsertMany(state, wallets);
      },
    )
    .addCase<string, PayloadAction<Record<string, string>>>(
      getSilentWalletsNext.ERROR,
      (state, { payload: error }) => {
        state.isSilentLoading = false;
        state.isError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<void>>(createWalletNext.INIT, state => {
      state.isCreateLoading = true;
      state.isCreateError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<WalletDto>>(
      createWalletNext.SUCCESS,
      (state, { payload: wallet }) => {
        state.isCreateLoading = false;
        state.isCreateError = false;
        state.error = null;

        const walletWithTokens: WalletWithTokensDto = {
          ...wallet,
          tokens: [],
        };
        walletsAdapter.addOne(state, walletWithTokens);
      },
    )
    .addCase<string, PayloadAction<Record<string, string>>>(
      createWalletNext.ERROR,
      (state, { payload: error }) => {
        state.isCreateLoading = false;
        state.isCreateError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<void>>(updateWalletNext.INIT, state => {
      state.isUpdateLoading = true;
      state.isUpdateError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<WalletDto>>(
      updateWalletNext.SUCCESS,
      (state, { payload: wallet }) => {
        const existingWallet = state.entities[wallet.walletId];
        if (existingWallet) {
          existingWallet.name = wallet.name;
        }
      },
    )
    .addCase<string, PayloadAction<Record<string, string>>>(
      updateWalletNext.ERROR,
      (state, { payload: error }) => {
        state.isUpdateLoading = false;
        state.isUpdateError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<void>>(deleteWalletNext.INIT, state => {
      state.isDeleteLoading = true;
      state.isDeleteError = false;
      state.error = null;
    })
    .addCase<string, PayloadAction<DeleteWalletResponseDto>>(
      deleteWalletNext.SUCCESS,
      (state, { payload: { deletedWalletId } }) => {
        state.isDeleteLoading = false;
        state.isDeleteError = false;
        walletsAdapter.removeOne(state, deletedWalletId);
      },
    )
    .addCase<string, PayloadAction<Record<string, string>>>(
      deleteWalletNext.ERROR,
      (state, { payload: error }) => {
        state.isDeleteLoading = false;
        state.isDeleteError = true;
        state.error = error;
      },
    )
    .addCase<string, PayloadAction<string>>(ADD_POLL_TOKEN, (state, { payload }) => {
      if (!state.pollTokens.includes(payload)) {
        state.pollTokens.push(payload);
      }
    })
    .addCase<string, PayloadAction<string>>(REMOVE_POLL_TOKEN, (state, { payload }) => {
      const tokenIndex = state.pollTokens.findIndex(x => x === payload);
      if (tokenIndex > -1) {
        state.pollTokens.splice(tokenIndex, 1);
      }
    })
    .addCase<string, PayloadAction<string>>(INIT_TOKEN_STEP, (state, { payload }) => {
      state.steps[payload] = Step.Disabled;
    })
    .addCase<string, PayloadAction<{ tokenId: string; step: Step }>>(
      SET_TOKEN_STEP,
      (state, { payload }) => {
        state.steps[payload.tokenId] = payload.step;
      },
    )
    .addCase<string, PayloadAction<void>>(RESET_TOKEN_STEPS, state => {
      state.steps = {};
    }),
);

export type IWalletsState = ReturnType<typeof wallets.getInitialState>;
