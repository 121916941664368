import { path, paths } from './util';

const ROOT_OPPORTUNITIES = '/opportunities';
const ROOT_REGISTRATION = '/registration';
const ROOT_PORTFOLIO = '/portfolio';
const ROOT_RESET_PASSWORD = '/reset-password';

export const PATH_ROOT = '/';

export const PATH_RESET_PASSWORD = {
  resetPassword: ROOT_RESET_PASSWORD,
  recoveryEmailSent: path(ROOT_RESET_PASSWORD, 'recovery-email-sent'),
  verifyEmail: path(ROOT_RESET_PASSWORD, 'verify-email'),
  newPassword: path(ROOT_RESET_PASSWORD, 'new-password'),
};

export const PATH_PRE_ENTRY = {
  description: path(ROOT_OPPORTUNITIES, ':id/pre-entry/description'),
  documents: path(ROOT_OPPORTUNITIES, ':id/pre-entry/documents'),
  form: path(ROOT_OPPORTUNITIES, ':id/pre-entry/form'),
  confirm: path(ROOT_OPPORTUNITIES, ':id/pre-entry/confirm'),
  complete: path(ROOT_OPPORTUNITIES, ':id/pre-entry/complete'),
};

export const PATH_ENTRY = {
  prospectus: path(ROOT_OPPORTUNITIES, ':id/entry/prospectus'),
  consent: path(ROOT_OPPORTUNITIES, ':id/entry/consent'),
  confirm: path(ROOT_OPPORTUNITIES, ':id/entry/confirm'),
  complete: path(ROOT_OPPORTUNITIES, ':id/entry/complete'),
};

export const PATH_OPPORTUNITIES = {
  opportunities: ROOT_OPPORTUNITIES,
  complete: path(ROOT_OPPORTUNITIES, 'complete'),
  opportunity: path(ROOT_OPPORTUNITIES, ':id'),
};

export const PATH_PORTFOLIO = {
  portfolios: ROOT_PORTFOLIO,
  portfolio: path(ROOT_PORTFOLIO, ':id'),
};

export const PATH_REGISTRATION = {
  registration: ROOT_REGISTRATION,
  verifyEmail: path(ROOT_REGISTRATION, 'verify-email'),
  verificationEmailSent: path(ROOT_REGISTRATION, 'verification-email-sent'),
  termsOfUse: path(ROOT_REGISTRATION, 'terms-of-use'),
  electronicDelivery: path(ROOT_REGISTRATION, 'electronic-delivery'),
  profile: path(ROOT_REGISTRATION, 'profile'),
  bank: path(ROOT_REGISTRATION, 'bank'),
  compatibility: path(ROOT_REGISTRATION, 'compatibility'),
  otherInformation: path(ROOT_REGISTRATION, 'other-information'),
  confirm: path(ROOT_REGISTRATION, 'confirm'),
  kyc: path(ROOT_REGISTRATION, 'kyc'),
  verifyKycStatus: path(ROOT_REGISTRATION, 'verify-kyc-status'),
};

export const PATH_AUTH = {
  login: '/login',
  logout: '/logout',
};

export const PATH_USER = {
  profile: '/profile',
  security: '/security',
};

export const PATH_OTHER = {
  documents: '/documents',
  tokens: '/tokens',
  faq: '/faq',
  notFound: '/not-found',
};

export const FTK_SSO_PATHS = [
  PATH_ROOT,
  ...paths(PATH_OTHER),
  ...paths(PATH_AUTH),
  ...paths(PATH_PORTFOLIO),
  ...paths(PATH_OPPORTUNITIES),
];

export const CB_SSO_PATHS = [
  PATH_ROOT,
  ...paths(PATH_OTHER),
  ...paths(PATH_AUTH),
  ...paths(PATH_PORTFOLIO),
  ...paths(PATH_OPPORTUNITIES),
  ...paths(PATH_PRE_ENTRY),
  ...paths(PATH_ENTRY),
];

export const FTK_PATHS = [
  PATH_ROOT,
  ...paths(PATH_OTHER),
  ...paths(PATH_AUTH),
  ...paths(PATH_RESET_PASSWORD),
  ...paths(PATH_USER),
  ...paths(PATH_PORTFOLIO),
  ...paths(PATH_OPPORTUNITIES),
  ...paths(PATH_REGISTRATION),
];

export const CB_PATHS = [
  PATH_ROOT,
  ...paths(PATH_OTHER),
  ...paths(PATH_AUTH),
  ...paths(PATH_RESET_PASSWORD),
  ...paths(PATH_USER),
  ...paths(PATH_PRE_ENTRY),
  ...paths(PATH_ENTRY),
  ...paths(PATH_PORTFOLIO),
  ...paths(PATH_OPPORTUNITIES),
  ...paths(PATH_REGISTRATION, {
    omit: [
      PATH_REGISTRATION.kyc,
      PATH_REGISTRATION.verifyKycStatus,
      PATH_REGISTRATION.electronicDelivery,
    ],
  }),
];
