import { useSettingSelector } from '@state/setting/hooks';
import { useI18nSelector } from '@state/i18n/hooks';
import { createIntl, createIntlCache } from '@formatjs/intl';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-displaynames/locale-data/ja';
import { useCallback } from 'react';
import { Currency } from '@web-api-client';

type CurrencyOptions = {
  currency?: Currency;
};

export const useCurrency = (options?: CurrencyOptions) => {
  const { isLoading, setting } = useSettingSelector();
  const { isLoading: isLoadingI18n, activeLanguage } = useI18nSelector();

  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: activeLanguage.toLowerCase(),
      defaultLocale: 'en',
    },
    cache,
  );

  const formatCurrency = useCallback(
    (amount: number) => {
      return isLoading || isLoadingI18n
        ? ''
        : intl.formatNumber(amount, {
            style: 'currency',
            currency: options?.currency || setting?.variables.mainCurrency,
            notation: 'standard',
            currencyDisplay: 'symbol',
          });
    },
    [options?.currency, setting?.variables.mainCurrency, isLoading, isLoadingI18n, intl],
  );

  return { formatCurrency };
};
