import React, { PropsWithChildren } from 'react';
import { CDataTable, CPagination } from '@coreui/react';
import classNames from 'classnames';

import './DataTable.scss';

export interface IDataTableProps<T = { [key: string]: unknown }>
  extends PropsWithChildren<CDataTable> {
  items: T[];
  fields: ({ [key: string]: unknown } | string)[];
  pagination?: Omit<CPagination, 'align' | 'size'>;
  itemsPerPage?: number;
  scopedSlots?: Record<string, unknown>;
  className?: string;
}

const DataTable = <T = { [key: string]: unknown },>({
  items,
  fields,
  pagination,
  itemsPerPage,
  scopedSlots,
  className,
  header,
  ...props
}: IDataTableProps<T>) => {
  return (
    <CDataTable
      items={items}
      fields={fields}
      itemsPerPage={itemsPerPage}
      outlined={false}
      pagination={{
        ...pagination,
        align: 'center',
        className: classNames('striped-table-pagination', pagination?.className),
      }}
      addTableClasses={classNames('striped-table', className, !header && 'hide-header')}
      scopedSlots={scopedSlots}
      {...props}
    />
  );
};

DataTable.defaultProps = {
  itemsPerPage: 5,
  header: true,
};

export default DataTable;
