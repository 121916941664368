import classNames from 'classnames';
import React from 'react';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Icon } from '../Icon';
import './IconButton.scss';

export interface IButtonProps extends CButton {
  color?: 'primary' | 'secondary' | 'danger' | 'link';
  icon?: string;
  iconName?: string;
  iconContent?: CIcon['content'];
  iconSrc?: string;
  rounded?: boolean;
}

const IconButton = ({
  className,
  size,
  icon,
  iconName,
  iconContent,
  iconSrc,
  rounded,
  ...props
}: IButtonProps) => {
  return (
    <CButton
      className={classNames(
        'IconButtonComponent transparent',
        {
          ['round']: rounded,
        },
        className,
      )}
      {...props}
    >
      <Icon icon={icon} content={iconContent} name={iconName} src={iconSrc} />
    </CButton>
  );
};

export default IconButton;
