import classNames from 'classnames';
import React from 'react';
import { SafeReactHtmlParser } from '../../SafeReactHtmlParser';
import styles from './Description.module.scss';

interface IDescription {
  description: string;
  isDetail?: boolean;
  isListCard?: boolean;
}

const Description = ({ description, isDetail, isListCard }: IDescription) => {
  return (
    <div
      className={classNames('subtext', styles.description, {
        [styles.descriptionPartial]: !isDetail,
        [styles.descriptionList]: isListCard,
      })}
    >
      {SafeReactHtmlParser(description)}
    </div>
  );
};

export default Description;
