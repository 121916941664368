import classNames from 'classnames';
import React, { FormEvent, useState } from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { CLabel, CFormGroup } from '@coreui/react';
import { ErrorMessage } from '@hookform/error-message';
import { translate } from '@utils/translate';
import { CheckboxInput } from '../CheckboxInput';
import { SafeReactHtmlParser } from '../../SafeReactHtmlParser';
import { Translate } from '../../Translate';

import './CheckboxGroup.scss';

interface ICheckboxGroupProps {
  name: string;
  label: string | React.ReactElement;
  value: string[];
  options: { label: string; value: string }[];
  className?: string;
  errors?: DeepMap<FieldValues, FieldError>;
  disabled?: boolean;
  onChange?: (event: FormEvent<HTMLFormElement>, selectedValues: string[]) => void;
}

const CheckboxGroup = ({
  label,
  name,
  value = [],
  options,
  className,
  errors,
  disabled,
  onChange,
}: ICheckboxGroupProps) => {
  const [selectedValues, setSelectedValues] = useState(new Set(value));

  return (
    <CFormGroup className={classNames('CheckboxGroupComponent', className)}>
      {label &&
        (typeof label === 'string' ? (
          <CLabel>
            <Translate translationKey={label} />
          </CLabel>
        ) : (
          label
        ))}
      {options.map(option => (
        <CheckboxInput
          key={option.value}
          name={name}
          value={option.value}
          checked={value.includes?.(option.value)}
          disabled={disabled}
          onChange={data => {
            const target = data.target as HTMLInputElement;
            const _selectedValues = selectedValues;
            if (target.checked) {
              _selectedValues.add(target.value);
              setSelectedValues(_selectedValues);
            } else {
              _selectedValues.delete(target.value);
              setSelectedValues(_selectedValues);
            }
            onChange?.(data, [...selectedValues]);
          }}
        >
          {SafeReactHtmlParser(translate(option.label))}
        </CheckboxInput>
      ))}
      {errors && (
        <ErrorMessage
          className='error text-danger errorMessage'
          errors={errors}
          name={name}
          as='small'
        />
      )}
    </CFormGroup>
  );
};

export default CheckboxGroup;
