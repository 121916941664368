import React, { CSSProperties, PropsWithChildren } from 'react';
import { CFormGroup } from '@coreui/react';
import classNames from 'classnames';
import './FormGroup.scss';

export type IFormGroupProps = PropsWithChildren<CFormGroup> & { style?: CSSProperties };

const FormGroup = ({ children, className, ...props }: IFormGroupProps) => {
  return (
    <CFormGroup className={classNames('FormGroupComponent', className)} {...props}>
      {children}
    </CFormGroup>
  );
};

export default FormGroup;
