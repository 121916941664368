import { Next } from '../next';

export const getWalletsNext = new Next(`/wallets`, 'GET_WALLETS', {
  method: 'GET',
});
export const getSilentWalletsNext = new Next(`/wallets`, 'GET_SILENT_WALLETS', {
  method: 'GET',
});

export const createWalletNext = new Next(`/wallets`, 'CREATE_WALLET', {
  method: 'POST',
});

export const updateWalletNext = new Next(`/wallets/{walletId}`, 'UPDATE_WALLET', {
  method: 'PATCH',
});

export const deleteWalletNext = new Next(`/wallets/{walletId}`, 'DELETE_WALLET', {
  method: 'DELETE',
});

export const ADD_POLL_TOKEN = 'ADD_POLL_TOKEN';

export const REMOVE_POLL_TOKEN = 'REMOVE_POLL_TOKEN';

export const INIT_TOKEN_STEP = 'INIT_TOKEN_STEP';

export const SET_TOKEN_STEP = 'SET_TOKEN_STEP';

export const RESET_TOKEN_STEPS = 'RESET_TOKEN_STEPS';
