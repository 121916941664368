import React, { Suspense, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Loading } from '@components';
import { patchConfig } from '@state';
import 'react-toastify/dist/ReactToastify.css'; // TODO: Remove dependency if possible
import { useI18nSelector } from '@state/i18n/hooks';
import { HelmetProvider } from 'react-helmet-async';
import { PATH_ROOT } from '@routes';

import './scss/style.scss';
import { SessionProvider } from '@contexts/session.context';

// Containers
const MainLayout = React.lazy(() => import('@containers/MainLayout'));

patchConfig({
  RE_CAPTCHA_KEY: process.env.REACT_APP_RE_CAPTCHA_KEY || '',
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || '/api',
});

const logGitVersion = () => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Git version: ', process.env.VERSION); // eslint-disable-line no-console
    console.log('Git version url:', process.env.VERSION_URL); // eslint-disable-line no-console
    console.log('Git commit hash: ', process.env.COMMITHASH); // eslint-disable-line no-console
    console.log('Git commit url: ', process.env.COMMIT_URL); // eslint-disable-line no-console
    console.log('Git branch: ', process.env.BRANCH); // eslint-disable-line no-console
    console.log('Git branch url: ', process.env.BRANCH_URL); // eslint-disable-line no-console
  }
};

const App = () => {
  const { activeLanguage } = useI18nSelector();

  useEffect(() => {
    logGitVersion();
  }, []);

  useEffect(() => {
    if (activeLanguage) {
      document.documentElement.lang = activeLanguage.toLocaleLowerCase();
    }
  }, [activeLanguage]);

  return (
    <HelmetProvider>
      <HashRouter>
        <Suspense fallback={<Loading />}>
          <SessionProvider>
            <Switch>
              <Route path={PATH_ROOT} component={MainLayout} />
            </Switch>
          </SessionProvider>
        </Suspense>
      </HashRouter>
    </HelmetProvider>
  );
};

export default App;
