import { InvestmentDetailDtoStatusEnum } from '@web-api-client';

export const getValueByInvestmentStatus = (
  status: InvestmentDetailDtoStatusEnum,
  completedValue: unknown,
  confirmedValue: unknown,
  defaultValue: unknown,
) => {
  const CONFIRMED_INVESTMENT_REQUEST_STATUSES = [
    InvestmentDetailDtoStatusEnum.Confirmed,
    InvestmentDetailDtoStatusEnum.Confirming,
    InvestmentDetailDtoStatusEnum.Processing,
  ];
  const COMPLETED_INVESTMENT_REQUEST_STATUSES = [
    InvestmentDetailDtoStatusEnum.Completed,
    InvestmentDetailDtoStatusEnum.OperationEnded,
    InvestmentDetailDtoStatusEnum.Redeemed,
    InvestmentDetailDtoStatusEnum.TransferredOrRedeemed,
  ];

  if (COMPLETED_INVESTMENT_REQUEST_STATUSES.includes(status)) {
    return completedValue;
  }

  if (CONFIRMED_INVESTMENT_REQUEST_STATUSES.includes(status)) {
    return confirmedValue;
  }

  return defaultValue;
};
