import urlJoin from 'url-join'; // TODO: Remove dependency if possible
import { configKeys, getConfig } from './npm-config';

export interface INextInterface {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
}

export class Next {
  constructor(url: string = '', seed: string, options: INextInterface = {}) {
    if (!seed || !url) {
      throw new Error('Seed and URL are required');
    }
    this.seed = `${seed}`;
    this.options = {
      uri:
        url.startsWith('http://') || url.startsWith('https://') || url.startsWith('//')
          ? url
          : urlJoin(getConfig(configKeys.API_BASE_URL) || '', url || ''),
      json: true,
      ...options,
    };
  }

  seed;
  options = {
    json: true,
    uri: '',
  };

  get INIT() {
    return `${this.seed}_INIT`;
  }

  get SUCCESS() {
    return `${this.seed}_SUCCESS`;
  }

  get ERROR() {
    return `${this.seed}_ERROR`;
  }

  get PROGRESS() {
    return `${this.seed}_PROGRESS`;
  }

  get ABORT() {
    return `${this.seed}_ABORT`;
  }
}
