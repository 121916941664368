import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  getPortfolioDetailNext,
  getTransferInstructionsNext,
  getVirtualBankAccountInstructionsNext,
} from './actionTypes';
import {
  GetTransferInstructionsResponseDto,
  GetVirtualBankAccountInstructionsResponseDto,
  InvestmentDetailDto,
} from '@web-api-client';
import { HttpCommonErrorTypes } from '@utils/error-types';
import { IHttpErrorResponse } from '../types';

export interface IPortfolioDetailState {
  isLoading: boolean;
  isError: boolean;
  readonly investment?: InvestmentDetailDto;
  readonly isLoadingInstructions: boolean;
  readonly transferInstructions?: GetTransferInstructionsResponseDto;
  readonly virtualBankAccountInstructions?: GetVirtualBankAccountInstructionsResponseDto;
}

export const initialState: IPortfolioDetailState = {
  isLoading: true,
  isError: false,
  isLoadingInstructions: false,
};

export const portfolioDetail = createReducer(initialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getPortfolioDetailNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<InvestmentDetailDto>>(
      getPortfolioDetailNext.SUCCESS,
      (state, { payload }) => {
        state.investment = payload;
        state.isLoading = false;
      },
    )
    .addCase<string, PayloadAction<IHttpErrorResponse<HttpCommonErrorTypes>>>(
      getPortfolioDetailNext.ERROR,
      (state, { payload }) => {
        if (payload.name === HttpCommonErrorTypes.NOT_FOUND_INVESTMENT) {
          location.hash = '#/not-found';
        }
        state.isLoading = false;
        state.isError = true;
      },
    )
    .addCase<string, PayloadAction<void>>(getTransferInstructionsNext.INIT, state => {
      state.isLoadingInstructions = true;
    })
    .addCase<string, PayloadAction<GetTransferInstructionsResponseDto>>(
      getTransferInstructionsNext.SUCCESS,
      (state, { payload }) => {
        state.transferInstructions = payload;
        state.isLoadingInstructions = false;
      },
    )
    .addCase<string, PayloadAction<void>>(getTransferInstructionsNext.ERROR, state => {
      state.transferInstructions = undefined;
      state.isLoadingInstructions = false;
    })
    .addCase<string, PayloadAction<void>>(getVirtualBankAccountInstructionsNext.INIT, state => {
      state.isLoadingInstructions = true;
    })
    .addCase<string, PayloadAction<GetVirtualBankAccountInstructionsResponseDto>>(
      getVirtualBankAccountInstructionsNext.SUCCESS,
      (state, { payload }) => {
        state.virtualBankAccountInstructions = payload;
        state.isLoadingInstructions = false;
      },
    )
    .addCase<string, PayloadAction<void>>(getVirtualBankAccountInstructionsNext.ERROR, state => {
      state.virtualBankAccountInstructions = undefined;
      state.isLoadingInstructions = false;
    }),
);
