import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export function useInvestorSelector(): RootState['investor'] {
  return useSelector<RootState, RootState['investor']>(state => state.investor);
}

export const selectTokenIdVerified = createSelector(
  (state: RootState, tokenId: string) => {
    const tokens = state.investor.investorVerifiedTokens;
    return tokens.includes(tokenId);
  },
  isVerified => isVerified,
);

export function useInvestorTokenVerified(tokenId: string): boolean {
  return useSelector<RootState, boolean>(state => selectTokenIdVerified(state, tokenId));
}
