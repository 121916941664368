import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { GetDomainQuestionsResponseDto, QuestionDto } from '@web-api-client';
import { getQuestionsNext } from './actionTypes';

export interface IQuestionsState {
  readonly totalItems: number;
  readonly questions: QuestionDto[];
  readonly page: number;
  readonly isLoading: boolean;
  readonly isError: boolean;
}

export const initialState: IQuestionsState = {
  totalItems: 0,
  questions: [],
  page: 0,
  isLoading: true,
  isError: false,
};

export const questions = createReducer(initialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(getQuestionsNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<GetDomainQuestionsResponseDto>>(
      getQuestionsNext.SUCCESS,
      (state, { payload }) => {
        state.totalItems = payload.totalItems;
        state.questions.push(...payload.data);
        state.page += 1;
        state.isLoading = false;
        state.isError = false;
      },
    )
    .addCase<string, PayloadAction<void>>(getQuestionsNext.ERROR, state => {
      state.isLoading = false;
      state.isError = true;
    }),
);
