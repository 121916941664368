// Extra small	      None  <576px
// Small	            sm	  ≥576px
// Medium	            md	  ≥768px
// Large	            lg	  ≥992px
// Extra large	      xl	  ≥1200px
// Extra extra large  xxl	  ≥1400px

import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type BreakpointMap = Record<Breakpoint, number>;

const breakpoints: BreakpointMap = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

interface IMediaBreakpointProps {
  down?: Breakpoint; // media-breakpoint-down
  up?: Breakpoint; // media-breakpoint-up
}

const getNextBreakpoint = (breakpoint: Breakpoint): Breakpoint => {
  const breakpointKeys = Object.keys(breakpoints) as Breakpoint[];
  const breakpointIndex = breakpointKeys.indexOf(breakpoint);
  const nextBreakpoint = breakpointKeys[breakpointIndex + 1];
  return nextBreakpoint;
};

export const useMediaBreakpoint = ({ up, down }: IMediaBreakpointProps) => {
  const query = useMemo(() => {
    if (down) {
      const nextBreakpoint = getNextBreakpoint(down);
      if (!nextBreakpoint) return {};

      // Maximum breakpoint width. Null for the largest (last) breakpoint.
      // The maximum value is calculated as the minimum of the next one less 0.02px
      // NOTE: Same as CoreUI
      return { maxWidth: breakpoints[nextBreakpoint] - 0.02 };
    }

    if (up) {
      return { minWidth: breakpoints[up] };
    }

    return {};
  }, [up, down]);

  return useMediaQuery(query);
};
