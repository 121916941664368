import React, { PropsWithChildren } from 'react';
import { CProgress } from '@coreui/react';
import classNames from 'classnames';
import './ProgressBar.scss';

export interface IProgressBar extends PropsWithChildren<CProgress> {
  value: number;
}

const ProgressBar = ({ className, value = 0, ...props }: IProgressBar) => {
  return <CProgress className={classNames(className)} value={value} {...props} />;
};

ProgressBar.defaultProps = {} as IProgressBar;

export default ProgressBar;
