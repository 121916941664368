import classNames from 'classnames';
import React, { useEffect } from 'react';
import { CCardHeader } from '@coreui/react';
import { translate } from '@utils/translate';
import { chipColor } from '@views/Portfolio';
import {
  InvestmentDetailDtoStatusEnum,
  OpportunityDetailDtoRoundStatusEnum,
  OpportunityDtoRoundStatusEnum,
} from '@web-api-client';
import { Chip } from '../../Chip';
import { StatusChip } from '../../StatusChip';
import styles from './Header.module.scss';

interface IOpportunityCardHeader {
  title: string;
  status: OpportunityDtoRoundStatusEnum | OpportunityDetailDtoRoundStatusEnum;
  investmentStatus?: InvestmentDetailDtoStatusEnum;
}
const Header = ({ title, status, investmentStatus }: IOpportunityCardHeader) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const statusChipContainerRef = React.useRef<HTMLDivElement>(null);
  const [isRows, setRows] = React.useState(false);
  const [isMultiline, setMultiline] = React.useState(false);

  const handleWindowResize = () => {
    if (!ref.current || !statusChipContainerRef.current) return;
    const titleDiv = ref.current;
    const statusChipContainerDiv = statusChipContainerRef.current;
    const h5Div = titleDiv.children[0];
    const headerDiv = titleDiv.parentElement;

    if (!headerDiv || !h5Div) return;
    const ratio = headerDiv.clientWidth / statusChipContainerDiv.clientWidth;

    setMultiline(h5Div.clientHeight > 25);
    setRows(ratio < 3 && h5Div.clientWidth === titleDiv.clientWidth);
  };

  useEffect(() => {
    if (ref.current) {
      window.addEventListener('resize', handleWindowResize);
    }
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [ref.current]);

  return (
    <CCardHeader
      className={classNames(styles.Header, {
        [styles.HeaderRows]: isRows,
      })}
    >
      <div
        ref={ref}
        className={classNames(styles.headerTitleContainer, {
          [styles.headerTitleMultiline]: isMultiline,
        })}
      >
        <h4 className={classNames('mb-0', styles.headerTitle)}>{title}</h4>
      </div>
      <div ref={statusChipContainerRef} className={classNames(styles.statusChipContainer)}>
        {investmentStatus ? (
          <Chip
            color={chipColor(investmentStatus)}
            text={translate(`Texts.portfolio.fund.status.${investmentStatus}`)}
            className={classNames('font-14', styles.statusChip)}
          />
        ) : (
          <StatusChip className={classNames('font-14', styles.statusChip)} status={status} />
        )}
      </div>
    </CCardHeader>
  );
};

export default Header;
