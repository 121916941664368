import { useSettingSelector } from '@state/setting/hooks';
import { translate } from '@utils/translate';

export const useDashboardMode = () => {
  const { setting } = useSettingSelector();
  const mode = setting?.variables?.dashboardMode;

  const getTranslateByMode = (key: string, params?: Record<string, string>) => {
    if (!mode) return translate(key);
    const translateByMode = translate(`${key}.${mode}`, params);
    if (translateByMode === `${key}.${mode}`) {
      return translate(key, params);
    } else {
      return translateByMode;
    }
  };

  return { getTranslateByMode };
};
