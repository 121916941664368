import classNames from 'classnames';
import React from 'react';
import { useSettingSelector } from '@state/setting/hooks';
import { SafeReactHtmlParser } from '../SafeReactHtmlParser';
import styles from './Footer.module.scss';

const Footer = () => {
  const { setting } = useSettingSelector();

  return (
    <div className={classNames('mt-5 p-3', styles.bg)}>
      {SafeReactHtmlParser(String(setting?.variables.footer))}
    </div>
  );
};

export default Footer;
