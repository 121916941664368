import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { translate } from '@utils/translate';
import { successToast } from '@components';
import { HttpCommonErrorTypes } from '@utils/error-types';
import { IHttpErrorResponse } from '../types';
import { activateAccountNext, createAccountNext, SAVE_USER_INPUT } from './actionTypes';
import { IUserInput, RegistrationErrorTypes } from './models';

type IRegistrationErrorResponse = IHttpErrorResponse<
  HttpCommonErrorTypes | RegistrationErrorTypes | null
>;

export interface IRegistrationState {
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly error: IRegistrationErrorResponse | null;
  readonly isRegistrationRequested: boolean;
  readonly userInput: IUserInput;
}

const initialState: IRegistrationState = {
  isLoading: false,
  isError: false,
  error: { statusCode: null, name: null, message: '' },
  isRegistrationRequested: false,
  userInput: {
    email: '',
    password: '',
    nationality: '',
    isResidenceChecked: false,
    isPrivacyPolicyAgreed: false,
  },
};

export const registration = createReducer(initialState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(createAccountNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
      isRegistrationRequested: false,
    }))
    .addCase<string, PayloadAction<void>>(createAccountNext.SUCCESS, state => {
      window.setTimeout(() => successToast(translate('Toasts.email-successfully-sent')));

      return {
        ...state,
        isRegistrationRequested: true,
        isLoading: false,
      };
    })
    .addCase<string, PayloadAction<IRegistrationErrorResponse>>(
      createAccountNext.ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        isError: true,
        error: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(activateAccountNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
      isRegistrationRequested: false,
    }))
    .addCase<string, PayloadAction<void>>(activateAccountNext.SUCCESS, state => {
      return {
        ...state,
        isRegistrationRequested: true,
        isLoading: false,
      };
    })
    .addCase<string, PayloadAction<IRegistrationErrorResponse>>(
      activateAccountNext.ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        isError: true,
        error: payload,
      }),
    )
    .addCase<string, PayloadAction<IUserInput>>(SAVE_USER_INPUT, (state, { payload }) => ({
      ...state,
      userInput: payload,
    })),
);
