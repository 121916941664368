import React, { PropsWithChildren } from 'react';
import { CNavItem, CNavLink } from '@coreui/react';
import { useHistory } from 'react-router-dom';
export interface ITabItem {
  dataTab: string;
  onClick: (tab: string) => void;
}

const TabItem = ({ children, dataTab, onClick, ...props }: PropsWithChildren<ITabItem>) => {
  const history = useHistory();
  const toggle = (tab: string) => {
    history.push({ search: new URLSearchParams({ tab }).toString() });
    onClick(tab);
  };

  return (
    <CNavItem onClick={() => toggle(dataTab)} {...props}>
      <CNavLink data-tab={dataTab}>{children}</CNavLink>
    </CNavItem>
  );
};

export default TabItem;
