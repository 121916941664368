import { ComputePreciseOperation, computePrecise } from '@utils/compute-precise';
import { InvestableAmountDto, OpportunityDetailDto } from '@web-api-client';

interface IInvestRestrictions {
  opportunity?: OpportunityDetailDto;
  investableAmount: InvestableAmountDto | null;
}

interface IInvestRestrictionsResult {
  minInvestmentUnit: number;
  maxInvestmentUnit: number;
  maxInvestableUnit: number;
  unitValue: number;
  investLimit: number;
}

export const useInvestRestrictions = ({
  opportunity,
  investableAmount: investableAmountDto,
}: IInvestRestrictions): IInvestRestrictionsResult => {
  if (!opportunity) {
    return {
      minInvestmentUnit: 1,
      maxInvestmentUnit: Infinity,
      maxInvestableUnit: Infinity,
      unitValue: 0,
      investLimit: Infinity,
    };
  }

  const tokenValue = opportunity.round.tokenCalculatorData?.tokenValue || 0;
  const minInvestmentUnit = opportunity.roundAdditionalInfo.minInvestmentUnit || 1;
  const maxInvestmentUnit = opportunity.roundAdditionalInfo.maxInvestmentUnit || Infinity;

  if (
    !investableAmountDto ||
    investableAmountDto.investLimit === null ||
    investableAmountDto.investableAmount === null
  ) {
    return {
      minInvestmentUnit,
      maxInvestmentUnit,
      maxInvestableUnit: maxInvestmentUnit,
      unitValue: tokenValue,
      investLimit: Infinity,
    };
  }

  const { investableAmount, investLimit } = investableAmountDto;

  const maxInvestableUnit =
    typeof investableAmount === 'number'
      ? computePrecise(maxInvestmentUnit, tokenValue, ComputePreciseOperation.Multiply) >
        investableAmount
        ? Math.floor(computePrecise(investableAmount, tokenValue, ComputePreciseOperation.Divide))
        : maxInvestmentUnit
      : maxInvestmentUnit;

  return {
    minInvestmentUnit,
    maxInvestmentUnit,
    maxInvestableUnit,
    unitValue: tokenValue,
    investLimit,
  };
};
