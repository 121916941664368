import React, { PropsWithChildren } from 'react';
import { CNav } from '@coreui/react';
import './Tabs.scss';
import classNames from 'classnames';

const Tabs = ({ children, className, ...props }: PropsWithChildren<CNav>) => (
  <CNav className={classNames('TabsComponent', className)} {...props}>
    {children}
  </CNav>
);

export default Tabs;
