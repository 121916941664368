import React from 'react';
import classNames from 'classnames';
import { Translate } from '../Translate';

export const PageTitle: React.FC<{
  title: string;
  subtitle?: string;
  className?: string;
}> = ({ title = '', subtitle = '', className = '' }) => (
  <div className={classNames('profile-header d-flex justify-content-between', className)}>
    <h1 className='profile-title font-32'>
      <Translate translationKey={title} />
      {!!subtitle && (
        <span className='text-uppercase font-14 font-weight-light pl-3'>{subtitle}</span>
      )}
    </h1>
  </div>
);
