import { IAuthState, auth } from './auth/reducer';
import { I18nState, i18n } from './i18n/reducer';
import { IOpportunitiesState, opportunities } from './opportunities/reducer';
import { IQuestionsState, questions } from './questions/reducer';
import { IOpportunityState, opportunityDetail } from './opportunityDetail/reducer';
import { ISettingState, setting } from './setting/reducer';
import { IRegistrationState, registration } from './registration/reducer';
import { ISecurityPageState, security } from './security/reducer';
import { IProfileState, profile } from './profile/reducer';
import { IInvestorState, investor } from './investor/reducer';
import { IPortfolioState, portfolio } from './portfolio/reducer';
import { IPortfolioDetailState, portfolioDetail } from './portfolioDetail/reducer';
import { IInvestmentState, investment } from './investment/reducer';
import { IWizardState, wizard } from './wizard/reducer';
import { AnyAction, CombinedState, combineReducers } from '@reduxjs/toolkit';
import { RESET_USER_SESSION, logoutNext } from './auth/actionTypes';
import { IWalletsState, wallets } from './wallets/reducer';
import { IProvidersState, providers } from './providers/reducer';
import { ITokenWalletsState, tokenWallets } from './tokenWallets/reducer';
import {
  ITradeBuyOpportunities,
  ITradeSellOpportunities,
  tradeBuyOpportunities,
  tradeSellOpportunities,
} from './trade-opportunities/reducer';

type State = CombinedState<{
  auth: IAuthState;
  i18n: I18nState;
  opportunities: IOpportunitiesState;
  questions: IQuestionsState;
  opportunityDetail: IOpportunityState;
  profile: IProfileState;
  setting: ISettingState;
  security: ISecurityPageState;
  registration: IRegistrationState;
  investor: IInvestorState;
  portfolio: IPortfolioState;
  portfolioDetail: IPortfolioDetailState;
  investment: IInvestmentState;
  wizard: IWizardState;
  wallets: IWalletsState;
  tokenWallets: ITokenWalletsState;
  providers: IProvidersState;
  tradeSellOpportunities: ITradeSellOpportunities;
  tradeBuyOpportunities: ITradeBuyOpportunities;
}>;

const combinedReducer = combineReducers({
  auth,
  i18n,
  opportunities,
  questions,
  opportunityDetail,
  profile,
  setting,
  security,
  registration,
  investor,
  portfolio,
  portfolioDetail,
  investment,
  wizard,
  wallets,
  tokenWallets,
  providers,
  tradeBuyOpportunities,
  tradeSellOpportunities,
});

export const rootReducer = (state: State | undefined, action: AnyAction) => {
  if ([logoutNext.SUCCESS, RESET_USER_SESSION].includes(action.type) && state) {
    const { setting, i18n } = state;
    // Probably an anti-pattern or something you're not supposed to do
    // But resets state to initial state when user logs out
    // except for setting and i18n
    return combinedReducer({ setting, i18n } as unknown as State, action);
  }
  return combinedReducer(state, action);
};
