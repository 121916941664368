import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { translate } from '@utils/translate';
import { successToast } from '@components';
import { HttpCommonErrorTypes } from '@utils/error-types';
import {
  AuthInfoResponseDto,
  CreateSessionTokenResponseDto,
  GenerateTfaSeedResponseDto,
  VerifyEmailResponseDto,
} from '@web-api-client';
import { IHttpErrorResponse } from '../types';
import { activateAccountNext } from '../registration/actionTypes';
import {
  refreshSessionNext,
  loginNext,
  verifyEmailNext,
  logoutNext,
  verifyPasswordNext,
  generateTFASeedNext,
  enableTFANext,
  disableTFANext,
  SAVE_TFA_TEMP_DATA,
  requestResetPasswordNext,
  verifyResetPasswordEmailNext,
  resetPasswordNext,
  loginSonyNext,
} from './actionTypes';
import { AuthErrorTypes, ITwoFactorAuth } from './models';
import { resetUserSession, initError } from './actions';
import {
  deleteLocalStorageItem,
  LocalStorageKeys,
  setLocalStorageItem,
} from '@hooks/local-storage.hook';
import { errorToast } from '@components';

type IAuthErrorResponse = IHttpErrorResponse<HttpCommonErrorTypes | AuthErrorTypes | null>;

export interface IAuthState {
  readonly isLoading: boolean;
  readonly isAuthenticated: boolean | null;
  readonly isError: boolean;
  readonly error: IAuthErrorResponse | null;
  readonly activationFormInfo: VerifyEmailResponseDto | null;
  readonly isTfaEnabled: boolean;
  readonly twoFactorAuth: ITwoFactorAuth;
  readonly accessTokenExpiredAt: number | null;
  readonly refreshTokenExpiredAt: number | null;
  readonly externalId: string | null;
}

const initialState: IAuthState = {
  isLoading: false,
  isAuthenticated: null,
  isError: false,
  error: null,
  isTfaEnabled: false,
  activationFormInfo: null,
  twoFactorAuth: {
    password: '',
    isPasswordVerified: false,
    seed: null,
  },
  accessTokenExpiredAt: null,
  refreshTokenExpiredAt: null,
  externalId: null,
};

export const auth = createReducer(initialState, builder =>
  builder
    .addCase(initError, state => {
      return {
        ...state,
        isError: false,
        error: null,
      };
    })
    .addCase(resetUserSession, () => {
      return {
        ...initialState,
        isAuthenticated: false,
      };
    })
    .addCase<string, PayloadAction<void>>(verifyPasswordNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
      error: null,
      twoFactorAuth: {
        password: state.twoFactorAuth.password,
        isPasswordVerified: false,
        seed: null,
      },
    }))
    .addCase<string, PayloadAction<void>>(generateTFASeedNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
      error: null,
      twoFactorAuth: {
        password: state.twoFactorAuth.password,
        isPasswordVerified: state.twoFactorAuth.isPasswordVerified,
        seed: null,
      },
    }))
    .addCase<string, PayloadAction<void>>(verifyResetPasswordEmailNext.INIT, state => ({
      ...state,
      isLoading: true,
      isError: false,
      error: null,
    }))
    .addCase<string, PayloadAction<IAuthErrorResponse>>(
      verifyPasswordNext.ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        isError: true,
        error: payload,
        twoFactorAuth: {
          password: '',
          isPasswordVerified: false,
          seed: null,
        },
      }),
    )
    .addCase<string, PayloadAction<IAuthErrorResponse>>(
      generateTFASeedNext.ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        isError: true,
        error: payload,
        twoFactorAuth: {
          password: state.twoFactorAuth.password,
          isPasswordVerified: state.twoFactorAuth.isPasswordVerified,
          seed: null,
        },
      }),
    )
    .addCase<string, PayloadAction<IAuthErrorResponse>>(
      requestResetPasswordNext.ERROR,
      (state, { payload }) => ({
        ...state,
        isError: true,
        error: payload,
        isLoading: false,
      }),
    )
    .addCase<string, PayloadAction<IAuthErrorResponse>>(
      verifyResetPasswordEmailNext.ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        isError: true,
        error: payload,
      }),
    )
    .addCase<string, PayloadAction<void>>(verifyPasswordNext.SUCCESS, state => ({
      ...state,
      isError: false,
      error: null,
      isLoading: false,
      twoFactorAuth: {
        password: state.twoFactorAuth.password,
        isPasswordVerified: true,
        seed: null,
      },
    }))
    .addCase<string, PayloadAction<GenerateTfaSeedResponseDto>>(
      generateTFASeedNext.SUCCESS,
      (state, { payload }) => ({
        ...state,
        isError: false,
        error: null,
        isLoading: false,
        twoFactorAuth: {
          password: state.twoFactorAuth.password,
          isPasswordVerified: state.twoFactorAuth.isPasswordVerified,
          seed: payload,
        },
      }),
    )
    .addCase<string, PayloadAction<void>>(requestResetPasswordNext.SUCCESS, state => {
      window.setTimeout(() => successToast(translate('Toasts.email-successfully-sent')));

      return {
        ...state,
        isError: false,
        error: null,
        isLoading: false,
      };
    })
    .addCase<string, PayloadAction<void>>(verifyResetPasswordEmailNext.SUCCESS, state => ({
      ...state,
      isLoading: false,
      isError: false,
      error: null,
    }))
    .addCase<string, PayloadAction<ITwoFactorAuth>>(SAVE_TFA_TEMP_DATA, (state, { payload }) => ({
      ...state,
      twoFactorAuth: payload,
    }))
    .addCase<string, PayloadAction<void>>(logoutNext.SUCCESS, () => {
      deleteLocalStorageItem(LocalStorageKeys.LoggedInExternalId);
      return {
        ...initialState,
        isAuthenticated: false,
      };
    })
    .addCase<string, PayloadAction<IAuthErrorResponse>>(
      refreshSessionNext.ERROR,
      (_, { payload }) => {
        return {
          ...initialState,
          isAuthenticated: false,
          error: payload,
        };
      },
    )
    .addMatcher<PayloadAction<void>>(
      ({ type }) =>
        [
          loginNext.INIT,
          loginSonyNext.INIT,
          logoutNext.INIT,
          refreshSessionNext.INIT,
          verifyEmailNext.INIT,
          requestResetPasswordNext.INIT,
          resetPasswordNext.INIT,
        ].includes(type),
      state => ({
        ...state,
        isLoading: true,
        isError: false,
        error: null,
      }),
    )
    .addMatcher<PayloadAction<IAuthErrorResponse>>(
      ({ type }) =>
        [
          loginNext.ERROR,
          loginSonyNext.ERROR,
          verifyEmailNext.ERROR,
          resetPasswordNext.ERROR,
        ].includes(type),
      (state, { payload }) => {
        deleteLocalStorageItem(LocalStorageKeys.LoggedInExternalId);
        return {
          ...state,
          isLoading: false,
          isError: true,
          isAuthenticated: false,
          error: payload,
          accessTokenExpiredAt: null,
          refreshTokenExpiredAt: null,
          externalId: null,
        };
      },
    )
    .addMatcher<PayloadAction<IAuthErrorResponse>>(
      ({ type }) => [disableTFANext.ERROR, enableTFANext.ERROR].includes(type),
      (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: payload,
        };
      },
    )
    .addMatcher<PayloadAction<VerifyEmailResponseDto>>(
      ({ type }) => [verifyEmailNext.SUCCESS].includes(type),
      (state, { payload }) => {
        return {
          ...state,
          activationFormInfo: payload,
        };
      },
    )
    .addMatcher<PayloadAction<CreateSessionTokenResponseDto>>(
      ({ type }) =>
        [
          loginNext.SUCCESS,
          refreshSessionNext.SUCCESS,
          activateAccountNext.SUCCESS,
          resetPasswordNext.SUCCESS,
          loginSonyNext.SUCCESS,
        ].includes(type),
      (state, { payload }) => {
        setLocalStorageItem(LocalStorageKeys.LoggedInExternalId, payload.externalId);
        return {
          ...state,
          isError: false,
          error: null,
          isAuthenticated: true,
          isTfaEnabled: payload.isTfaEnabled,
          isLoading: false,
          accessTokenExpiredAt: payload.sessionToken.accessTokenExpiredAt * 1000,
          refreshTokenExpiredAt: payload.sessionToken.refreshTokenExpiredAt * 1000,
          externalId: payload.externalId,
        };
      },
    )
    // Just process the ones that were defined in MainLayout previously?
    // authError?.name, investorError?.name, investmentError?.name, securityError?.name
    .addMatcher<PayloadAction<IAuthErrorResponse>>(
      ({ type }) => type.endsWith('_ERROR'),
      (state, { payload }) => {
        if (payload?.name === HttpCommonErrorTypes.UNAUTHORIZED_EXCEPTION) {
          state.isAuthenticated = false;
          window.setTimeout(() => errorToast(translate('Errors.unauthorized')));
        }
      },
    )
    .addMatcher<PayloadAction<AuthInfoResponseDto>>(
      ({ type }) => [enableTFANext.SUCCESS, disableTFANext.SUCCESS].includes(type),
      (state, { payload: { isTfaEnabled } }) => {
        window.setTimeout(() =>
          successToast(
            translate(isTfaEnabled ? 'Toasts.2fa-enabled-success' : 'Toasts.2fa-disabled-success'),
          ),
        );
        return {
          ...state,
          isError: false,
          error: null,
          isLoading: false,
          isTfaEnabled,
          twoFactorAuth: {
            password: '',
            isPasswordVerified: false,
            seed: null,
          },
        };
      },
    ),
);
