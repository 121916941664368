import React from 'react';
import { useAgeRestriction } from '@hooks/age-restriction.hook';
import { AgeRestrictionError } from '../../AgeRestrictionError';

interface IActionButton {
  actionButton: React.ReactElement;
}

const ActionButton = ({ actionButton }: IActionButton) => {
  const { isAgeRestricted } = useAgeRestriction();

  return (
    <div>
      {isAgeRestricted && <AgeRestrictionError />}
      {actionButton}
    </div>
  );
};

export default ActionButton;
