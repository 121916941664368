import classNames from 'classnames';
import React from 'react';
import { useMediaBreakpoint } from '@hooks/use-media-breakpoint.hook';
import { useSettingSelector } from '@state/setting/hooks';
import { VariableDtoDashboardModeEnum } from '@web-api-client';
import { Translate } from '../Translate';
import styles from './AgeRestrictionError.module.scss';

const AgeRestrictionError = () => {
  const isMobile = useMediaBreakpoint({ down: 'lg' });
  const { setting } = useSettingSelector();

  const Component = isMobile
    ? ('h6' as keyof JSX.IntrinsicElements)
    : ('h5' as keyof JSX.IntrinsicElements);

  const isFtk = setting?.variables.dashboardMode === VariableDtoDashboardModeEnum.Ftk;

  return (
    <Component
      className={classNames(styles.error, {
        'm-0 mb-2': !isMobile,
        'm-0 mt-2 mb-2': isMobile && !isFtk,
      })}
    >
      <Translate
        translationKey='Errors.invest-age-restriction'
        parameters={setting?.variables.ageRestriction}
      />
    </Component>
  );
};

export default AgeRestrictionError;
