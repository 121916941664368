import React, { FC } from 'react';
import classNames from 'classnames';
import './Alert.scss';
import { CAlert } from '@coreui/react';

export interface IAlertProps extends CAlert {
  icon?: React.ReactNode;
}

const Alert: FC<IAlertProps> = ({ className, children, icon, ...props }) => {
  return (
    <CAlert className={classNames('AlertComponent', 'font-16', className)} {...props}>
      {icon && <div className='AlertComponent_icon'>{icon}</div>}
      {children}
    </CAlert>
  );
};

export default Alert;
