import React from 'react';
import { Translate } from '../Translate';

export const ServerError: React.FC<{
  className?: string;
  errorText?: string;
  children?: JSX.Element;
}> = ({ children, errorText = 'Errors.server-error' }) => (
  <div>
    <Translate translationKey={errorText} />
    {children}
  </div>
);
