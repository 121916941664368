export enum configKeys {
  RE_CAPTCHA_KEY = 'RE_CAPTCHA_KEY',
  API_BASE_URL = 'API_BASE_URL',
  GTM_KEY = 'GTM_KEY',
}

interface IConfigInterface {
  RE_CAPTCHA_KEY: string;
  API_BASE_URL: string;
  GTM_KEY?: string;
}

let configurations: IConfigInterface = {
  RE_CAPTCHA_KEY: process.env.REACT_APP_RE_CAPTCHA_KEY || '',
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || '/api',
  GTM_KEY: process.env.GTM_KEY || '',
};

export const patchConfig = (configObject: IConfigInterface) => {
  configurations = {
    ...configurations,
    ...(configObject || {}),
  };
};

export const getConfig = (key: configKeys) => {
  return configurations[key];
};
