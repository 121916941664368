import { Next } from '../next';

export const getPortfolioDetailNext = new Next('/investments/{id}', 'GET_PORTFOLIO_DETAIL', {
  method: 'GET',
});

export const getTransferInstructionsNext = new Next(
  '/investments/funding-address',
  'GET_TRANSFER_INSTRUCTIONS',
  {
    method: 'GET',
  },
);

export const getVirtualBankAccountInstructionsNext = new Next(
  '/investments/virtual-bank-account-instructions',
  'GET_VIRTUAL_BANK_ACCOUNT_INSTRUCTIONS',
  {
    method: 'GET',
  },
);
