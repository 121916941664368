import {
  LoginProviderConfigTypeEnum,
  VariableDtoDashboardModeEnum,
  DashboardDisplayFlags,
} from '@web-api-client';
import { CB_SSO_PATHS, CB_PATHS, FTK_SSO_PATHS, FTK_PATHS, PATH_USER, PATH_OTHER } from './paths';
import { routes } from './routes';
import { SecureSSORoutes, SecureRoutes } from './secure-routes';
import { isEmpty } from 'lodash';
import type { Path, Route } from './types';

export * from './paths';
export * from './wizard-steps';
export type { Path, Route } from './types';

const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

const getCbPaths = (loginType?: LoginProviderConfigTypeEnum) => {
  if (loginType === LoginProviderConfigTypeEnum.Sso) {
    return CB_SSO_PATHS;
  }
  return CB_PATHS;
};

const getFtkPaths = (loginType?: LoginProviderConfigTypeEnum) => {
  if (loginType === LoginProviderConfigTypeEnum.Sso) {
    return FTK_SSO_PATHS;
  }
  return FTK_PATHS;
};

const getPaths = (
  dashboardMode?: VariableDtoDashboardModeEnum,
  loginType?: LoginProviderConfigTypeEnum,
) => {
  if (dashboardMode === VariableDtoDashboardModeEnum.CbSelfOffering) {
    return getCbPaths(loginType);
  }

  if (dashboardMode === VariableDtoDashboardModeEnum.Ftk) {
    return getFtkPaths(loginType);
  }

  return [];
};

const displayFlagFilter = (dashboardDisplayFlags?: DashboardDisplayFlags) => {
  const enableTokensPage = WALLET_CONNECT_PROJECT_ID && dashboardDisplayFlags?.enableTokensPage;

  return (path: string) => {
    if (!dashboardDisplayFlags?.enableProfilePage && path === PATH_USER.profile) return false;
    if (!dashboardDisplayFlags?.enableSecurityPage && path.startsWith(PATH_USER.security))
      return false;
    if (!enableTokensPage && path.startsWith(PATH_OTHER.tokens)) return false;
    return true;
  };
};

export const getRoutes = (
  dashboardMode?: VariableDtoDashboardModeEnum,
  loginType?: LoginProviderConfigTypeEnum,
  dashboardDisplayFlags?: DashboardDisplayFlags,
): Route[] => {
  const paths = getPaths(dashboardMode, loginType);
  return paths.filter(displayFlagFilter(dashboardDisplayFlags)).map(path => {
    const route = routes[path];
    const secureRoutes =
      loginType === LoginProviderConfigTypeEnum.Sso ? SecureSSORoutes : SecureRoutes;

    route.secure = secureRoutes.includes(path);

    return { ...route, path };
  });
};

export const getPath = (path: Path, params?: Record<string, string | number>) => {
  if (!params || isEmpty(params)) return path;

  return Object.entries(params).reduce((acc, [key, param]) => {
    return acc.replace(`:${key}`, param.toString());
  }, path);
};
